
import _find from 'lodash/find';
import _map from 'lodash/map';
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from 'moment';
import React, { Component } from "react";
import { Package } from "react-feather";
import 'react-vertical-timeline-component/style.min.css';
import { Dimmer, Divider, Dropdown, Header, Icon, Item, Label, List, Loader, Segment,Transition,Form,Modal,Button } from "semantic-ui-react";
import { ph_emptyImageURL } from "../../0-common/utils/DefaultImage";
import ShopOrderHistoryTimelineView from "../../0-common/view/OrderHistoryTimelineView";
import { injectStyle } from "../utils/DecoratorUtil";

@inject("storeFactory")
@injectStyle("Account", "OrderView")
@observer
export default class ShopBuyerOrderView extends Component{

    @observable showOrderDetailView = false;
    @observable showCancelForm = false;
    @observable reasonForCancel="";
    @observable reasonForCancelKey=0;
    @observable explanationForCancel="";
    @observable tempObjectId=null;
    @observable canceledFieldsEmpty=false;
    // Dedicated OrderStore for selected Order in detail view.
    // Any changes of data in orderDetailStore should be updated in orderStore as well.
    orderDetailStore = null;
    
    optionsForCancelling = [
        { key: 1, text: 'Order Created by Mistake', value:'Order Created by Mistake'},
        { key: 2, text: 'Item(s) Would Not Arrive on Time', value: 'Item(s) Would Not Arrive on Time' },
        { key: 3, text: 'Shipping Cost Too High', value: 'Shipping Cost Too High'},
        { key: 4, text: 'Item Price Too High', value: 'Item Price Too High' },
        { key: 5, text: 'Found It Cheaper Somewhere Else', value: 'Found It Cheaper Somewhere Else' },
        { key: 6, text: 'Need to change Shipping Address', value: 'Need to change Shipping Address' },
        { key: 7, text: 'Need to Change Payment Method' , value: 'Need to Change Payment Method' },
        { key: 8, text: 'Not Available During Delivery', value: 'Not Available During Delivery' },
        { key: 9, text: 'My Reason is not listed here', value: 'My Reason is not listed here' },
      ]
    
    reasonSeparator="@#$$#@";
      
    constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }
    
    //Is true when the order detail view is loaded directly via URL from email link.
    get isOrderDetailView_Via_Link(){
        const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        if(shopParams && shopParams.itemID){
            return true;
        } else {
            return false;
        }
    }

    get CurrencyStore(){
        return this.props.storeFactory.CurrencyStore;
    }

    @action
    closeOrderDetailView_Via_Link = () => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        shopParams.itemID = null;
        this.shopStore.ActivePage = navigationStore.SUBVIEW.BUYER.ORDER;
        navigationStore.changeView(navigationStore.VIEW.SHOP);
        this.closeOrderDetailView();

        setTimeout( () => {
            navigationStore.changeView(navigationStore.VIEW.SHOP, navigationStore.SUBVIEW.BUYER.ORDER);
        }, 1);
    }

    @action
    openOrderDetailView = (orderRef) => {
        this.showOrderDetailView = true;
        const orderUID = orderRef.DATA.uid;
        this.orderDetailStore = this.props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS", "uid" : orderUID});
        this.orderDetailStore.currentItem = orderRef;
    }

    @action
    closeOrderDetailView = () => {
        this.showOrderDetailView = false;
        this.orderDetailStore.currentItem = {};
    }

    @action
    closeCancelOrderForm=()=>{
        this.showCancelForm=false;
    }

    @action
    changeReasonForCancel=(e,data)=>{
        this.reasonForCancel=data.value;
        let { value } = data;
        let { key } = data.options.find(o => o.value === value);
        this.reasonForCancelKey=key;
    }

    @action
    changeExplanationForCancel=(e,{value})=>{
        this.explanationForCancel=value;
    }

    @action
    handleDoneCancelOrderForm=()=>{
       
        if(this.reasonForCancelKey==0)
        {
            this.canceledFieldsEmpty=true;
            return;
        };
        if(this.reasonForCancelKey==9 && this.explanationForCancel=="")
        {
            this.canceledFieldsEmpty=true;
            return;
        };

        this.executeOrderAction("Cancel",this.tempObjectId);
        this.reasonForCancel="";
        this.reasonForCancelKey=0;
        this.explanationForCancel="";
        this.showCancelForm=false;
        this.canceledFieldsEmpty=false;
    }
    
    preExecuteOrderAction = async (actionName, objectID) => {
        if(actionName=="Cancel"){
            this.tempObjectId=objectID;
            this.reasonForCancel="";
            this.reasonForCancelKey=0;
            this.explanationForCancel="";
            this.canceledFieldsEmpty=false;
            this.showCancelForm=true;
        }
        else{   
            this.executeOrderAction(actionName, objectID)
        }
        
    }
    

    // Any changes of data in orderDetailStore should be updated in orderStore as well.
    executeOrderAction = async (actionName, objectID) => {
            let patch = [];
            switch (actionName){
                case "Cancel" :
                        const reasonForCancel = this.reasonForCancel+ this.reasonSeparator+ this.explanationForCancel;
                        patch.push({"op":"add", "path":"/OrderCancelReason", "value": reasonForCancel});
        
                default:
                        const sellerID = this.shopStore.OwnerInfo.SourceID;
                        const shopID = this.shopStore.ShopID;
                        await this.orderDetailStore.executeWorkflowAction(actionName, objectID, sellerID, shopID,patch);
                        //Added to reflect the current order's state change in orderStore's ItemList
                        this.orderStore.updateRepository(this.orderDetailStore.currentItem);
            }
    }
         
    renderCancelView(){
        
        return(
        <Segment basic>
            <Header as='h2' className='cancelHeader'>
                <Header.Content>
                Cancel Order
                </Header.Content>
                <Header.Subheader>
                provide cancellation information for this order
                </Header.Subheader>
            </Header>
                <Segment basic>
                    <label>Reason for cancellation:</label>
                    <Dropdown error={this.canceledFieldsEmpty} 
                    placeholder="select cancellation Reason" key={this.optionsForCancelling.key} options={this.optionsForCancelling}
                    selection onChange={this.changeReasonForCancel} />
                </Segment>  
                <Form>
                    <Form.Field error={this.canceledFieldsEmpty}>
                    <Form.TextArea placeholder='Give an explanation' onChange={this.changeExplanationForCancel}/>
                    </Form.Field>
                </Form>
                <Segment textAlign='center' basic>
                    <Modal.Actions>
                        <Button 
                        content="Cancel"  
                        onClick={this.closeCancelOrderForm}
                        />
                        <Button
                        content="Done"
                        onClick={this.handleDoneCancelOrderForm}
                        />
                    </Modal.Actions>
                </Segment>
        </Segment>);
                    
                
    }

    executeRefreshAction = async (orderId) => {
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        await this.orderDetailStore.refreshOrderForBuyer(orderId, sellerID, shopID);
        //Added to reflect the current order's state change in orderStore's ItemList
        this.orderStore.updateRepository(this.orderDetailStore.currentItem);
    }

    /*executeOpenOrderItem(itemId, itemType, itemCategory){
        emitter.emit(EVENTS.ITEM.OPEN, EVENTS.ITEM.OPEN, itemId, itemType, itemCategory);
    }*/

    renderOrderDetailsView(){
        const Style = this.props.componentStyle;
        const orderID = this.orderDetailStore.currentItem.DATA.SourceID;
        const addressRef = this.orderDetailStore.currentItem.DATA.OrderAddress;
        
        const name = this.orderDetailStore.currentItem.DATA.OrderAddressName || "";
        const streetaddress = this.orderDetailStore.currentItem.DATA.OrderAddressStreetAddress || "";
        const locatedin = this.orderDetailStore.currentItem.DATA.OrderAddressLocatedin || "";
        const city = this.orderDetailStore.currentItem.DATA.OrderAddressCity || "";
        const state = this.orderDetailStore.currentItem.DATA.OrderAddressState || "";
        const country = this.orderDetailStore.currentItem.DATA.OrderAddressCountry || "";
        const pincode = this.orderDetailStore.currentItem.DATA.OrderAddressPincode || "";
        const addressMobileNo = this.orderDetailStore.currentItem.DATA.OrderAddressMobileNumber || "";

        const sellerID = this.orderDetailStore.currentItem.DATA.Seller;
		const sellerEmail = this.orderDetailStore.currentItem.REFERENCES[sellerID].Email;

        const orderItems = this.orderDetailStore.getItemsInOrder(this.orderDetailStore.currentItem);
        const totalItemCount = this.orderDetailStore.getItemCountInOrder(this.orderDetailStore.currentItem);
        
        const deliveryAmount = this.orderDetailStore.currentItem.DATA.OrderDeliveryAmount || 0;
        const orderTransactionCurrency = this.orderDetailStore.currentItem.DATA.OrderTransactionCurrency;
        let totalAmount = this.CurrencyStore.formatCurrency(orderTransactionCurrency, this.orderDetailStore.currentItem.DATA.OrderAmount);
        let totalPayableAmount = this.CurrencyStore.formatCurrency(orderTransactionCurrency, this.orderDetailStore.currentItem.DATA.OrderPayableAmount || (this.orderDetailStore.currentItem.DATA.OrderAmount + deliveryAmount) );
        const timelineHistoryRef = this.orderDetailStore.currentItem.DATA.History;
        const timelineHistoryData = _map(timelineHistoryRef, (timelineHistoryID) => {
            return this.orderDetailStore.currentItem.REFERENCES[timelineHistoryID];
        });

        const orderStatusID = this.orderDetailStore.currentItem.DATA.OrderStatus[0];
        const orderStatusRef = _find(this.orderDetailStore.currentItem.OPTIONS.OrderStatus, ["key", orderStatusID]);
        let currentOrderStatus = "";
        let currentOrderStatusLabel = "";
        let orderStatusColor = "grey";
        if(orderStatusRef){
            currentOrderStatus = orderStatusRef.source;
            currentOrderStatusLabel = orderStatusRef.text;
            switch (orderStatusRef.source) {
				case "orderplaced":
				case "ordershipped":
				case "orderdelivered":
					orderStatusColor = "green";
					break;
				
				case "ordercancelled":
				case "ordercancelrefunded":
                case "orderpaymentprocessing":
					orderStatusColor = "orange";
					break;

				case "orderreturnrequested":
				case "orderreturnscheduled":
				case "orderreturnrejected":
				case "orderreturned":
				case "orderreturnrefunded":
					orderStatusColor = "blue";
                    break;

                case "orderpaymentfailed":
                    orderStatusColor = "red";
                    break;
			}
        }

        let expectedDeliveryDate = this.orderDetailStore.currentItem.DATA.ExpectedDeliveryDate;
		let showEstimatedDelivery = false;
		if(expectedDeliveryDate){
			showEstimatedDelivery = true;
		}
        let cancellingReason=this.orderDetailStore.currentItem.DATA.OrderCancelReason;
		let showCancellingReason = false;
		let showCancellingExplanation=false;
		if(cancellingReason){
			showCancellingReason = true;
			cancellingReason =cancellingReason.split("@#$$#@");
			if(cancellingReason[1]!==""){
				showCancellingExplanation=true;
			}
		}
        
        return(
            <Style.OrderDetailView>
                <Transition visible={this.showCancelForm} animation="zoom" duration={500} >
                    <Modal
                    key="CancelForm"
                    centered
                    open={this.showCancelForm} 
                    closeOnDimmerClick={true}
                    size="tiny" >
                        <Modal.Content>
                        {this.renderCancelView()}
                        </Modal.Content>
                    </Modal>
                </Transition>
                <Style.OrderDetailHeader>
                    <Header.Content>Order Details : {orderID}</Header.Content>
                </Style.OrderDetailHeader>
                <Divider />
                <Style.OrderActionSegment basic>
                    <Style.OrderStatusAction>
                        {
                            !this.isOrderDetailView_Via_Link
                            &&
                            <Icon name='chevron left' onClick={this.closeOrderDetailView} />
                        }
                        {
                            this.orderDetailStore.currentItem.ACTIONS
                            &&
                            <Style.OrderStatus basic $orderStatusColor={orderStatusColor} $border={false}>
                                <Label circular empty size="small"/>
                                <span>{currentOrderStatusLabel}</span>
                                <Dropdown inline>
                                    <Dropdown.Menu direction="left">
                                        <Dropdown.Header icon='bolt' content='Actions' />
                                        <Dropdown.Divider />
                                        {
                                            _map(this.orderDetailStore.currentItem.ACTIONS, (orderAction)=>{
                                                return <Dropdown.Item key={orderAction.key} text={orderAction.text} onClick={this.preExecuteOrderAction.bind(this, orderAction.value, this.orderDetailStore.currentItem.DATA.uid)} />
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Style.OrderStatus>
                        }
                    </Style.OrderStatusAction>
                    <Style.RefreshButton
                        primary
                        labelPosition='left'
                        icon='sync'
                        content="Refresh"
                        size="small"
                        onClick={this.executeRefreshAction.bind(this, this.orderDetailStore.currentItem.DATA.uid)} />
                </Style.OrderActionSegment>
                <Style.OrderDetailSummaryGroup horizontal raised>
                    <Style.OrderAddressView>
                        <Header content='Delivery Address' />
                        <Header className="contactName" content={name}/>
                        <div className="streetAddress1">
                            {streetaddress}, {locatedin} 
                        </div>
                        <div className="streetAddress1">
                            {city}, {state} - {pincode} 
                        </div>
                        <div className="streetAddress2">
                            {country} 
                        </div>
                        <div className="contactNumber">
                            Mobile : <span>{addressMobileNo}</span>
                        </div>
                        {
                            showEstimatedDelivery
                            &&
                            currentOrderStatus!="ordercancelled"
                            &&
                            <>
                                <Header content='Estimated Delivery' />
                                <div className="estimated-delivery-date">
                                    {moment(expectedDeliveryDate).format("DD MMM YYYY")}
                                </div>
                            </>
                        }
                        {
                            showCancellingReason
                            &&
                            <>
                                <Header as='h4' content='Reason For Cancelling' />
                                <div style={{fontSize: "1rem",  marginBottom: "1rem"}}>
                                    {cancellingReason[0]}
                                </div>

                                {
                                    showCancellingExplanation
                                    &&
                                    <>
                                        <Header as='h4' content='Explanations' />
                                        <div style={{fontSize: "1rem",  marginBottom: "1rem"}}>
                                            {cancellingReason[1]}
                                        </div>
                                    </>
                                }

                            </>
                        }
                    </Style.OrderAddressView>
                    <Style.OrderSummaryView className="orderSummaryDetailView">
                        <Header content='Order Summary' />
                        <List verticalAlign='middle'>
                            <List.Item>
                                <List.Content floated='right'>
                                    {totalAmount}
                                </List.Content>
                                <List.Content>Price({totalItemCount} Item)</List.Content>  
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>
                                    <span>{ (deliveryAmount === 0) ? 'Free' : this.CurrencyStore.formatCurrency(orderTransactionCurrency, deliveryAmount) }</span>
                                </List.Content>
                                <List.Content>Delivery Charges</List.Content>
                            </List.Item>
                        </List>
                        <Divider />
                        <Segment basic clearing className="grand-total">
                            <Header floated='left'>
                                Grand Total
                            </Header>
                            <Header floated='right' className="total-amount">
                                <span>{totalPayableAmount} </span>
                            </Header>
                        </Segment>
                        <Divider />
                        <Segment basic className="contact-us">
                            <Header>
                                <Header.Content>
                                    Questions about this order?
                                    <Header.Subheader>
                                        <Style.ContactUsButton primary content="Contact Us" onClick={ () => window.open(`mailto:${sellerEmail}`, '_blank')}/>
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Segment>
                    </Style.OrderSummaryView>
                </Style.OrderDetailSummaryGroup>
                <Style.TrackDivider horizontal>
                    <Icon name='shipping fast' /> &nbsp;Track
                </Style.TrackDivider>
                <Style.OrderTimeLineView>
                    <ShopOrderHistoryTimelineView currentStatus={currentOrderStatus} data={timelineHistoryData}/>
                </Style.OrderTimeLineView>
                <Style.OrderItemDivider horizontal>
                    <Icon name='list ul' /> &nbsp;Order Items
                </Style.OrderItemDivider>
                <Segment>
                    <Item.Group divided>
                    {
                        _map(orderItems, (orderItem) =>  {
                            let productImageUrl = ph_emptyImageURL;
                            const imgRefId = orderItem.DATA.ProfileImage;
                            if(imgRefId) {
                                const imgRef = orderItem.REFERENCE_IMAGES[imgRefId];
                                if(imgRef)
                                    productImageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/"; 
                            }
                            //const imageURL = productImageUrl+"/-/format/auto/-/quality/smart/";

                            let selectedSizeText = "";
                            let showSizeOption = true;
                            if(orderItem.Option === 'ONE_SIZE'){
                                showSizeOption = false;
                            } else {
                                const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
                                const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
                                if(sizeOption)
                                    selectedSizeText  = sizeOption.text;
                            }

                            
                            return(
                                    <Item key={orderItem.DATA.uid}>
                                        <Item.Image size='tiny' src={productImageUrl} />
                                        <Item.Content>
                                            <Style.OrderItemLabel>{orderItem.DATA.Name}</Style.OrderItemLabel>
                                            {
                                                showSizeOption
                                                &&
                                                <Style.OrderItemSize>
                                                    <span>Size : {selectedSizeText}</span>
                                                </Style.OrderItemSize>
                                            }
                                            <Style.OrderItemQuantity>
                                                <span>Quantity : {orderItem.Quantity}</span>
                                            </Style.OrderItemQuantity>
                                        </Item.Content>
                                    </Item>
                            
                            );
                        })
                    }
                    </Item.Group>
                </Segment>
                <Segment basic textAlign="center">
                    {
                        !this.isOrderDetailView_Via_Link
                        &&
                        <Style.BackButton compact primary onClick={this.closeOrderDetailView} >
                            <Icon name='left chevron' />
                            Back
                        </Style.BackButton>
                    }
                    {
                        this.isOrderDetailView_Via_Link
                        &&
                        <Style.BackButton compact primary onClick={this.closeOrderDetailView_Via_Link} >
                            <Icon name='close' />
                            Close
                        </Style.BackButton>
                    }
                </Segment>
                <Dimmer active={this.orderDetailStore.isLoading} >
                    <Loader />
                </Dimmer>
            </Style.OrderDetailView>
        )
    }

    renderOrderView(){
        const Style = this.props.componentStyle;
        const orders = this.orderStore.ItemList;
        if(this.orderStore.isLoading){
            return (
                <Style.OrderLoadingView>
                    <Dimmer active={true} >
                        <Loader>
                            Fetching your orders...
                        </Loader>
                    </Dimmer>
                </Style.OrderLoadingView>
            )
        } else if(orders && orders.length === 0){
            return (
                <Style.EmptyOrderView>
                    <Header as='h3' icon textAlign='center'>
                        <Icon name='shopping bag' circular />
                        <Header.Content>We Are Yet To Receive Your First Order</Header.Content>
                    </Header>
                </Style.EmptyOrderView>
            );
        } else {
            return(
                <Style.OrderView>
                    <Style.OrderTimeline layout="1-column" className="orderTimeline">
                            {
                                _map(orders, (order) =>  {
                                    
                                    const orderPlacedDate = moment(order.DATA.OrderPlacedDate).format("DD MMM YYYY");
                                    const itemsInOrder = this.orderStore.getItemsInOrder(order);
                                    const orderItem = itemsInOrder[0];
    
                                    let productImageUrl = ph_emptyImageURL;
                                    const imgRefId = orderItem.DATA.ProfileImage;
                                    if(imgRefId) {
                                        const imgRef = orderItem.REFERENCE_IMAGES[imgRefId];
                                        if(imgRef)
                                            productImageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/"; 
                                    }
                                    //const imageURL = productImageUrl+"/-/format/auto/-/quality/smart/";

                                    let selectedSizeText = "";
                                    let showSizeOption = true;
                                    if(orderItem.Option === 'ONE_SIZE'){
                                        showSizeOption = false;
                                    } else {
                                        const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
                                        const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
                                        if(sizeOption)
                                            selectedSizeText  = sizeOption.text;
                                    }

                                    const orderStatusID = order.DATA.OrderStatus[0];
                                    const orderStatusRef = _find(order.OPTIONS.OrderStatus, ["key", orderStatusID]);
                                    let currentOrderStatus = "";
                                    let currentOrderStatusColor = "grey";
                                    if(orderStatusRef){
                                        currentOrderStatus = orderStatusRef.text;
                                        switch (orderStatusRef.source) {
                                            case "orderplaced":
                                            case "ordershipped":
                                            case "orderdelivered":
                                                currentOrderStatusColor = "green";
                                                break;
                                            
                                            case "ordercancelled":
                                            case "ordercancelrefunded":
                                            case "orderpaymentprocessing":
                                                currentOrderStatusColor = "orange";
                                                break;

                                            case "orderreturnrequested":
                                            case "orderreturnscheduled":
                                            case "orderreturnrejected":
                                            case "orderreturned":
                                            case "orderreturnrefunded":
                                                currentOrderStatusColor = "blue";
                                                break;

                                            case "orderpaymentfailed":
                                                orderStatusColor = "red";
                                                break;
                                        }
                                    }
                                    
                                    return(
                                        <Style.OrderTimelineEvent
                                            key={order.DATA.uid}
                                            className="orderTimelineEvent"
                                            date={null}
                                            icon={<Package />}>
                                                <Style.Order>
                                                    <Item>
                                                        <Item.Image size='tiny' src={productImageUrl} />
                                                        <Item.Content>
                                                            <Item.Header>
                                                                {order.DATA.SourceID}
                                                            </Item.Header>
                                                            <Item.Description>{orderItem.DATA.Name}</Item.Description>
                                                            {
                                                                showSizeOption
                                                                &&
                                                                <Item.Meta>
                                                                    <span>Size : {selectedSizeText}</span>
                                                                </Item.Meta>
                                                            }
                                                            <Item.Extra>
                                                                <Style.OrderStatus basic $orderStatusColor={currentOrderStatusColor} $border={true}>
                                                                    <Label circular empty size="small"/>
                                                                    <span>{currentOrderStatus}</span>
                                                                </Style.OrderStatus>
                                                                <Style.OrderDetailButton floated='right' compact onClick={this.openOrderDetailView.bind(null, order)} >
                                                                    Order Details
                                                                    <Icon name='right chevron' />
                                                                </Style.OrderDetailButton>
                                                            </Item.Extra>
                                                        </Item.Content>
                                                    </Item>
                                                    <Style.OrderPlacedDate>{orderPlacedDate}</Style.OrderPlacedDate>
                                                </Style.Order>
                                        </Style.OrderTimelineEvent>
                                    );
                                })
                            }
                    </Style.OrderTimeline>
                </Style.OrderView>
            );
        }
    }

    async componentDidMount(){
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        const orderID = shopParams ? shopParams.itemID : null;
        if(shopOwnerInfo){
            if(orderID){
                this.orderDetailStore = this.props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS", "uid" : orderID});
                await this.orderDetailStore.refreshOrderForBuyer(orderID, shopOwnerInfo.SourceID, shopID);
                this.showOrderDetailView = true;
            } else {
                this.orderStore.loadOrdersForBuyer(shopOwnerInfo.SourceID, shopID);
            }
        }
    }

    render(){
        if(this.showOrderDetailView){
            return this.renderOrderDetailsView();
        } else {
            return this.renderOrderView();
        }
    }
}