import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Grid, Image, Modal } from 'semantic-ui-react';
import { infuseRootStyle, injectStyle } from "../utils/DecoratorUtil";
import ShopForgotPasswordView from './Shop-ForgotPasswordView';
import ShopRegisterView from './Shop-RegisterView';
import ShopSigninView from './Shop-SigninView';


const languageOptions = [
  { key: 'English', text: 'English', value: 'en' },
  { key: 'Hindi', text: 'हिंदी', value: 'hi' },
];

let RootStyledModal;
@inject("storeFactory")
@injectStyle('Login', 'LoginView')
@observer
export default class ShopLoginView extends Component {
  
    constructor(props) {
        super(props);
        props.storeFactory.ShopLoginStore.init();
        this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        RootStyledModal = infuseRootStyle(Modal, "ShopLoginView");
    }

    @action
    handleCloseLoginView = () => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        const pageID = shopParams ? shopParams.pageID : "";
        /* 
        * IF Login Popup is shown and anyone choose to close it then
        * make sure the page behind the popup doesn't match any of the below cases.
        * Below case scenarios are when the order url from email is opened and the user is not logged in.
        *
        * The default case is the common code we have to run irrespective of which page is behind.
        */
        switch(pageID){
            case navigationStore.SUBVIEW.BUYER.PROFILE  :
            case navigationStore.SUBVIEW.BUYER.ADDRESS : 
            case navigationStore.SUBVIEW.BUYER.PASSWORD : 
            case navigationStore.SUBVIEW.BUYER.ORDER :
                shopParams.pageID = this.shopStore.DefaultShopPageID;

            default :
                this.props.storeFactory.ShopLoginStore.resetAll();
                navigationStore.changeView(navigationStore.VIEW.SHOP);
        }
    }

    renderForgotPasswordView(){
        const businessLogo = this.shopStore.ShopLogo;
        const Style = this.props.componentStyle;
        return (
            <Style.LoginGrid stackable divided>
                <Style.LoginGridTitleRow columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <Style.LoginHeader textAlign="left">
                        {
                            businessLogo
                            &&
                            <Image size="massive" src={businessLogo} />
                        }
                            Forgot Password ?
                        </Style.LoginHeader>
                    </Grid.Column>
                </Style.LoginGridTitleRow>
                <Style.LoginGridBodyRow  columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <ShopForgotPasswordView SellerID={this.props.SellerID} ShopID={this.props.ShopID}/>
                    </Grid.Column>
                </Style.LoginGridBodyRow>
            </Style.LoginGrid>
        );
    }

    renderRegisterView(){
        const businessLogo = this.shopStore.ShopLogo;
        const Style = this.props.componentStyle;
        return (
            <Style.LoginGrid stackable divided>
                <Style.LoginGridTitleRow columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <Style.LoginHeader textAlign="left">
                        {
                            businessLogo
                            &&
                            <Image size="massive" src={businessLogo} />
                        }
                            Sign up
                        </Style.LoginHeader>
                    </Grid.Column>
                </Style.LoginGridTitleRow>
                <Style.LoginGridBodyRow columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <ShopRegisterView SellerID={this.props.SellerID} ShopID={this.props.ShopID} />
                    </Grid.Column>
                </Style.LoginGridBodyRow>
            </Style.LoginGrid>
        );
    }

    renderSignInView(){
        const businessLogo = this.shopStore.ShopLogo;
        const Style = this.props.componentStyle;
        return (
            <Style.LoginGrid stackable divided>
                <Style.LoginGridTitleRow columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <Style.LoginHeader textAlign="left">
                        {
                            businessLogo
                            &&
                            <Image size="massive" src={businessLogo} />
                        }
                            Login
                        </Style.LoginHeader>
                    </Grid.Column>
                </Style.LoginGridTitleRow>
                <Style.LoginGridBodyRow columns="1" stretched>
                    <Grid.Column textAlign="center" >
                        <ShopSigninView SellerID={this.props.SellerID} ShopID={this.props.ShopID} />
                    </Grid.Column>
                </Style.LoginGridBodyRow>
            </Style.LoginGrid>
        );
    }

    componentDidMount(){
        this.props.storeFactory.ShopLoginStore.scanStorage();
    }

    renderView() {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const subView = navigationStore.currentSubView;
        console.log("R. ShopLogin SubView.... : "+ subView);
        switch(subView){
            case navigationStore.SUBVIEW.SHOP.SIGNUP  : return this.renderRegisterView();
            case navigationStore.SUBVIEW.SHOP.FORGOTPASSWORD : return this.renderForgotPasswordView();
            case navigationStore.SUBVIEW.SHOP.LOGIN : return this.renderSignInView();
            default : return this.renderSignInView();
        }
    }

    render(){
        return (
            <RootStyledModal
                key="loginDialog"
                size='miniX'
                className="loginPopup"
                closeIcon
                onClose={this.handleCloseLoginView}
                open={true}
                closeOnDimmerClick={false}
                centered={true}
                $storeFactory={this.props.storeFactory} >
                    <Modal.Content>
                        {this.renderView()}
                    </Modal.Content>
            </RootStyledModal>
        );
    }

}