import { inject, observer } from 'mobx-react';
import React, { Component } from "react";

@inject("storeFactory", "shopTemplateFactory")
@observer
export default class ContentSection extends Component{

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

	render() {
        //TODO Add a check to determine if the current section template is available for the currentTheme ?
        const themeSectionID = this.props.section.DATA.ThemeSectionID;// eg: - "Default-Content1";
        const section_template_ID =  this.shopStore.ShopTheme.getSectionTemplateID(themeSectionID)// eg: - "ContentSectionTemplate1";
        const ContentSectionTemplate = this.props.shopTemplateFactory.getTemplate(section_template_ID);

        if(ContentSectionTemplate){
            return (<ContentSectionTemplate {...this.props} />);
        } else {
            return null;
        }
	}
}