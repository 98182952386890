import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

@inject("storeFactory", "shopTemplateFactory")
@observer
class ProductCardView extends Component {

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

	render() {
        //TODO Add a check to determine if the current section template is available for the currentTheme ?
        const productSection = this.shopStore.ProductSection;
        const productThemeSectionID = (productSection && productSection.ThemeSectionID) || "";// eg: - "Default-ProductsSection"
        const card_view_template_ID =  this.shopStore.ShopTheme.getCardViewTemplateID(productThemeSectionID) // eg: - "CardViewTemplate1";
        const CardViewTemplate = this.props.shopTemplateFactory.getTemplate(card_view_template_ID);

        if(CardViewTemplate){
            return (<CardViewTemplate {...this.props} pageType="PRODUCT"/>);
        } else {
            return null;
        }
	}
}

export default ProductCardView;