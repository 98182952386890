import { Provider, useStaticRendering } from 'mobx-react';
import React, { Component } from 'react';
import storeFactory from '../0-common/utils/StoreFactory';
import StoreRepository from '../0-common/utils/StoreRepository';
import ShopAppContainer from './container/Shop-AppContainer';
import pageSectionFactory from './utils/Page-Section-Factory';
import { MediaContextProvider } from "./utils/Responsive";
import shopTemplateFactory from "./utils/Shop-Template-Factory";

import languageStore from '../0-common/store/LanguageStore';

//Collect all store for Mobx Provider {: to provide store across all View, Containers }
let storeList = {
	languageStore,
	storeFactory,
	pageSectionFactory,
	shopTemplateFactory
};
/*
	1. Hydrate the StoreFactory
	2. Disable Mobx for SSR.
*/

useStaticRendering(typeof window === "undefined"); //2. Disable Mobx for SSR.

export default class Shop extends Component {

	constructor(props){
		super(props);
		if(props.ssr){
			storeList.storeFactory = props.serverStoreFactory;
		} else {
			storeList.storeFactory.StoreRepository = new StoreRepository();
			storeList.storeFactory.initializeFactory(); //1. Hydrate the StoreFactory
		}
	}

    render() {
		console.log("R. Shop ");
        return (
        	<Provider {...storeList}>
	            <MediaContextProvider>
					<ShopAppContainer />
				</MediaContextProvider>
        	</Provider>
        );
    }
}