import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import { Container } from 'semantic-ui-react';
import { infuseRootStyle } from '../utils/DecoratorUtil';
import ShopPaymentStatusView from "../view/Shop-PaymentStatusView";

let RootStyledContainer;
@inject("storeFactory")
@observer
export default class PaymentStatusScreen extends Component {

    constructor(props){
		super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.shopStore = props.storeFactory.fetchStore('PUBLICWEBSHOPS');
        RootStyledContainer = infuseRootStyle(Container, "PaymentStatusScreen");
        this.orderStore.isLoading = true;
    }
    
    async componentDidMount(){
        console.log("M. Payment Status Screen");

        const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        const shopID = shopParams ? shopParams.shopID : null; //for preview mode
        const shopLink = navigationStore.ShopLink; //for live mode
        if(shopID || shopLink){
            if(this.shopStore.OwnerInfo){
                const orderInfo = navigationStore.getContextualParam("Order");
                if(orderInfo){
                    const paymentOrderId = orderInfo.paymentOrderId;
                    const paymentId = orderInfo.paymentId;
                    const shopOwnerInfo = this.shopStore.OwnerInfo;
                    const shopID = this.shopStore.ShopID;
                    this.orderStore.executeCheckPaymentStatus(paymentOrderId, paymentId, shopOwnerInfo, shopID);
                } else {
                    this.orderStore.isLoading = false;
                }
            }
        }
    }
    
    render(){
        return (
            <RootStyledContainer fluid className="payment-screen" $injectGlobalStyle $injectFontStyle $storeFactory={this.props.storeFactory}>
                <ShopPaymentStatusView />
            </RootStyledContainer>
        );
    }
	
}
