import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Divider, Form, Grid, Icon, Item } from 'semantic-ui-react';
import { injectStyle } from '../utils/DecoratorUtil';


@inject('storeFactory')
@injectStyle("Common", "ContactUsSection")
@observer
export default class ContactUsSection extends Component {

    @observable contact_ToEmail = "";
    @observable contact_Name = "";
    @observable contact_Message = "";

    @observable contact_ToEmail_invalid = false;
    @observable contact_Name_empty = false;

    @observable messageSent = false;
    
    constructor(props){
        super(props);
        this.itemStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    @action
    handleSendMessage = async () => {
        const emailCheckRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isEmailValid = emailCheckRegex.test(this.contact_ToEmail);
        
        if(!isEmailValid){
            this.contact_ToEmail_invalid = true;
        } else {
            this.contact_ToEmail_invalid = false;
        }

        if(this.contact_Name === ""){
            this.contact_Name_empty = true;
        } else {
            this.contact_Name_empty = false;
        }

        if( !this.contact_ToEmail_invalid && !this.contact_Name_empty){
            this.messageSent =  await this.itemStore.executeSendMessage(this.contact_Name, this.contact_ToEmail, this.contact_Message);
        }
    }

    @action
    handleContactFormChange = (formField, e) => {
        switch (formField) {
            case 'Name':
                this.contact_Name = e.target.value;
                break;
            case 'Email':
                this.contact_ToEmail = e.target.value;
                break;
            case 'Message':
                this.contact_Message = e.target.value;
                break;
        }
    }

    render() {
        const shop = this.itemStore.getCurrentItem();

        const shopEmail = shop ? shop.DATA.Email : "";
        const shopMobileNumber = shop ? shop.DATA.MobileNumber : "";

        const shopStreetAddress = shop && shop.DATA.streetaddress ? shop.DATA.streetaddress : "";
        const shopLandmark = shop && shop.DATA.locatedin ? shop.DATA.locatedin : "";
        const shopCity = shop && shop.DATA.city ? shop.DATA.city : "";
        const shopPincode = shop && shop.DATA.pincode ? shop.DATA.pincode : "";
        const shopState = shop && shop.DATA.state ? shop.DATA.state : "";
        const shopCountry = shop && shop.DATA.country ? shop.DATA.country : "";

        const facebookInfo = shop ? shop.DATA.facebookurl : '';
        const instagramInfo = shop ? shop.DATA.instagramurl : '';
        const twitterInfo = shop ? shop.DATA.twitterurl : '';
        const linkedInInfo = shop ? shop.DATA.linkedin : '';
        //const whatsappInfo = shop ? shop.DATA.whatsappno : '';

        const Style = this.props.componentStyle;

        return (
            <Style.ContactUsContainer className="contactUsSection" key={this.props.section.DATA.uid}>
                <Style.ContactUsHeader as='h1' className="header">{this.props.section.DATA.Name}</Style.ContactUsHeader>
                <Style.ContactUsGrid columns={2} relaxed stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Style.InfoSegment raised>
                                <Style.ContactInfoHeader>Contact Information</Style.ContactInfoHeader>
                                <Divider />
                                <Item.Group unstackable>
                                    {
                                        ( (shopStreetAddress && shopStreetAddress.trim() !== "")  || (shopLandmark && shopLandmark.trim() !== "") || (shopCity && shopCity.trim() !== "") || (shopPincode && shopPincode.trim() !== "") || (shopState && shopState.trim() !== "") || (shopCountry && shopCountry.trim() !== "") )
                                        &&
                                        <Item>
                                            <Style.InfoIcon name="map marker alternate" circular size="large" />
                                            <Style.InfoContent>
                                                <Style.InfoContentHeader as='a'>Address</Style.InfoContentHeader>
                                                {
                                                    (shopStreetAddress && shopStreetAddress.trim() !== "")
                                                    &&
                                                    <Style.InfoContentValue>
                                                        {shopStreetAddress}, 
                                                    </Style.InfoContentValue>
                                                }
                                                {
                                                    (shopLandmark && shopLandmark.trim() !== "")
                                                    &&
                                                    <Style.InfoContentValue>
                                                        {shopLandmark},
                                                    </Style.InfoContentValue>
                                                }
                                                {
                                                    ((shopCity && shopCity.trim() !== "") || (shopPincode && shopPincode.trim() !== ""))
                                                    &&
                                                    <Style.InfoContentValue>
                                                        {shopCity} - {shopPincode}
                                                    </Style.InfoContentValue>
                                                }
                                                {
                                                    ((shopState && shopState.trim() !== "") || (shopCountry && shopCountry.trim() !== ""))
                                                    &&
                                                    <Style.InfoContentValue>
                                                        {shopState} &nbsp; {shopCountry}
                                                    </Style.InfoContentValue>
                                                }
                                            </Style.InfoContent>
                                        </Item>
                                    }
                                    {
                                        (shopMobileNumber && shopMobileNumber.trim() !== "")
                                        &&
                                        <Item>
                                            <Style.InfoIcon name="phone" circular size="large"/>
                                            <Style.InfoContent>
                                                <Style.InfoContentHeader as='a'>Phone</Style.InfoContentHeader>
                                                <Style.InfoContentValue>
                                                    +91 {shopMobileNumber}
                                                </Style.InfoContentValue>
                                            </Style.InfoContent>
                                        </Item>
                                    }
                                    {
                                        (shopEmail && shopEmail.trim() !== "")
                                        &&
                                        <Item>
                                            <Style.InfoIcon name="mail" circular size="large" />
                                            <Style.InfoContent>
                                                <Style.InfoContentHeader as='a'>Email</Style.InfoContentHeader>
                                                <Style.InfoContentValue>
                                                    {shopEmail}
                                                </Style.InfoContentValue>
                                            </Style.InfoContent>
                                        </Item>
                                    }
                                </Item.Group>
                                <div key="social" className="socialMedia" style={{textAlign: "center"}}> 
                                    {
                                        facebookInfo
                                        &&
                                        (facebookInfo.trim() !== "")
                                        &&
                                        <Style.SocialMediaInfoLabel basic as='a' href={`https://www.facebook.com/${facebookInfo}`} target="_blank">
                                            <Icon name='facebook f' circular inverted />
                                        </Style.SocialMediaInfoLabel>
                                    }
                                    {
                                        twitterInfo
                                        &&
                                        (twitterInfo.trim() !== "")
                                        &&
                                        <Style.SocialMediaInfoLabel basic as='a' href={`https://www.twitter.com/${twitterInfo}`} target="_blank">
                                            <Icon name='twitter' circular inverted/>
                                        </Style.SocialMediaInfoLabel>
                                    }
                                    {
                                        instagramInfo
                                        &&
                                        (instagramInfo.trim() !== "")
                                        &&
                                        <Style.SocialMediaInfoLabel basic as='a' href={`https://www.instagram.com/${instagramInfo}`} target="_blank">
                                            <Icon name='instagram' circular inverted/>
                                        </Style.SocialMediaInfoLabel>
                                    }
                                    {
                                        linkedInInfo
                                        &&
                                        (linkedInInfo.trim() != "")
                                        &&
                                        <Style.SocialMediaInfoLabel basic as='a' href={`https://www.linkedin.com/${linkedInInfo}`} target="_blank">
                                            <Icon name='linkedin' circular inverted/>
                                        </Style.SocialMediaInfoLabel>
                                    }
                                </div>
                            </Style.InfoSegment>
                        </Grid.Column>
                        <Grid.Column>
                            <Style.InfoSegment raised>
                                <Style.ContactForm>
                                    <Style.ContactInfoHeader>Send us a Message</Style.ContactInfoHeader>
                                    <Divider />
                                    <Form.Field required error={this.contact_Name_empty}>
                                        <label>Full Name</label>
                                        <input placeholder='Enter your name' value={this.contact_Name} onChange={this.handleContactFormChange.bind(this, 'Name')} />
                                        {this.contact_Name_empty && <span>Please provide you name.</span>}
                                    </Form.Field>
                                    <Form.Field required error={this.contact_ToEmail_invalid}>
                                        <label>Email</label>
                                        <input placeholder='Enter your email' value={this.contact_ToEmail} onChange={this.handleContactFormChange.bind(this, 'Email')} />
                                        {this.contact_ToEmail_invalid && <span>Please provide a valid email.</span>}
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Message</label>
                                        <textarea rows={3} placeholder='Type your message' value={this.contact_Message} onChange={this.handleContactFormChange.bind(this, 'Message')} />
                                    </Form.Field>
                                    <Form.Field style={{textAlign: "center"}}>
                                        {
                                            this.messageSent
                                            &&
                                            <Style.SendButton>
                                                <Icon name="check" />
                                                Message Sent
                                            </Style.SendButton>
                                        }
                                        {
                                            !this.messageSent
                                            &&
                                            <Style.SendButton onClick={this.handleSendMessage} loading={this.itemStore.sendMessageLoading} content="Send" />
                                        }
                                    </Form.Field>
                                </Style.ContactForm>
                            </Style.InfoSegment>
                        </Grid.Column>
                    </Grid.Row>
                </Style.ContactUsGrid>
            </Style.ContactUsContainer>
        );
    }
}