import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import _map from 'lodash/map';
import { action } from 'mobx';
@inject("storeFactory")
@observer
class PayPalView extends Component {

    constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    onClickValidation = (data, actions) => {
        const isPreviewOn = this.props.isPreviewOnCheckHandler();
        if(isPreviewOn){
            return actions.reject();
        } else {
            return actions.resolve();
        }
    }

    createOrder = async (data, actions) =>{
        let paymentOrderID = null;
        const order = this.orderStore.currentItem;
        const orderItems = this.orderStore.ItemsInCart;
        const orderItemList = _map(orderItems, (orderItem) => {
            
            let itemDescription = orderItem.DATA.Description;
            if(itemDescription && itemDescription.length > 127){
                itemDescription = itemDescription.substring(0, 126);
            }

            let itemName = orderItem.DATA.Name;
            if(itemName && itemName.length > 127){
                itemName = itemName.substring(0, 126);
            }

            return {
                name: itemName,
                unit_amount: {
                    currency_code : this.props.currency,
                    value : orderItem.DATA.DiscountedPrice,
                },
                quantity : orderItem.Quantity,
                description : itemDescription
            }
        });
        try {
            const orderInfo = {
                purchase_units: [
                    {
                        amount: {
                            currency_code: this.props.currency,
                            value: this.props.orderPayableAmount,
                            breakdown : {
                                item_total : {
                                    currency_code : this.props.currency,
                                    value : this.props.orderAmount,
                                },
                                shipping : {
                                    currency_code : this.props.currency,
                                    value : this.props.orderDeliveryCharge,
                                }
                            }
                        },
                        items :orderItemList,
                        shipping : {
                            name : {
                                full_name : order.DATA.OrderAddressName
                            },
                            type: "SHIPPING",
                            address : {
                                address_line_1 : (order.DATA.OrderAddressStreetAddress && order.DATA.OrderAddressStreetAddress.substring(0, 299)) || "",
                                address_line_2 : (order.DATA.OrderAddressLocatedin && order.DATA.OrderAddressLocatedin.substring(0, 299)) || "",
                                admin_area_2 : (order.DATA.OrderAddressCity && order.DATA.OrderAddressCity.substring(0, 119)) || "",
                                admin_area_1 : (order.DATA.OrderAddressState && order.DATA.OrderAddressState.substring(0, 299)) || "",
                                postal_code : order.DATA.OrderAddressPincode,
                                country_code : (order.DATA.OrderAddressCountry && order.DATA.OrderAddressCountry.toUpperCase().substring(0, 2)) || 'IN'
                            }
                        }
                    },
                ]
            };
            paymentOrderID = await actions.order.create(orderInfo);
            console.log("PAYPAL Order-ID : "+paymentOrderID);
            const shopOwnerInfo = this.shopStore.OwnerInfo;
            const shopID = this.shopStore.ShopID;
            const userInfo = this.props.storeFactory.ShopUserStore.User;
            this.orderStore.executeAddPayment(this.props.paymentSettingID, "PaypalPaymentGateway", paymentOrderID, this.props.orderAmount, this.props.currency, shopOwnerInfo, shopID, userInfo);
        } catch (error) {
            this.onError(error);
        } finally{
            return paymentOrderID;
        }
    }

    onApprove = async (data, actions) => {
        try {
            const paymentDetails =  await actions.order.capture();
            const payPalResponse = {
                paymentOrderId: paymentDetails.id,
                paymentStatus: paymentDetails.status
            }
            const sellerID = this.shopStore.OwnerInfo.SourceID;
            const shopID = this.shopStore.ShopID;
            const order = this.orderStore.currentItem;
            this.orderStore.callback_processPaypalOrderPayment(order.DATA.SourceID, sellerID, shopID, payPalResponse);
        } catch (error) {
            this.onError(error);
        }
    }

    onError(err) {
        console.log("PAYPAL ERROR : "+err.toString());
    }

    render() {
        const paypalMerchantConfig = { 
            "client-id": this.props.clientId, 
            "currency": this.props.currency 
        };
        return (
            <PayPalScriptProvider options={paypalMerchantConfig}>
                <PayPalButtons
                    disabled={this.props.disabled}
                    style={{ layout: "horizontal" }}
                    createOrder={this.createOrder}
                    onApprove={this.onApprove}
                    onClick={this.onClickValidation}
                    onError={this.onError} />
            </PayPalScriptProvider>
        );
    }
}

export default PayPalView;