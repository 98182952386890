import _take from 'lodash/take';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";
import { injectStyle } from "../utils/DecoratorUtil";
import ProductsInCollectionView from "./Products-In-CollectionView";
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@injectStyle("Collection", "ProfileView")
@observer
export default class ShopCollectionProfileView extends Component{

    constructor(props){
        super(props);
        this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.collectionStore = props.storeFactory.fetchStore("COLLECTIONS");
    }

    buildImageURLsForCollectionSliderView(item, requiredProductCount = 5){
        let requiredProducts = [];
		const productsInCollection = item.DATA.ProductsInCollection ? item.DATA.ProductsInCollection : [];
        if(requiredProductCount < productsInCollection.length){
            requiredProducts = _take(productsInCollection, requiredProductCount);
        } else {
            requiredProducts = productsInCollection;
        }
		const imageURLs = _compact(_map(requiredProducts, (productId) => {
			const imageId = item.REFERENCES[productId].ProfileImage;
			if(imageId && imageId !== ""){
                let imageCDNID = item.REFERENCE_IMAGES[imageId].SourceID;
                return "https://ucarecdn.com/" + imageCDNID + "/-/format/auto/-/quality/lightest/";
			}
		}));
		return imageURLs;
    }


    componentDidMount() {
        if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
            return;
        }
		console.log("M. ShopCollectionProfileView");
		const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP");
		const collectionCategory = shopParams.itemCategory;
		const collectionID = shopParams.itemID;
		if(collectionID){
			const sellerID = this.shopStore.OwnerInfo.SourceID;
			const shopID = this.shopStore.ShopID;
			this.collectionStore.executeFetchCollectionForShop(collectionID, collectionCategory, sellerID, shopID);
            window.document.title = `${this.shopStore.ShopName} | ${this.collectionStore.CollectionName}`;
		}
	}

    render() {
        const Style = this.props.componentStyle;
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        const collection = this.collectionStore.getCurrentItem();
        
        if(collection){
            return  (
                <Container fluid className="collectionProfileView">
                    <div>
                        <Style.CollectionTileSegment basic>
                            <Style.CollectionInfo basic>
                                <Style.CollectionNameHeader as='h2'>
                                    {collection.DATA.Name}
                                    <Header.Subheader className="ql-editor">
                                        {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                    </Header.Subheader>
                                </Style.CollectionNameHeader>
                            </Style.CollectionInfo>
                        </Style.CollectionTileSegment>
                    </div>
                    <Style.CollectionProductView fluid >
                        <ProductsInCollectionView activeCollectionID={collection.DATA.uid} sellerID={sellerID} shopID={shopID} />
                    </Style.CollectionProductView>
                </Container>
            );
        }else{
            return null;
        }
	}
}