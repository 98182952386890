import { action, observable, runInAction } from "mobx";
import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import { ArrowLeft } from "react-feather";
import { Grid, Header, Icon, List, Menu, Segment } from "semantic-ui-react";
import { injectStyle } from "../utils/DecoratorUtil";
import { Media } from "../utils/Responsive";
import ShopBuyerAddressView from "../view/Shop-BuyerAddressView";
import ShopBuyerChangePasswordView from "../view/Shop-BuyerChangePasswordView";
import ShopBuyerOrderView from "../view/Shop-BuyerOrderView";
import ShopBuyerProfileView from "../view/Shop-BuyerProfileView";


@inject("storeFactory")
@injectStyle("Account", "AccountPage")
@observer
class AccountPage extends Component {

    @observable showAccountDetailView_mobile = false;

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    handlePageClick = (subView) => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const currentSubView = navigationStore.currentSubView;
        runInAction("On Page Click Force Refresh",() => {
            this.shopStore.ActivePage = subView;
            this.showAccountDetailView_mobile = true;
            if(currentSubView === subView)
                navigationStore.changeView(navigationStore.VIEW.SHOP);
        });
        setTimeout( () => {
            navigationStore.changeView(navigationStore.VIEW.SHOP, subView);
        }, 1);
    }

    @action
    handleLinkClick = (subView) => {
        this.showAccountDetailView_mobile = true;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP, subView);
    }

    @action
    goBackAction_AccountView = () => {
        this.showAccountDetailView_mobile = false;
    }

    getAccountSideMenu(){
        const Style = this.props.componentStyle;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const subView = navigationStore.currentSubView;
        return(
            <Style.SideMenuItem>
                <Menu.Header>
                    <Icon name="setting" /> <span>Account Settings</span>
                </Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                    link
                    name='Profile Information'
                    active={(subView === navigationStore.SUBVIEW.BUYER.PROFILE) || (subView === navigationStore.SUBVIEW.NONE) }
                    onClick={this.handlePageClick.bind(null, navigationStore.SUBVIEW.BUYER.PROFILE)}
                    />
                    <Menu.Item
                    link
                    name='Manage Address'
                    active={subView === navigationStore.SUBVIEW.BUYER.ADDRESS}
                    onClick={this.handleLinkClick.bind(null, navigationStore.SUBVIEW.BUYER.ADDRESS)}
                    />
                    <Menu.Item
                    link
                    name='Change Password'
                    active={subView === navigationStore.SUBVIEW.BUYER.PASSWORD}
                    onClick={this.handleLinkClick.bind(null, navigationStore.SUBVIEW.BUYER.PASSWORD)}
                    />
                </Menu.Menu>
            </Style.SideMenuItem>
        );
    }

    getOrderSideMenu(){
        const Style = this.props.componentStyle;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const subView = navigationStore.currentSubView;
        return(
            <Style.SideMenuItem>
                <Menu.Header>
                    <Icon name="box" /> <span>My Orders</span>
                </Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                    link
                    name="Orders And Returns"
                    active={subView === navigationStore.SUBVIEW.BUYER.ORDER }
                    onClick={this.handlePageClick.bind(null, navigationStore.SUBVIEW.BUYER.ORDER)}
                    />
                </Menu.Menu>
            </Style.SideMenuItem>
        );
    }

    renderProfileView(){
        return <ShopBuyerProfileView />
    }

    renderAddressView(){
        return <ShopBuyerAddressView context="ACCOUNT" />
    }

    renderPasswordView(){
        return <ShopBuyerChangePasswordView />
    }

    renderOrderView(){
        return <ShopBuyerOrderView />
    }

    renderActivePage() {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const subView = navigationStore.currentSubView;
        console.log("R. ShopAccountView SubView.... : "+ subView);
        switch(subView){
            case navigationStore.SUBVIEW.BUYER.PROFILE  : return this.renderProfileView();
            case navigationStore.SUBVIEW.BUYER.ADDRESS : return this.renderAddressView();
            case navigationStore.SUBVIEW.BUYER.PASSWORD : return this.renderPasswordView();
            case navigationStore.SUBVIEW.BUYER.ORDER : return this.renderOrderView();
            default : return this.renderProfileView();
        }
    }

    renderMobileAccountView(){
        const Style = this.props.componentStyle;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const currentPage = this.props.pageInfo;

        if(currentPage && currentPage.uid === navigationStore.SUBVIEW.BUYER.PROFILE){

            if(this.showAccountDetailView_mobile){
                return(
                    <Style.MobileAccountDetailView fluid>
                        <Style.MobileBackButtonSegment basic>
                            <ArrowLeft size="30px" strokeWidth="1px" onClick={this.goBackAction_AccountView} />
                        </Style.MobileBackButtonSegment>
                        {this.renderActivePage()}
                    </Style.MobileAccountDetailView>
                );
            } else {
                const userInfo = this.props.storeFactory.ShopUserStore.User;
                if(!userInfo.DATA){
                    return null;
                }

                return(
                    <Style.MobileAccountView fluid>
                        <Segment raised>
                            <Style.MobileAccountHeader icon>
                                <Icon name='user outline' circular />
                                <Header.Content>
                                    {userInfo.DATA.Name}
                                    <Header.Subheader>
                                        {userInfo.DATA.Email}
                                    </Header.Subheader>
                                </Header.Content>
                            </Style.MobileAccountHeader>
                        </Segment>
                        <Segment>
                            <Style.MobileAccountMenuList relaxed divided verticalAlign='middle'>
                                <List.Item onClick={this.handlePageClick.bind(null, navigationStore.SUBVIEW.BUYER.PROFILE)}>
                                    <List.Content floated='right'>
                                        <List.Icon name='caret right' />
                                    </List.Content>
                                    <List.Icon name='address book outline' />
                                    <List.Content>
                                        <List.Header>Profile Information</List.Header>
                                        <List.Description>
                                            Change you profile information.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item onClick={this.handleLinkClick.bind(null, navigationStore.SUBVIEW.BUYER.ADDRESS)}>
                                    <List.Content floated='right'>
                                        <List.Icon name='caret right' />
                                    </List.Content>
                                    <List.Icon name='map marker alternate' />
                                    <List.Content>
                                        <List.Header>Manage Address</List.Header>
                                        <List.Description>
                                            Save addresses for faster checkout.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item onClick={this.handleLinkClick.bind(null, navigationStore.SUBVIEW.BUYER.PASSWORD)}>
                                    <List.Content floated='right'>
                                        <List.Icon name='caret right' />
                                    </List.Content>
                                    <List.Icon name='user secret' />
                                    <List.Content>
                                        <List.Header>Change Password</List.Header>
                                        <List.Description>
                                            Secure your account password.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </Style.MobileAccountMenuList>
                        </Segment>
                    </Style.MobileAccountView>
                );
            }
        } else if(currentPage && currentPage.uid === navigationStore.SUBVIEW.BUYER.ORDER){
            return (
                <Style.MobileOrderViewContainer fluid>
                    {this.renderActivePage()}
                </Style.MobileOrderViewContainer>
            );
        } else {
            return null;
        }
    }

    renderTabletAccountView(){
        const Style = this.props.componentStyle;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const currentPage = this.props.pageInfo;

        if(currentPage && currentPage.uid === navigationStore.SUBVIEW.BUYER.PROFILE){
            return(
                <Style.TabletAccountViewGrid>
                    <Grid.Column width={6}>
                        <Menu fluid vertical>
                            {this.getAccountSideMenu()}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={10} stretched>
                        {this.renderActivePage()}
                    </Grid.Column>
                </Style.TabletAccountViewGrid>
            );
        } else if(currentPage && currentPage.uid === navigationStore.SUBVIEW.BUYER.ORDER){
            return (
                <Style.TabletOrderViewContainer fluid>
                    {this.renderActivePage()}
                </Style.TabletOrderViewContainer>
            );
        } else {
            return null;
        }
    }

	renderAccountView() {
        const Style = this.props.componentStyle;
        return(
            <Style.AccountView fluid>
                <Style.AccountViewGrid>
                    <Grid.Column width={4}>
                        <Menu fluid vertical>
                            {this.getAccountSideMenu()}
                            {this.getOrderSideMenu()}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column stretched width={12} className="active-view">
                        <Style.ActiveView>
                            {this.renderActivePage()}
                        </Style.ActiveView>
                    </Grid.Column>
                </Style.AccountViewGrid>
            </Style.AccountView>
        );
    }

    componentDidMount(){
        //Reset the Scroll to top.
        const shopBodyDOM = document.querySelector('.shopBody');
        shopBodyDOM.scrollTop = 0;
        window.document.title = this.shopStore.ShopMetaData.og_title_data;
    }

    render(){
        const bodyClassList = "responsiveAccountPage";
        return(
            <>
                <Media at="mobile" className={bodyClassList}>
                    {this.renderMobileAccountView()}
                </Media>
                <Media at="tablet" className={bodyClassList}>
                    {this.renderTabletAccountView()}
                </Media>
                <Media greaterThanOrEqual="laptop" className={bodyClassList}>
                    {this.renderAccountView()}
                </Media>
            </>
        );
    }
}

export default AccountPage;