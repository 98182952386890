import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Trash2 } from "react-feather";
import { Button, Container, Dimmer, Divider, Grid, Header, Icon, Item, List, Loader, Modal, Segment } from "semantic-ui-react";
import { ph_emptyImageURL } from '../../0-common/utils/DefaultImage';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { injectStyle } from "../utils/DecoratorUtil";
import PayPalView from './PayPalView';
import ShopBuyerAddressView from './Shop-BuyerAddressView';
import ShopFooterView from "./Shop-FooterView";

@inject("storeFactory")
@injectStyle("Checkout", "CheckoutView")
@observer
export default class CheckoutView extends Component {
    
    @observable activeStep = 1;
    @observable stepsCompleted = 0;
    @observable showAddressFormView = false;
    @observable previewModeOn = false;
    @observable notifyAddAddress = false;
    emitterBindList = [];
    deliveryAddressSet = false;


	constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.bindEvents(props);
    }
    
    bindEvents = (props) => {
		this.emitterBindList.push(emitter.on(EVENTS.CHECKOUT.SET_DELIVERY_ADDRESS, this.handleSetDeliveryAddress) );
	}

	unBindEvents = () => {
		this.emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.emitterBindList = [];
	}

    get Currency(){
        return this.props.storeFactory.CurrencyStore.Currency;
    }

    get CurrencyCode(){
        return this.Currency.source.toUpperCase();
    }

    @action.bound
    handleContinueAction(){
        if(this.deliveryAddressSet){
            this.activeStep++;
            this.stepsCompleted++;
            emitter.emit(EVENTS.CART.CHECKOUT.ADD_SHIPPING_INFO);
        } else {
            this.notifyAddAddress = true;
            setTimeout(() => {
                this.notifyAddAddress = false;
            }, 3500);
        }
    }

    @action.bound
    handleBackAction(){
        this.activeStep--;
        this.stepsCompleted--;
    }

    @action
    handleRemoveItemFromCart = (orderItemToBeRemoved) => {
        emitter.emit(EVENTS.CART.REMOVE, orderItemToBeRemoved);
        /*const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeRemoveProductFromCart(shopOwnerInfo.SourceID, shopID, orderItemIDToBeRemoved);*/
    }
    
    @action
    handleQuantityChange = (updateOps, currentQuantity, productInfo) => {
        try{
            let productQuantity = 0;
            if(updateOps === "add"){
                productQuantity = currentQuantity + 1;
            } else {//Case of subtract
                if(currentQuantity != 1)
                    productQuantity = currentQuantity - 1;
                else 
                    return;
            }
            emitter.emit(EVENTS.CART.UPDATE, productInfo, productQuantity, updateOps);
        }
        catch (error){
            let msg = '';
            if(updateOps === "add"){
                msg = `On Click ' + Add Quantity ' : ${error.message}`
            }else{
                msg = `On Click ' - Remove Quantity' : ${error.message}`
            }
            emitter.emit(EVENTS.SHOP.ERROR, msg, "Checkout Page");
        }
        /*let productQuantity = 0;
        if(op === "add"){
            productQuantity = currentQuantity + 1;
        } else {//Case of subtract
            if(currentQuantity != 1)
                productQuantity = currentQuantity - 1;
            else 
                return;
        }
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeUpdateProductInCart(shopOwnerInfo.SourceID, shopID, orderItemID, productQuantity);*/
    }

    isPreviewModeOn = () => {
        if(this.shopStore.isPreviewModeOn){
            this.previewModeOn = true;
            return true;
        }
        return false;
    }

    @action
    handlePayAction = (paymentSettingId, paymentProvider, totalAmount, currency) => {
        if(!this.isPreviewModeOn()){
            const shopOwnerInfo = this.shopStore.OwnerInfo;
            const shopID = this.shopStore.ShopID;
            const userInfo = this.props.storeFactory.ShopUserStore.User;
            this.orderStore.executeInitiatePayment(paymentSettingId, paymentProvider, totalAmount, currency, shopOwnerInfo, shopID, userInfo);
            
        }
    }

    handleSetDeliveryAddress = async (addressID) => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        const response_DeliveryAddressSet = await this.orderStore.setDeliveryAddress(addressID, shopOwnerInfo, shopID);
        return response_DeliveryAddressSet;
    }

    handleRemoveDeliveryAddress = () => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        this.orderStore.removeDeliveryAddress(shopOwnerInfo, shopID);
    }

    handleClosePreviewWarning = () => {
        this.previewModeOn = false;
    }

    renderDeliveryStepView(){
        const activeOrder = this.orderStore.currentItem;

        if(activeOrder && activeOrder.DATA){
            let orderAddressID = null;
            const orderAddressList = activeOrder.DATA.OrderAddress;
            if(orderAddressList && orderAddressList.length > 0){
                orderAddressID = orderAddressList[0];
                this.deliveryAddressSet = true;
                return (
                    <ShopBuyerAddressView 
                        context="CHECKOUT" 
                        selectedAddress={orderAddressID}
                        onRemoveDeliveryAddress={this.handleRemoveDeliveryAddress} 
                        notifyAddAddress={this.notifyAddAddress}
                        isLoading_SetDeliveryAddress={this.orderStore.isLoading_SetDeliveryAddress} />
                );
            } else {
                this.deliveryAddressSet = false;
                return (
                    <ShopBuyerAddressView 
                        context="CHECKOUT" 
                        onAddNewAddress={this.handleSetDeliveryAddress} 
                        notifyAddAddress={this.notifyAddAddress} />
                );
            }
        }
    }

    /*renderOrderItemView(orderItem){
        const options = [
            { key: 1, text: ' 1 ', value: 1 },
            { key: 2, text: ' 2 ', value: 2 },
            { key: 3, text: ' 3 ', value: 3 },
            { key: 4, text: ' 4 ', value: 4 },
            { key: 5, text: ' 5 ', value: 5 },
        ];

        let productImageUrl = ph_emptyImageURL;
        const imgRefId = orderItem.DATA.ProfileImage;
        if(imgRefId) {
            const imgRef = orderItem.REFERENCE_IMAGES[imgRefId];
            if(imgRef)
                productImageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/"; 
        }
        const imageURL = productImageUrl+"/-/format/auto/-/quality/smart/";

        let selectedSizeText = "";
        let showSizeOption = true;
        if(orderItem.Option === 'ONE_SIZE'){
            showSizeOption = false;
        } else {
            const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
            const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
            if(sizeOption)
                selectedSizeText  = sizeOption.text;
        }

        const categorydictonary = this.props.storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
        const category = _find(categorydictonary, ['key', orderItem.DATA.Category]);
        const categoryName = category ? category.text : "$$$";
        
        const discountedPrice = new Intl.NumberFormat('en-IN').format(orderItem.DATA.DiscountedPrice);
        const retailPrice = new Intl.NumberFormat('en-IN').format(orderItem.DATA.RetailPrice);
        return(
            <Segment raised key={orderItem.DATA.uid+selectedSizeText} className="orderItem">
                <Item.Group>
                    <Item>
                        <Item.Image src={imageURL} size='tiny'/>
                        <Item.Content>
                            <Segment basic compact floated="right" className="productPrice">
                                <span style={{fontSize: "1rem",  fontWeight:"500"}}>Rs. {discountedPrice}</span>
                                <span style={{fontSize: "1rem",  fontWeight:"300", textDecoration: "line-through", color: "#76767694"}}>Rs.{retailPrice}</span>
                            </Segment>
                            <Item.Header className="productLabel">{orderItem.DATA.Name}</Item.Header>
                            <Item.Description className="productDescription">
                                {orderItem.DATA.Description}
                            </Item.Description>
                            <Item.Meta className="productCategory">
                                <span>{categoryName}</span>
                            </Item.Meta>
                            <Item.Extra>
                                {
                                    showSizeOption
                                    &&
                                    <Label basic>
                                        Size : &nbsp;
                                        {selectedSizeText}
                                    </Label>
                                }
                                <Label basic>
                                    Quantity : &nbsp;
                                    <Dropdown
                                        inline
                                        options={options}
                                        value={orderItem.Quantity}
                                        className="quantityDropdown"
                                        onChange={this.handleQuantityChange.bind(this, orderItem.OrderItemID)} />
                                </Label>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Divider />
                <Segment basic className="orderItemActions">   
                    <div className="removeAction" onClick={this.handleRemoveItemFromCart.bind(null, orderItem.OrderItemID)}>
                        <span>Remove</span>
                    </div>
                </Segment>
            </Segment>
        );
    }*/

    renderOrderItemView(orderItem){
        
        const Style = this.props.componentStyle;
        let productImageUrl = ph_emptyImageURL;
        const imgRefId = orderItem.DATA.ProfileImage;
        if(imgRefId) {
            const imgRef = orderItem.REFERENCE_IMAGES[imgRefId];
            if(imgRef)
                productImageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/"; 
        }
        //const imageURL = productImageUrl+"/-/format/auto/-/quality/smart/";

        let selectedSizeText = "";
        let showSizeOption = true;
        if(orderItem.Option === 'ONE_SIZE'){
            showSizeOption = false;
        } else {
            const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
            const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
            if(sizeOption)
                selectedSizeText  = sizeOption.text;
        }

        const categorydictonary = this.props.storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
        const category = _find(categorydictonary, ['key', orderItem.DATA.Category]);
        const categoryName = category ? category.text : "$$$";
        
        const discountedPrice = this.Currency.format(orderItem.DATA.DiscountedPrice);
        const retailPrice = orderItem.DATA.RetailPrice ? this.Currency.format(orderItem.DATA.RetailPrice) : null;

        //Added for capturing G4 Analytics
        const categoryNameList = _compact(_map(orderItem.DATA.Categories, (itemCategoryID) => {
			const category = _find(categorydictonary, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));
        const productInfo = {
            productId: orderItem.DATA.uid,
            productOptionText: selectedSizeText,
            productName: orderItem.DATA.Name,
            productRetailPrice: (orderItem.DATA.RetailPrice || orderItem.DATA.DiscountedPrice),
            productDiscountedPrice: orderItem.DATA.DiscountedPrice,
            productBrand: orderItem.DATA.Brand,
            productCategoryList: categoryNameList,
            OrderItemID: orderItem.OrderItemID,
            context: "Checkout Page",
        }

        return(
            <Style.OrderItem raised key={orderItem.DATA.uid+selectedSizeText}>
                <Item.Group>
                    <Item>
                        <Item.Image src={productImageUrl} size='small'/>
                        <Item.Content>
                            <Style.OrderItemPrice basic compact floated="right">
                                <span className="discounted-price">{discountedPrice}</span>
                                {
                                    retailPrice
                                    &&
                                    <span className="retail-price">{retailPrice}</span>
                                }
                            </Style.OrderItemPrice>
                            <Style.OrderItemLabel>{orderItem.DATA.Name}</Style.OrderItemLabel>
                            <Style.OrderItemCategory>
                                <span>{categoryName}</span>
                            </Style.OrderItemCategory>
                            <Item.Extra>
                                {
                                    showSizeOption
                                    &&
                                    <Style.OrderItemSizeBox>
                                        <span className="sizeLabel">Size : </span>
                                        <span className="sizeValue">{selectedSizeText}</span>
                                    </Style.OrderItemSizeBox>
                                }
                                <Style.OrderItemQuantityBox>
                                    <label>Quantity : </label>
                                    <div className="modifier">
                                        <span disabled={orderItem.Quantity === 1} className="action subtract" onClick={this.handleQuantityChange.bind(this, "subtract", orderItem.Quantity, productInfo)}>-</span>
                                        <span className="quantity">{orderItem.Quantity}</span>
                                        <span className="action add" onClick={this.handleQuantityChange.bind(this, "add", orderItem.Quantity, productInfo)}>+</span>
                                    </div>
                                </Style.OrderItemQuantityBox>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Divider />
                <Style.OrderItemActions basic>   
                    <div className="removeAction" onClick={this.handleRemoveItemFromCart.bind(null, productInfo)}>
                        <Trash2 size="16" color="red" strokeWidth="1px"/><span>Remove</span>
                    </div>
                </Style.OrderItemActions>
            </Style.OrderItem>
        );
    }

    renderOrderSummaryStepView(){
        const Style = this.props.componentStyle;
        const orderItems = this.orderStore.ItemsInCart;

        if(orderItems.length > 0){
            return(
                <Style.OrderSummaryView basic>
                    <Style.OrderSummaryHeader content="Review your order"/>
                    <Divider />
                    <Style.OrderList basic>
                        {
                            _map(orderItems, (orderItem) => {
                                return this.renderOrderItemView(orderItem)
                            })
                        }
                    </Style.OrderList>
                    <Dimmer active={this.orderStore.isLoading}>
                        <Loader>Loading ... </Loader>
                    </Dimmer>
                </Style.OrderSummaryView>
            );
        } else {
            return (
                <Style.EmptyCartPlaceholder placeholder>
                    <Header icon>
                        <Icon name='shopping cart' />
                        <Style.EmptyCartMsg>
                            Hey, Your shopping cart is empty !
                            <br/>
                            Please add some products.
                        </Style.EmptyCartMsg>
                    </Header>
                    <Dimmer active={this.orderStore.isLoading}>
                        <Loader>Loading ... </Loader>
                    </Dimmer>
                </Style.EmptyCartPlaceholder>
            );
        }
    }
    
    renderStepView(){
        switch (this.activeStep) {
            case 1:
                return this.renderDeliveryStepView();
            case 2:
                return this.renderOrderSummaryStepView();
        }
    }

    renderPaymentButtons(totalItemCount, totalPayableAmount, totalOrderAmount, deliveryCharge){
        const Style = this.props.componentStyle;
        const paymentSettings = this.shopStore.PaymentSettings;

        return _map(paymentSettings, (paymentSettingRef) => {
            if(paymentSettingRef.IsPaymentSettingVerified){
                switch (paymentSettingRef.CategorySourceID) {
                    case "IndcatalogPaymentGateway":
                        return(
                            <Segment basic textAlign="center" style={{padding : "0", order: "1", marginBottom: "1rem"}}>
                                <Style.PayNow
                                    loading={this.orderStore.isPaymentLoading}
                                    disabled={(totalItemCount === 0) || this.CurrencyCode !== 'INR'}
                                    primary
                                    content="Pay Now"
                                    onClick={this.handlePayAction.bind(null, paymentSettingRef.uid, "IndcatalogPaymentGateway", totalPayableAmount, this.CurrencyCode)} />
                                <span style={{fontStyle:"italic", display: "block", marginTop: "0.5rem"}}>powered by Razorpay</span>
                            </Segment>
                        )
                    
                    case "PaypalPaymentGateway":
                        return(
                            <Segment basic textAlign="center" style={{padding : "0", order: "2"}}>
                                <PayPalView
                                    clientId={paymentSettingRef.MerchantID}
                                    paymentSettingID={paymentSettingRef.uid}
                                    orderPayableAmount={totalPayableAmount}
                                    orderAmount={totalOrderAmount}
                                    orderDeliveryCharge={deliveryCharge}
                                    totalItemCount={totalItemCount}
                                    disabled={(totalItemCount === 0) || this.CurrencyCode === 'INR'}
                                    currency={this.CurrencyCode}
                                    isPreviewOnCheckHandler={this.isPreviewModeOn} />
                            </Segment>
                        );
                }
            }
        });
    }

    renderPriceSummaryView(){
        const Style = this.props.componentStyle;
        const order = this.orderStore.currentItem;
        const orderItems = this.orderStore.ItemsInCart;
        const shop = this.shopStore.getCurrentItem();
        const deliveryCharge = shop.DATA.DeliveryCharge || 0;
        const totalItemCount = this.orderStore.ItemCountInCart;
        let totalOrderAmount = order.DATA ? order.DATA.OrderAmount: 0;
        let totalPayableAmount = totalOrderAmount + deliveryCharge;
        let totalOrderAmountToDisplay = this.Currency.format(totalOrderAmount);
        let totalPayableAmountToDisplay = this.Currency.format(totalOrderAmount + deliveryCharge);
        let totalSaving = 0;
        _forEach(orderItems, (orderItem) => {
            const retailPrice = orderItem.DATA.RetailPrice ? orderItem.DATA.RetailPrice : orderItem.DATA.DiscountedPrice;
            const discountedPrice = orderItem.DATA.DiscountedPrice;
            totalSaving =  totalSaving + ( (retailPrice - discountedPrice) * orderItem.Quantity)
        });
        var dplaces = totalSaving == parseInt(totalSaving, 10) ? 0 : 2;
        totalSaving = this.Currency.format(totalSaving.toFixed(dplaces));

        return(
            <Style.PriceSummaryView>
                <Style.PriceSummaryHeader attached='top'>
                    Price Details
                </Style.PriceSummaryHeader>
                <Segment attached>
                    <List verticalAlign='middle'>
                        <List.Item>
                            <Style.TotalAmount floated='right'>
                                {totalOrderAmountToDisplay}
                            </Style.TotalAmount>
                            <Style.TotalQuantity>
                                Price({totalItemCount} Item)
                            </Style.TotalQuantity>
                        </List.Item>
                        <List.Item>
                            <Style.DeliveryAmount floated='right'>
                                <span>{this.shopStore.DeliveryCharge}</span>
                            </Style.DeliveryAmount>
                            <Style.DeliveryText>Delivery Charges</Style.DeliveryText>
                        </List.Item>
                    </List>
                    <Divider />
                    <Segment basic clearing style={{padding: "0"}}>
                        <Style.TotalPayableText floated='left'>
                            Total Payable
                        </Style.TotalPayableText>
                        <Style.TotalPayableAmount floated='right'>
                            <span>{totalPayableAmountToDisplay} </span>
                        </Style.TotalPayableAmount>
                    </Segment>
                </Segment>
                <Style.TotalSavings attached='bottom'>
                    <span>Your total saving {totalSaving}</span> 
                </Style.TotalSavings>
                {
                    this.activeStep === 1
                    &&
                    <Segment basic textAlign="center" style={{padding : "0"}}>
                        <Style.Continue 
                            primary 
                            content="Continue"
                            onClick={this.handleContinueAction} />
                    </Segment>
                }
                <div style={{display: "flex", flexDirection: "column"}}>
                    {
                        this.activeStep === 2
                        &&
                        this.renderPaymentButtons(totalItemCount, totalPayableAmount, totalOrderAmount, deliveryCharge)
                    }
                </div>
            </Style.PriceSummaryView>
        );
    }

    componentWillUnmount() {
        this.unBindEvents();
    }

	render(){
        const Style = this.props.componentStyle;
        /*const checkpointAddressClassList = classNames({
            checkpoint : true,
            address : true,
            active : (this.activeStep === 1)
        });

        const checkpointOrderSummaryClassList = classNames({
            checkpoint : true,
            orderSummary : true,
            active : (this.activeStep === 2)
        });*/

        /*const connectorClassList = classNames({
            line : true,
            active : (this.activeStep === 2)
        });*/

        return (
            <Style.CheckoutBody fluid className="shopCheckoutBody">
                <Style.CheckoutView className="checkoutView">
                    <Grid as={Container} fluid columns={2} stackable doubling>
                        <Grid.Column width={11}>
                            <Style.CheckoutTimeline basic>
                                <Style.AddressCheckpoint $isActive={(this.activeStep === 1)}>
                                    <Style.CheckpointButton
                                        compact
                                        primary={this.activeStep === 1}
                                        secondary={this.activeStep === 2}
                                        content="Address"
                                        onClick={(this.activeStep === 2) ? this.handleBackAction : null}  />
                                </Style.AddressCheckpoint>
                                <Style.Connector className="connector" $isActive={(this.activeStep === 2)}>
                                    <div className="line"></div>
                                </Style.Connector>
                                <Style.SummaryCheckpoint $isActive={(this.activeStep === 2)}>
                                    <Style.CheckpointButton
                                        compact
                                        primary={this.activeStep === 2}
                                        secondary={this.activeStep === 1}
                                        content="Summary"
                                        onClick={(this.activeStep === 1) ? this.handleContinueAction : null} />
                                </Style.SummaryCheckpoint>
                            </Style.CheckoutTimeline>
                            <Style.StepView>
                                {this.renderStepView()}
                            </Style.StepView>
                        </Grid.Column>
                        <Style.StickyColumn width={5}>

                            {this.renderPriceSummaryView()}

                            <Modal
                                key="previewModeWarningDialog"
                                size='mini'
                                className='previewModeWarningPopup'
                                open={this.previewModeOn}
                                closeIcon
                                onClose={this.handleClosePreviewWarning}
                                closeOnDimmerClick={false}
                                centered={true} >
                                    <Header>
                                        <Icon name='warning sign' color="yellow"/>
                                        <Header.Content>Preview Mode</Header.Content>
                                    </Header>
                                    <Modal.Content>
                                        <div>
                                            This is a shop preview. In the live shop your customer will be shown the payment options in this step.
                                        </div>
                                        <div style={{marginTop: "2rem", textAlign: "center", width: "100%"}}>
                                            <Button primary onClick={this.handleClosePreviewWarning}>
                                                OK
                                            </Button>
                                        </div>
                                    </Modal.Content>
                            </Modal>
                        </Style.StickyColumn>
                        <Dimmer active={this.orderStore.isPaymentProcessing}>
                            <Loader>
                                <Style.PaymentProcessing>
                                    Your payment is currently being processed.
                                    <Header.Subheader>
                                        Do not refresh or close your browser.
                                    </Header.Subheader>
                                </Style.PaymentProcessing>
                            </Loader>
                        </Dimmer>
                    </Grid>
                </Style.CheckoutView>
                <ShopFooterView />
            </Style.CheckoutBody>
            
        );
    }
}