import _forEach from 'lodash/forEach';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { infuseRootStyle, injectStyle } from '../utils/DecoratorUtil';

let RootStyledModal;

@inject("storeFactory")
@injectStyle("Common", "SignOutView")
@observer
export default class SignOutView extends Component {

    @observable showSignOutWarning = false;

    emitterBindList = {};

    constructor(props){
        super(props);
        RootStyledModal = infuseRootStyle(Modal, "SignOutView");
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.shopLoginStore = props.storeFactory.ShopLoginStore;
        this.bindEvents(props);
    }

    bindEvents = (props) => {
        this.emitterBindList[EVENTS.SHOP.SIGNOUT] = emitter.on(EVENTS.SHOP.SIGNOUT, this.showSignOutWarningPopup);
	}

	unBindEvents = () => {
		_forEach(this.emitterBindList, (unbindRef, key) => {
			unbindRef();
		})
		this.emitterBindList = {};
	}

    @action
    showSignOutWarningPopup = () => {
        this.showSignOutWarning = true;
    }

    @action
    closeSignOutWarningPopup = () => {
        this.showSignOutWarning = false;
    }
    
    handleShopSignOut = () =>{
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        this.shopLoginStore.executeLogout(sellerID, shopID);
    }

    componentWillUnmount(){
		this.unBindEvents();
    }

    render() {
        const Style = this.props.componentStyle;
        return (
            <RootStyledModal
                key="confirmSignOut"
                centered
                open={this.showSignOutWarning}
                size="miniX"
                dimmer="blurring"
                closeOnDimmerClick={false}
                $storeFactory={this.props.storeFactory}>

                <Style.SignOutHeader>
                    <Icon name='warning sign' color="yellow" />
                    <Header.Content>Sign Out</Header.Content>
                </Style.SignOutHeader>
                <Style.SignOutContent>

                    <Segment basic textAlign="center">
                        <Style.SignOutMessage icon>
                            {
                                this.shopLoginStore.isLoggingOut
                                &&
                                <Icon name='circle notch' style={{ color: "var(--primary-color)" }} circular loading />
                            }
                            {
                                !this.shopLoginStore.isLoggingOut
                                &&
                                !this.shopLoginStore.loggedOut
                                &&
                                <Icon name='power off' style={{ color: "var(--primary-color)" }} circular />
                            }
                            {
                                this.shopLoginStore.loggedOut
                                &&
                                <Icon name='checkmark' style={{ color: "var(--primary-color)" }} circular />
                            }
                            {
                                !this.shopLoginStore.loggedOut
                                &&
                                <Header.Content>
                                    Are you sure you want to sign out?
                                    <Header.Subheader>
                                        you can always sign back in any time.
                                    </Header.Subheader>
                                </Header.Content>
                            }
                            {
                                this.shopLoginStore.loggedOut
                                &&
                                <Header.Content>
                                    Signed out successfully
                                </Header.Content>
                            }
                        </Style.SignOutMessage>
                    </Segment>

                </Style.SignOutContent>
                {
                    !this.shopLoginStore.loggedOut
                    &&
                    <Style.SignOutActions>
                        <Style.SignOutButton
                            content="Cancel"
                            onClick={this.closeSignOutWarningPopup} />

                        <Style.SignOutButton
                            primary
                            loading={this.shopLoginStore.isLoggingOut}
                            content="Sign Out"
                            onClick={this.handleShopSignOut} />
                    </Style.SignOutActions>
                }
                {
                    this.shopLoginStore.loggedOut
                    &&
                    <Style.SignOutActions>
                        <Style.SignOutButton
                            content="Close"
                            onClick={this.closeSignOutWarningPopup} />
                    </Style.SignOutActions>
                }
            </RootStyledModal>
        );
    }
}