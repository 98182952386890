
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _without from 'lodash/without';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import HeaderBaseStyle from '../template/header/header-template/base/Style-HeaderBaseTemplate';
import WindowUtil from '../utils/WindowUtil';


@inject('storeFactory')
@observer
export default class SideBarMenuView extends Component {

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    get TemplateStyle(){
        const headerSection = this.shopStore.HeaderSection;
        const headerThemeSectionID = (headerSection && headerSection.ThemeSectionID) || "";// eg: - "Default-HeaderSection"
        const header_template_ID =  this.shopStore.ShopTheme.getHeaderTemplateID(headerThemeSectionID) // eg: - "HeaderTemplate1";
        const shopThemeID =  this.shopStore.ShopTheme.ThemeID;
        const styleMap = this.props.storeFactory.ThemeFactory.getThemeStyleMap(shopThemeID);
        return styleMap["HeaderStyles"][header_template_ID];
    }

    get HeaderTheme(){
        const shopThemeID =  this.shopStore.ShopTheme.ThemeID;
        const currentTheme = this.props.storeFactory.ThemeFactory.getThemeInfo(shopThemeID);
        return currentTheme.HeaderTheme;
    }

    get isAShop(){
        const webShopCategory = this.shopStore.ShopCategory;
        return (webShopCategory === 'StandardWebShop');
    }

    @action
    onSideBarMenuClick(pageID, event){
        const navigationStore = this.props.storeFactory.NavigationStore;
        this.shopStore.ActivePage = pageID;
        if(this.shopStore.searchStore){
            this.shopStore.searchStore.clearSearchQuery();
            this.shopStore.searchStore.results = [];
            this.shopStore.searchStore.searchApplied = true;
        }
        navigationStore.changeView(navigationStore.VIEW.SHOP);
        this.closeSideBarMenu();
        event.preventDefault();
    }

    @action
    showBuyersProfileView = () => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        this.shopStore.ActivePage = navigationStore.SUBVIEW.BUYER.PROFILE;
        navigationStore.changeView(navigationStore.VIEW.SHOP, navigationStore.SUBVIEW.BUYER.PROFILE);
		//this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.BUYERACCOUNT, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.PROFILE);
        this.closeSideBarMenu();
    }

    @action
    showBuyersOrderView = () => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        this.shopStore.ActivePage = navigationStore.SUBVIEW.BUYER.ORDER;
        navigationStore.changeView(navigationStore.VIEW.SHOP, navigationStore.SUBVIEW.BUYER.ORDER);
		//this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.BUYERACCOUNT, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.ORDER);
        this.closeSideBarMenu();
    }

    @action
    showLoginView = () => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        navigationStore.changeView(navigationStore.VIEW.SHOPLOGIN, navigationStore.SUBVIEW.SHOP.LOGIN);
        this.closeSideBarMenu();
    }

    @action
    showSignOutWarningPopup = () => {
        emitter.emit(EVENTS.SHOP.SIGNOUT);
    }

    closeSideBarMenu = () => {
		emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    buildPageURL = (page) => {
        let itemURL = "";
        const navigationStore = this.props.storeFactory.NavigationStore;
        const contextualParam = navigationStore.getContextualParam("SHOP");
        const shopViewMode = contextualParam.shopMode;

        if(shopViewMode === "preview"){
            const shopCategory = this.shopStore.getCurrentItem().DATA.Category;
            const shopID = this.shopStore.getCurrentItem().DATA.uid;
            itemURL = `https://${WindowUtil.HostName}${(WindowUtil.Port ? ':' + WindowUtil.Port : '')}/p/WEBSHOPS/shop/${shopCategory}/${shopID}/${page.uid}`;
        } else { // shopViewMode === "live"
            if(contextualParam && contextualParam.shopLink){
                const shopLink = contextualParam.shopLink;
                itemURL = `https://${WindowUtil.HostName}${(WindowUtil.Port ? ':' + WindowUtil.Port : '')}/s/${shopLink}/shop/${page.uid}`;
            } else {
                itemURL = `https://${WindowUtil.HostName}${(WindowUtil.Port ? ':' + WindowUtil.Port : '')}/shop/${page.uid}`;
            }
        }
        return itemURL;
    }

    render() {
        const activePageID = this.shopStore.ActivePage;
        const pageList = this.shopStore.Pages;
        const pagesForHeader = _filter(pageList, 'ShowOnHeader');
        const pagesForFooter = _filter(pageList, 'ShowOnFooter');
        const pagesOnlyOnFooter = _without(pagesForFooter, ...pagesForHeader);
        const showSideBar = this.shopStore.showSideBar;
        const showFilterSideBar = this.shopStore.showFilterSideBar;
        const buyer = this.props.storeFactory.ShopUserStore.User;
        const navigationStore  = this.props.storeFactory.NavigationStore;
        const MobileFilterView = this.props.filterView;
        
        const Style = this.TemplateStyle;
        
        return (
            <Sidebar.Pushable as={HeaderBaseStyle.BaseHeaderDiv} $headerThemeProps={this.HeaderTheme}>
                <Style.SideBar.SideBarMenu
                    forwardedAs={Menu}
                    animation='overlay'
                    visible={showSideBar}
                    onHide={this.closeSideBarMenu}
                    fluid={this.props.isMobile}
                    vertical
                    size="massive"
			        className="menuSideBar"
                    $isMobile={this.props.isMobile}
                    $isHomePage={this.props.isHomePage} >
                        {
                            this.props.isMobile 
                            &&
                            this.isAShop
                            &&
                            <Style.SideBar.ProfileMenuItem key="profile" className="profileMenuItem">
                                {
                                    !buyer.DATA
                                    &&
                                    <Style.WelcomeSegment basic>
                                        <Style.WelcomeHeader>
                                            Welcome
                                            <Style.WelcomeSubHeader>
                                            To access account and manage orders
                                            </Style.WelcomeSubHeader>
                                        </Style.WelcomeHeader>
                                        <Style.HeaderLoginSignUpButton primary content="Login or Signup" onClick={this.showLoginView}/>
                                    </Style.WelcomeSegment>
                                }
                                {
                                    buyer.DATA
                                    &&
                                    <>
                                        <header>{buyer.DATA.Name}</header>
                                        <Menu.Menu>
                                            <Style.SideBar.SideBarMenuItem  
                                            active={activePageID === navigationStore.SUBVIEW.BUYER.PROFILE} 
                                            onClick={this.showBuyersProfileView}>
                                                <div>
                                                    <Icon name='user outline' />
                                                    <Style.HeaderMenuItemName>Account</Style.HeaderMenuItemName>
                                                </div>
                                            </Style.SideBar.SideBarMenuItem>
                                            <Style.SideBar.SideBarMenuItem
                                            active={activePageID === navigationStore.SUBVIEW.BUYER.ORDER} 
                                            onClick={this.showBuyersOrderView}>
                                                <div>   
                                                    <Icon name='box' />
                                                    <Style.HeaderMenuItemName>My Orders</Style.HeaderMenuItemName>
                                                </div>
                                            </Style.SideBar.SideBarMenuItem>
                                            <Style.SideBar.SideBarMenuItem onClick={this.showSignOutWarningPopup}>
                                                <div>
                                                    <Icon name='power off' />
                                                    <Style.HeaderMenuItemName>Sign Out</Style.HeaderMenuItemName>
                                                </div>
                                            </Style.SideBar.SideBarMenuItem>
                                        </Menu.Menu>
                                    </>
                                }
                                
                            </Style.SideBar.ProfileMenuItem>
                        }
                        <Style.SideBar.PageMenuItem key="core">
                            <Menu.Menu as="nav">
                            {
                                _map(pagesForHeader, (page) => {
                                    const isPageActive = (activePageID === page.uid);
                                    return(
                                        <Style.SideBar.SideBarMenuItem
                                            link
                                            href={this.buildPageURL(page)}
                                            key={page.key}
                                            active={isPageActive}
                                            onClick={this.onSideBarMenuClick.bind(this, page.uid)}
                                            $isMobile={this.props.isMobile}>
                                                <Style.HeaderMenuItemName>
                                                    {page.Name}
                                                </Style.HeaderMenuItemName>
                                        </Style.SideBar.SideBarMenuItem>
                                    );
                                })
                            }
                            </Menu.Menu>
                        </Style.SideBar.PageMenuItem>
                        <Style.SideBar.OtherMenuItem key="extras">
                            <Menu.Menu as="nav">
                                {
                                    _map(pagesOnlyOnFooter, (page) => {
                                        const isPageActive = (activePageID === page.uid);
                                        return(
                                            <Style.SideBar.SideBarMenuItem
                                                link
                                                key={page.key}
                                                active={isPageActive}
                                                onClick={this.onSideBarMenuClick.bind(this, page.uid)}
                                                $isMobile={this.props.isMobile}>
                                                    <Style.HeaderMenuItemName>
                                                        {page.Name}
                                                    </Style.HeaderMenuItemName>
                                            </Style.SideBar.SideBarMenuItem>
                                        );
                                    })
                                }
                            </Menu.Menu>
                        </Style.SideBar.OtherMenuItem>
                </Style.SideBar.SideBarMenu>
                {
                    this.props.isMobile
                    &&
                    <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    visible={showFilterSideBar}
                    fluid
                    size="massive"
                    className="filterMenuSideBar" >
                        
                        <MobileFilterView 
                        filterStore={this.shopStore.filterStoreForSideBar} 
                        eventContext={this.shopStore.filterEventContext}  />

                    </Sidebar>
                }
                <Sidebar.Pusher dimmed={showSideBar}>
                    {this.props.children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}