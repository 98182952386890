import classNames from 'classnames';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';
import _take from 'lodash/take';
import { observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Minus, Plus } from "react-feather";
import { Dropdown, Icon, Input } from "semantic-ui-react";
import { value } from '../../0-common/store/LanguageStore';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { injectStyle } from '../utils/DecoratorUtil';
import windowUtil from '../utils/WindowUtil';

@inject("storeFactory")
@injectStyle("Product", "FilterView")
@observer
export default class ShopFilterView extends Component {

	@observable filterSearchBox = "";
	@observable showMore = false;
	@observable isHydrationDone = true;

	constructor(props){
		super(props);
		this.checkIsHydrationDone();
		console.log("C. ShopFilterView > isHydrationDone : "+this.isHydrationDone);
	}

	/**
	 *  Make's sure to not render ShopFilterView both during SSR and CSR-Hydration.
	 */
	checkIsHydrationDone(){
		if( 
			
			(typeof window === "undefined")//Added for SSR
			|| 
			(//Added for CSR-Hydration
				window.__INITIAL_STATE_SSR_DONE__ 
				&& 
				(window.__INITIAL_STATE_SSR_DONE__ === true)
			)
		)
		{
			this.isHydrationDone = false;
		}
	}

	get shouldRenderFilterView(){
		return this.isHydrationDone;
	}

	applyFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
		this.stopPropagation(e);
	}

	removeFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
	}

	stopPropagation = (e) => {
		e.stopPropagation();
	}

	toggleShowMore(show){
		this.showMore = show;
	}	

	/*executeClearFilter = () => {
		this.props.searchStore.clearSearchQuery();
		emitter.emit(EVENTS.FILTER.CLEAR + this.props.eventContext);
	}*/

	applySearchOnFilter = (optionList, e, data) =>{
		this.filterSearchBox = data.value;
		_forEach(optionList, (options) => {
			if(options.text.toLowerCase().indexOf(data.value.toLowerCase()) > -1){
				options.hidden = false;
			} else {
				options.hidden = true;
			}
		});
	}

	selectFilterSearch = (e) => {
		this.stopPropagation(e);
	}

	resetFilterSearchBox = (optionList) => {
		this.applySearchOnFilter(optionList, null, {value: ""});
	}


	buildCategoryTreeInfo(categoryOptions){
		let bottomBorderNoneList = [];
		let topBorderList = [];
		let lastProcesseedOption = categoryOptions.length > 1 ? categoryOptions[0] : {level : 0};

		_forEach(categoryOptions, (currentOption) => {
			if(lastProcesseedOption.level > currentOption.level){
				bottomBorderNoneList.push(lastProcesseedOption.key);
				topBorderList.push(currentOption.key);
			}
			lastProcesseedOption = currentOption;
		});
		return {bottomBorderNoneList, topBorderList};
	}

	buildFilters(){
		const Style = this.props.componentStyle;
		const filters = this.props.filterStore.filterList;
		const appliedFilters = this.props.filterStore.appliedFilter;

		return _map(filters, (filter) => {
			const appliedFilterOptions = appliedFilters.get(filter.uid);
			
			const selectedFilterClassList = classNames({
		      	'selectedFilter': (appliedFilterOptions ? true : false),
		      	'link': true,
		      	'item': true
		    });
			
			const borderInfo = this.buildCategoryTreeInfo(filter.options);

			return (
				    <Style.FilterDropdown
						key={filter.uid}
				    	text={filter.text}
				    	pointing="top left" 
				    	className={selectedFilterClassList}
				    	disabled={!filter.isAvailable}
				    	onClose={this.resetFilterSearchBox.bind(this, filter.options)} >
						    <Dropdown.Menu
						    	onClick={this.stopPropagation} >
						    	{
						    		filter.options.length > 1
						    		&&
						    		<Input
							      		value={this.filterSearchBox}
							      		placeholder='Search...' 
							      		icon='search' 
							      		iconPosition='left' 
							      		className='search'
							      		onClick={this.selectFilterSearch}
							      		onChange={this.applySearchOnFilter.bind(this, filter.options)} />
						    	}
						        <Style.FilterHeader>{filter.text}</Style.FilterHeader>
						        <Dropdown.Menu scrolling >
							        {	
							        	_map(filter.options, (filterOption) => {
							        		if(filterOption.hidden)
							        			return;
											const optionFilterClassList = classNames({
										      	'filterOption': true,
										      	'categoryOption' : (filter.uid === "Categories"),
										      	'Level1' : (filterOption.level === 1),
										      	'Level2' : (filterOption.level === 2),
										      	'Level3' : (filterOption.level === 3),
										      	'Level4' : (filterOption.level === 4),
										      	'bottomBorderNone' : (_indexOf(borderInfo.bottomBorderNoneList, filterOption.key) !== -1),
										      	'topBorder' : (_indexOf(borderInfo.topBorderList, filterOption.key) !== -1)
										    });
							        		const isApplied = (_indexOf(appliedFilterOptions, filterOption.value) !== -1);
							        		return (
												<Style.FilterOption 
													key={filterOption.key}
													selected={isApplied} 
													disabled={!filterOption.isAvailable} 
													onClick={this.applyFilter.bind(this, filter.uid, filterOption.value)}
													className={optionFilterClassList} >
														<Style.FilterOptionText>{filterOption.text}</Style.FilterOptionText>
														<Style.FilterOptionCount>{filterOption.count}</Style.FilterOptionCount>
												</Style.FilterOption>
							        		);
							        	})
							        }
							    </Dropdown.Menu>
						    </Dropdown.Menu>
					</Style.FilterDropdown>
			);
		});
	}

	buildFilterView(){
		const Style = this.props.componentStyle;
		//const allowedFilterCount = (window.screen.width > 1439) ? 9 : 8;
		const allowedFilterCount = Math.floor( (windowUtil.ScreenWidth - 90 - 70) / 120 ); // [ (Screen_width - LR_Padding{90px} - ShowMoreLabel_Width{70px} ) / Avg_FilterWidth{120px} ]
		const filterList = this.buildFilters()
		let finalFilterList = null;
		if(filterList.length > allowedFilterCount && this.showMore === false){
			finalFilterList = _take(filterList, allowedFilterCount);
			const label = value('lb_filter_showmore', (filterList.length - allowedFilterCount));
			finalFilterList.push(<Style.ShowMore key="showMore" onClick={this.toggleShowMore.bind(this, true)}> <Plus size="14"/> {label} </Style.ShowMore>)
		} else {
			finalFilterList = filterList;
			if(this.showMore){
				const label = value('lb_filter_showless');
				finalFilterList.push(<Style.ShowLess key="showLess" onClick={this.toggleShowMore.bind(this, false)}> <Minus size="14"/> {label} </Style.ShowLess>)
			}	
		}
		
		return finalFilterList;
	}

	buildMsgView(){
		const Style = this.props.componentStyle;
		const appliedFilters = toJS(this.props.filterStore.appliedFilter);
		const filterList = this.props.filterStore.filterList;
		if(filterList.length > 0){
			return	_map(appliedFilters, (appliedFilterOptions, filterId) => {
						const filterRef = _find(filterList, ["uid", filterId]);
						return _map(appliedFilterOptions, (appliedOptionValue) =>{
							const filterOptionsRef = _find(filterRef.options, ["value", appliedOptionValue]);		
							return (
								<Style.SelectedFilter key={filterId + "#" + appliedOptionValue} basic>
									{filterOptionsRef.text}
									<Icon 
										name='delete' 
										onClick={this.removeFilter.bind(this, filterId, appliedOptionValue)} />
								</Style.SelectedFilter>
							);
						});
					});
		}
	}

	componentDidMount(){
		this.isHydrationDone = true;
	}

	/**
	 * Due usage of 'windowUtil.ScreenWidth', its possible for SSR generated filter view to match with 
	 * CSR generated filter view during hydration.
	 * 
	 * In order to avoid reHydration, added a new utility function
	 * - checkIsHydrationDone, which makes sure to not render ShopFilterView (i.e return null) both during SSR and CSR-Hydration.
	 * 
	 * After Hydration in 'componentDidMount' update the observable isHydrationDone to true, 
	 * which triggers the render of ShopFilterView
	 */
	renderFilterView(){
		if(this.shouldRenderFilterView){
			console.log("R. ShopFilterView");
			const filterView = this.buildFilterView();
			//const filterViewClassList = classNames('filterView', 'badgeView');
			const filterMsgView = this.buildMsgView();
			const Style = this.props.componentStyle;
			return (
				<>
					<Style.FilterView basic>
						{filterView}
					</Style.FilterView>
					<Style.SelectedFilterView>
						{filterMsgView}
					</Style.SelectedFilterView>
				</>	
			);
		}
		console.log("R. SKIPPED ShopFilterView");
		return null;
	}

	render() {
		const Style = this.props.componentStyle;
		return(
			<Style.FilterBar $visibility={this.shouldRenderFilterView}>
				{this.renderFilterView()}
			</Style.FilterBar>
		);
	}
}