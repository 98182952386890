import classNames from 'classnames';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _indexOf from 'lodash/indexOf';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Button, Grid, Header, Icon, Input, Label, List } from "semantic-ui-react";
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { injectStyle } from '../utils/DecoratorUtil';

@inject("storeFactory")
@injectStyle("Product", "FilterView")
@observer
export default class ShopFilterMobileView extends Component {

	default_ActiveFilterID = "Categories";
	@observable filterSearchBox = "";
	@observable showMore = false;
	@observable activeFilterMenu = {};

	@computed
	get ActiveFilterID(){
		if(_isEmpty(this.activeFilterMenu)){
			return this.default_ActiveFilterID;
		} else {
			return this.activeFilterMenu.uid;
		}
	}

	@computed
	get ActiveFilterOptions(){
		if(_isEmpty(this.activeFilterMenu)){
			const default_ActiveFilter = _find(this.props.filterStore.filterList, ['uid', this.default_ActiveFilterID]);
			if(default_ActiveFilter)
				return default_ActiveFilter.options;
			else 
				return [];
		} else {
			return this.activeFilterMenu.options;
		}
	}

	handleItemClick = (filter) => {
		this.activeFilterMenu = filter;
	}

	handleClearFilter = () => {
		emitter.emit(EVENTS.FILTER.BADGE.CLEAR + this.props.eventContext);
	}

	applyFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
		this.stopPropagation(e);
	}

	removeFilter = (filterId, data, e) => {
		emitter.emit(EVENTS.FILTER.BADGE.APPLY + this.props.eventContext, filterId, data);
	}

	stopPropagation = (e) => {
		e.stopPropagation();
	}

	toggleShowMore(show){
		this.showMore = show;
	}	

	/*executeClearFilter = () => {
		this.props.searchStore.clearSearchQuery();
		emitter.emit(EVENTS.FILTER.CLEAR + this.props.eventContext);
	}*/

	@action
	applySearchOnFilter = (optionList, e, data) =>{
		this.filterSearchBox = data.value;
		_forEach(optionList, (options) => {
			if(options.text.toLowerCase().indexOf(data.value.toLowerCase()) > -1){
				options.hidden = false;
			} else {
				options.hidden = true;
			}
		});
	}

	@action
	clearSearchOnFilter = (optionList) =>{
		this.filterSearchBox = "";
		_forEach(optionList, (options) => {
			options.hidden = false;
		});
	}

	selectFilterSearch = (e) => {
		this.stopPropagation(e);
	}

	resetFilterSearchBox = (optionList) => {
		this.applySearchOnFilter(optionList, null, {value: ""});
	}

	closeFilterSideBar = (e) => {
		emitter.emit(EVENTS.FILTER.SIDEBAR.CLOSE);
	}

	buildCategoryTreeInfo(categoryOptions){
		let bottomBorderNoneList = [];
		let topBorderList = [];
		let lastProcesseedOption = categoryOptions && categoryOptions.length > 1 ? categoryOptions[0] : {level : 0};

		_forEach(categoryOptions, (currentOption) => {
			if(lastProcesseedOption.level > currentOption.level){
				bottomBorderNoneList.push(lastProcesseedOption.key);
				topBorderList.push(currentOption.key);
			}
			lastProcesseedOption = currentOption;
		});
		return {bottomBorderNoneList, topBorderList};
	}

	getFilterOptionsForActiveFilter(){
		const Style = this.props.componentStyle;
		const borderInfo = this.buildCategoryTreeInfo(this.ActiveFilterOptions);
		const appliedFilters = this.props.filterStore.appliedFilter;
		const appliedFilterOptions = appliedFilters.get(this.ActiveFilterID);

		return _map(this.ActiveFilterOptions, (filterOption) => {
			if(filterOption.hidden)
				return;

			const isApplied = (_indexOf(appliedFilterOptions, filterOption.value) !== -1);
			const optionFilterClassList = classNames({
				  'filterOption': true,
				  'categoryFilterOption' : (this.ActiveFilterID === "Categories"),
				  'Level1' : (filterOption.level === 1),
				  'Level2' : (filterOption.level === 2),
				  'Level3' : (filterOption.level === 3),
				  'Level4' : (filterOption.level === 4),
				  'bottomBorderNone' : (_indexOf(borderInfo.bottomBorderNoneList, filterOption.key) !== -1),
				  'topBorder' : (_indexOf(borderInfo.topBorderList, filterOption.key) !== -1),
				  'selected' : isApplied
			});
			
			return (
				<Style.FilterMobileOption
					key={filterOption.key} 
					disabled={!filterOption.isAvailable}
					$isFilterApplied={isApplied}
					className={optionFilterClassList}
					onClick={this.applyFilter.bind(this, this.ActiveFilterID, filterOption.value)} >
					<List.Icon name='check' size="small" verticalAlign='middle'/>
					<List.Content verticalAlign='middle' className="filterOptionText">
						{filterOption.text}
					</List.Content>
					<List.Content verticalAlign='middle'>
						<Label className="filterOptionCount">{filterOption.count}</Label>
					</List.Content>
				</Style.FilterMobileOption>
			);
		}) 
	}

	getFilters(){
		const Style = this.props.componentStyle;
		const filters = this.props.filterStore.filterList;
		const appliedFilters = this.props.filterStore.appliedFilter;

		return _map(filters, (filter) => {
			const appliedFilterOptions = appliedFilters.get(filter.uid);
			const isFilterApplied = (appliedFilterOptions ? true : false);
			const isFilterActive = (this.ActiveFilterID === filter.uid);
			return (
				<Style.FilterMenuItem
					key={filter.uid}
					name={filter.text}
					className="filterMenuItem"
					active={isFilterActive}
					onClick={this.handleItemClick.bind(this, filter)}>
						{filter.text}
						{
							isFilterApplied
							&&
							<Icon.Group className="applied-filter-icon" size='small' >
								<Icon name='filter' />
								<Icon corner name='check' />
							</Icon.Group>
						}
						
				</Style.FilterMenuItem>
			);
		});
	}

	componentWillUnmount(){
		this.activeFilterMenu = {};
	}

	render() {
		const Style = this.props.componentStyle;
		if(!this.props.filterStore)
			return null;

		const appliedFilters = this.props.filterStore.appliedFilter;
		const filterMenuList = this.getFilters();
		const activeFilterOptionList = this.getFilterOptionsForActiveFilter();
		return (
			<Style.FilterMobileView className="filterMobileView">
				<Style.FilterMobileHeader basic className="filterHeader">
					<Header>
						Filters
					</Header>
					{
						(appliedFilters.size > 0)
						&&
						<Header className="clear-all" onClick={this.handleClearFilter}>
							Clear &nbsp; All
						</Header>
					}
				</Style.FilterMobileHeader>
				<Style.FilterMobileBody className="filterBody">
					<Grid.Column tablet={5} mobile={7} className="filterMenuColumn">
						<Style.FilterMenuList fluid vertical tabular className="filterMenuList">
							{filterMenuList}
						</Style.FilterMenuList>
					</Grid.Column>
					<Grid.Column stretched tablet={11} mobile={9} className="filterOptionsColumn">
						<div className="stickyItem">
							{
								(this.ActiveFilterOptions.length > 1)
								&&
								<Style.FilterOptionSearchBar className="filterOptionSearchBar">
									<Input
										fluid
										value={this.filterSearchBox}
										placeholder='Search...' 
										icon={this.filterSearchBox === "" ? 'search' : <Icon name='remove circle' link onClick={this.clearSearchOnFilter.bind(this, this.ActiveFilterOptions)} />}
										iconPosition={this.filterSearchBox === "" ? 'left' : 'right'}
										className='search'
										onClick={this.selectFilterSearch}
										onChange={this.applySearchOnFilter.bind(this, this.ActiveFilterOptions)} />
								</Style.FilterOptionSearchBar>
							}
							<Style.FilterOptionList divided relaxed verticalAlign='middle' className="filterOptionList">
								{activeFilterOptionList}
							</Style.FilterOptionList>
						</div>
						<div className="stickySibling"></div>
					</Grid.Column>
				</Style.FilterMobileBody>
				<Style.FilterMobileFooter basic className="filterFooter">
					<Button content="Close" onClick={this.closeFilterSideBar} />
					<Button primary content="Apply" onClick={this.closeFilterSideBar} />
				</Style.FilterMobileFooter>
			</Style.FilterMobileView>
		);
	}
}