import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

@inject("storeFactory", "shopTemplateFactory")
@observer
class CollectionCardView extends Component {

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

	render() {
        //TODO Add a check to determine if the current section template is available for the currentTheme ?
        const collectionSection = this.shopStore.CollectionSection;
        const collectionThemeSectionID = (collectionSection && collectionSection.ThemeSectionID) || "";// eg: - "Default-AllCollectionsSection"
        const card_view_template_ID =  this.shopStore.ShopTheme.getCardViewTemplateID(collectionThemeSectionID) // eg: - "CardViewTemplate1";
        const CardViewTemplate = this.props.shopTemplateFactory.getTemplate(card_view_template_ID);

        if(CardViewTemplate){
            return (<CardViewTemplate {...this.props} pageType="COLLECTION"/>);
        } else {
            return null;
        }
	}
}

export default CollectionCardView;