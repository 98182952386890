import { Router } from '@libs/director/build/director';
import { action, autorun, runInAction } from 'mobx';
import storeFactory from '../../0-common/utils/StoreFactory';

//Fetch url based on current view & module to use it for History mgmt
function getCurrentRoute() {
	const navigationStoreREF = storeFactory.NavigationStore;
	switch(navigationStoreREF.currentScreenName) {
		case navigationStoreREF.SCREEN.SHOP : return getShopURL();
		case navigationStoreREF.SCREEN.PAYMENT : return getPaymentStatusURL();
        case navigationStoreREF.SCREEN.NONE : return "";
    }
}

function getShopURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	console.log(" >> getShopURL : currentViewName >> "+ navigationStoreREF.currentViewName);
	switch(navigationStoreREF.currentViewName) {
        case navigationStoreREF.VIEW.SHOP : 
		case navigationStoreREF.VIEW.SHOPLOGIN  : 
		//case navigationStoreREF.VIEW.BUYERACCOUNT : 
		case navigationStoreREF.VIEW.CHECKOUT : return buildShopURL();
		case navigationStoreREF.VIEW.NONE : return "";
    }
}

function buildShopURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	const contextualParam = navigationStoreREF.getContextualParam("SHOP")
	let shopURL = "";
	let shopLink = "";
	let shopContextURL = "";
	const shopViewMode = contextualParam.shopMode;
	if(shopViewMode === "preview"){

		if(contextualParam){
			if(contextualParam.shopCategory){
				shopContextURL = contextualParam.shopCategory;
			}
			if(contextualParam.shopID){
				shopContextURL = `${shopContextURL}/${contextualParam.shopID}`;
			}	
			if(contextualParam.pageID){
				shopContextURL = `${shopContextURL}/${contextualParam.pageID}`;
			}
			if(contextualParam.itemCategory){
				shopContextURL = `${shopContextURL}/${contextualParam.itemCategory}`;
			}
			if(contextualParam.itemID){
				shopContextURL = `${shopContextURL}/${contextualParam.itemID}`;
			}
		}

		shopURL = `/p/WEBSHOPS/${navigationStoreREF.currentViewName}/${unescape(shopContextURL)}`;

	} else { // shopViewMode === "live"

		if(contextualParam){
		
			//Not Part of 'shopContextURL' 
			if(contextualParam.shopLink){
				shopLink = contextualParam.shopLink;
			}
			if(contextualParam.pageID){
				shopContextURL = contextualParam.pageID;
			}
			if(contextualParam.itemCategory){
				shopContextURL = `${shopContextURL}/${contextualParam.itemCategory}`;
			}
			if(contextualParam.itemID){
				shopContextURL = `${shopContextURL}/${contextualParam.itemID}`;
			}
		}

		if(shopLink === ""){// For Custom URL
			shopURL = `/${navigationStoreREF.currentViewName}`;
			if(shopContextURL !== "")
				shopURL = shopURL + `/${unescape(shopContextURL)}`;
		} else { //For Standard URL
			shopURL = `/s/${shopLink}/${navigationStoreREF.currentViewName}`;
			if(shopContextURL !== "")
				shopURL = shopURL + `/${unescape(shopContextURL)}`;
		}
	}
	//console.log(" >> buildShopURL : "+ shopURL);
	return shopURL;
}

function getPaymentStatusURL(){
	const navigationStoreREF = storeFactory.NavigationStore;
	const orderContextualParam = navigationStoreREF.getContextualParam("Order");
	const paymentOrderID = orderContextualParam ? orderContextualParam.paymentOrderId : "";
	const paymentID = orderContextualParam ? orderContextualParam.paymentId : "";
	const orderID = orderContextualParam ? orderContextualParam.orderId : "";
	
	const shopContextualParam = navigationStoreREF.getContextualParam("SHOP");
	let queryString = "";
	if(paymentOrderID){
		queryString = `paymentOrderId=${paymentOrderID}`;
	}
	if(paymentID){
		queryString = `${queryString}&paymentId=${paymentID}`;
	}
	if(orderID){
		queryString = `${queryString}&orderId=${orderID}`;
	}

	if(shopContextualParam && shopContextualParam.shopLink){
		return `/s/${shopContextualParam.shopLink}/payment/status/${queryString}`;
	} else {
		return `/payment/status/${queryString}`;
	}
}

export class RouterStore {

	constructor(){
		this.router = new Router();
	}

	@action
	gotoPayment_CustomURL(param){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAYMENT);
		const urlParams = new URLSearchParams(param);

		let paymentContextData = {};
		let shopContextData = {
			"shopMode" : "live"
		}
		
		const paymentOrderId = urlParams.get("paymentOrderId");
		if(paymentOrderId){
			paymentContextData.paymentOrderId = paymentOrderId;
		}

		const paymentId = urlParams.get("paymentId");
		if(paymentId){
			paymentContextData.paymentId = paymentId;
		}
		
		const orderId = urlParams.get("orderId");
		if(orderId){
			paymentContextData.orderId = orderId;
		}
		
		navigationStoreREF.setContextualParam("SHOP", shopContextData);
		navigationStoreREF.setContextualParam("Order", paymentContextData);
	}

	@action
	gotoPayment_StandardURL(shopLink, param){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.PAYMENT);
		const urlParams = new URLSearchParams(param);

		let paymentContextData = {};
		let shopContextData = {
			"shopMode" : "live",
			"shopLink" : shopLink
		}

		const paymentOrderId = urlParams.get("paymentOrderId");
		if(paymentOrderId){
			paymentContextData.paymentOrderId = paymentOrderId;
		}

		const paymentId = urlParams.get("paymentId");
		if(paymentId){
			paymentContextData.paymentId = paymentId;
		}
		
		const orderId = urlParams.get("orderId");
		if(orderId){
			paymentContextData.orderId = orderId;
		}
		
		navigationStoreREF.setContextualParam("SHOP", shopContextData);
		navigationStoreREF.setContextualParam("Order", paymentContextData);
	}

	@action
	gotoShop_PreviewMode(view, shopCategory, shopID, page, category, itemID){		
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SHOP);
		navigationStoreREF.changeView(view);
		let contextData = {
			"shopMode" : "preview",
		};

		if(shopCategory)
			contextData["shopCategory"] = shopCategory;
		if(shopID)
			contextData["shopID"] = shopID;
		if(page)
			contextData["pageID"] = page;
		if(category)
			contextData["itemCategory"] = category;
		if(itemID)
			contextData["itemID"] = itemID;

		navigationStoreREF.setContextualParam("SHOP", contextData);
	}

	@action
	gotoShop_LiveMode_StandardURL(shopLink, view, page, category, itemID){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SHOP);
		let contextData = {
			"shopMode" : "live",
			"shopLink" : shopLink,
		};
		if(view)
			navigationStoreREF.changeView(view);
		if(page)
			contextData["pageID"] = page;
		if(category)
			contextData["itemCategory"] = category;
		if(itemID)
			contextData["itemID"] = itemID;

		navigationStoreREF.setContextualParam("SHOP", contextData);
	}

	@action
	gotoShop_LiveMode_CustomURL(view, page, category, itemID){
		const navigationStoreREF = storeFactory.NavigationStore;
		navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SHOP);
		let contextData = {
			"shopMode" : "live",
		};
		if(view)
			navigationStoreREF.changeView(view);
		if(page)
			contextData["pageID"] = page;
		if(category)
			contextData["itemCategory"] = category;
		if(itemID)
			contextData["itemID"] = itemID;
		
		navigationStoreREF.setContextualParam("SHOP", contextData);
	}

	startRouter() {

	    // update state on url change
	    const routes = {
			"/payment/status/:?param": this.gotoPayment_CustomURL,
			"/p/WEBSHOPS/:view/:shopCategory/:shopID" : this.gotoShop_PreviewMode, //For localhost:8081/p/WEBSHOPS/shop/... || shop.tridasha.in/p/WEBSHOPS/shop/...
			"/p/WEBSHOPS/:view/:shopCategory/:shopID/:page" : this.gotoShop_PreviewMode, //For localhost:8081/p/WEBSHOPS/shop/... || shop.tridasha.in/p/WEBSHOPS/shop/...
			"/p/WEBSHOPS/:view/:shopCategory/:shopID/:page/:category/:itemID" : this.gotoShop_PreviewMode, //For localhost:8081/p/WEBSHOPS/shop/... || shop.tridasha.in/p/WEBSHOPS/shop/...
			"/s/:shopLink" : this.gotoShop_LiveMode_StandardURL, //For shop.tridasha.in/xyz
			"/s/:shopLink/:view" : this.gotoShop_LiveMode_StandardURL, //For shop.tridasha.in/xyz/shop/...
			"/s/:shopLink/:view/:page" : this.gotoShop_LiveMode_StandardURL, //For shop.tridasha.in/xyz/shop/...
			"/s/:shopLink/:view/:page/:category/:itemID" : this.gotoShop_LiveMode_StandardURL, //For shop.tridasha.in/xyz/shop/...
			"/s/:shopLink/payment/status/:?param": this.gotoPayment_StandardURL,
			"/:view" : this.gotoShop_LiveMode_CustomURL, //For www.shreekala.com/shop
			"/:view/:page" : this.gotoShop_LiveMode_CustomURL, //For www.shreekala.com/shop
			"/:view/:page/:category/:itemID" : this.gotoShop_LiveMode_CustomURL, //For www.shreekala.com/shop/...
	    }
	    
		this.router.param(":\\?param", "*\\b\\?*([^\/]*)");
		//this.router.param(":\\?encodedParam", "*\\?*([^\/]*)");
		//this.router.param(":\\?encodedURL", "([._a-zA-Z0-9-%()&]+=*)");
		//this.router.param(":encodedUserId", "([._a-zA-Z0-9-%()&]+=+)");
		//this.router.param(":encodedCatalogInfo", "([._a-zA-Z0-9-%()&]+=*)");
		this.router.param(":\\?REF", "*\\b\\?*([^]*)");
	    this.router.configure({
	        notfound: () => {
				runInAction("Not Found Route", () => {
					const navigationStoreREF = storeFactory.NavigationStore;
					navigationStoreREF.changeScreen(navigationStoreREF.SCREEN.SHOP);
					//default value
					const contextData = {
						"shopMode" : "live"
					}
					navigationStoreREF.setContextualParam("SHOP", contextData);
				});
	        },
	        html5history: true,
	        strict: false,
			run_handler_in_init : !(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true)
	    });
	    this.router.mount(routes);
	    this.router.init();
	};

	loadURL(path){
		this.router.dispatch('on', path.charAt(0) === '/' ? path : '/' + path);
	}

	currentRouteChangeDisposer = autorun(() => {
		const navigationStoreREF = storeFactory.NavigationStore;
		console.log("==== Router Store Autorun |  Route Change Activated.....");	
        const path = getCurrentRoute();
		//console.log("==== Router Store Autorun | Path >> "+path);	
        if ((path !== "") && (path !== "/") && (path !== window.location.pathname) && (navigationStoreREF.currentViewName !== navigationStoreREF.VIEW.SHOPLOGIN)) {
        	console.log("Pushing to History : "+path);
			window.history.pushState(null, null, path);
        }
    }, {name: "currentRouteChangeDisposer"});
}

export default new RouterStore();