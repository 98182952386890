import classNames from "classnames";
import _find from 'lodash/find';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import { action } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Trash2 } from "react-feather";
import { Dimmer, Divider, Header, Icon, Item, Loader, Modal } from "semantic-ui-react";
import { ph_emptyImageURL } from "../../0-common/utils/DefaultImage";
import { emitter, EVENTS } from "../../0-common/utils/EventEmitter";
import { infuseRootStyle, injectStyle } from "../utils/DecoratorUtil";

let RootStyledModal;
@inject("storeFactory")
@injectStyle("Common", "CartView")
@observer
export default class CartView extends Component{

    emitterBindList = [];

	constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        RootStyledModal = infuseRootStyle(Modal, "CartView");
        this.bindEvents(props);
    }

	bindEvents = (props) => {
		this.emitterBindList.push(emitter.on(EVENTS.CART.OPEN, this.handleCartOpenAction) );
	}

	unBindEvents = () => {
		this.emitterBindList.map( (unbindRef) => {
			unbindRef();
		});
		this.emitterBindList = [];
	}

    get Currency(){
        return this.props.storeFactory.CurrencyStore.Currency;
    }

	@action
	handleCartOpenAction = () => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeFetchCart(shopOwnerInfo.SourceID, shopID);
	}

	@action
	handleCartCloseAction = () => {
		this.orderStore.openCartView = false;
    }

    @action
    handleRemoveItemFromCart = (orderItemToBeRemoved) => {
        emitter.emit(EVENTS.CART.REMOVE, orderItemToBeRemoved);
        /*const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeRemoveProductFromCart(shopOwnerInfo.SourceID, shopID, orderItemIDToBeRemoved);*/
    }

    @action
    handleQuantityChange = (updateOps, currentQuantity, productInfo) => {
        try{
            let productQuantity = 0;
            if(updateOps === "add"){
                productQuantity = currentQuantity + 1;
            } else {//Case of subtract
                if(currentQuantity != 1)
                    productQuantity = currentQuantity - 1;
                else 
                    return;
            }
            emitter.emit(EVENTS.CART.UPDATE, productInfo, productQuantity, updateOps);
        }
        catch (error){
            let msg = '';
            if(updateOps === "add"){
                msg = `On Click ' + Add Quantity ' : ${error.message}`
            }else{
                msg = `On Click ' - Remove Quantity' : ${error.message}`
            }
            emitter.emit(EVENTS.SHOP.ERROR, msg, "Shopping Cart");
        }
        /*let productQuantity = 0;
        if(op === "add"){
            productQuantity = currentQuantity + 1;
        } else {//Case of subtract
            if(currentQuantity != 1)
                productQuantity = currentQuantity - 1;
            else 
                return;
        }
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeUpdateProductInCart(shopOwnerInfo.SourceID, shopID, orderItemID, productQuantity);*/
    }
    
    @action
    proceedToCheckout = () => {
        this.orderStore.openCartView = false;
        this.shopStore.ActivePage = null;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.CHECKOUT);
        localStorage.setItem('orderHomeURL', JSON.stringify(window.location.href));
        emitter.emit(EVENTS.CART.CHECKOUT.BEGIN);
    }

    renderOrderItemView(orderItem){

        const Style = this.props.componentStyle;
        let productImageUrl = ph_emptyImageURL;
        const imgRefId = orderItem.DATA.ProfileImage;
        if(imgRefId) {
            const imgRef = orderItem.REFERENCE_IMAGES[imgRefId];
            if(imgRef)
                productImageUrl = "https://ucarecdn.com/" + imgRef.SourceID + "/-/format/auto/-/quality/lightest/"; 
        }
        //const imageURL = productImageUrl+"/-/format/auto/-/quality/smart/";

        let selectedSizeText = "";
        let showSizeOption = true;
        if(orderItem.Option === 'ONE_SIZE'){
            showSizeOption = false;
        } else {
            const sizePredicate = orderItem.DATA.CATEGORY_MASTERS_Size;
            const sizeOption = _find(orderItem.OPTIONS[sizePredicate], ["source", orderItem.Option]);
            if(sizeOption)
                selectedSizeText  = sizeOption.text;
        }

        const categorydictonary = this.props.storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
        const category = _find(categorydictonary, ['key', orderItem.DATA.Category]);
        const categoryName = category ? category.text : "$$$";
        
        const discountedPrice = this.Currency.format(orderItem.DATA.DiscountedPrice);
        const retailPrice = orderItem.DATA.RetailPrice ? this.Currency.format(orderItem.DATA.RetailPrice) : null;


        //Added for capturing G4 Analytics
        const categoryNameList = _compact(_map(orderItem.DATA.Categories, (itemCategoryID) => {
			const category = _find(categorydictonary, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));
        const productInfo = {
            productId: orderItem.DATA.uid,
            productOptionText: selectedSizeText,
            productName: orderItem.DATA.Name,
            productRetailPrice: (orderItem.DATA.RetailPrice || orderItem.DATA.DiscountedPrice),
            productDiscountedPrice: orderItem.DATA.DiscountedPrice,
            productBrand: orderItem.DATA.Brand,
            productCategoryList: categoryNameList,
            OrderItemID: orderItem.OrderItemID,
            context: "Shopping Cart",
        }
        return(
            <Style.OrderItem raised key={orderItem.DATA.uid+selectedSizeText}>
                <Item.Group>
                    <Item>
                        <Item.Image src={productImageUrl} size='small'/>
                        <Item.Content>
                            <Style.OrderItemPrice basic compact floated="right">
                                <span className="discounted-price">{discountedPrice}</span>
                                {
                                    retailPrice
                                    &&
                                    <span className="retail-price">{retailPrice}</span>
                                }
                            </Style.OrderItemPrice>
                            <Style.OrderItemLabel>{orderItem.DATA.Name}</Style.OrderItemLabel>
                            <Style.OrderItemCategory>
                                <span>{categoryName}</span>
                            </Style.OrderItemCategory>
                            <Item.Extra>
                                {
                                    showSizeOption
                                    &&
                                    <Style.OrderItemSizeBox>
                                        <span className="sizeLabel">Size : </span>
                                        <span className="sizeValue">{selectedSizeText}</span>
                                    </Style.OrderItemSizeBox>
                                }
                                <Style.OrderItemQuantityBox>
                                    <label>Quantity : </label>
                                    <div className="modifier">
                                        <span disabled={orderItem.Quantity === 1} className="action subtract" onClick={this.handleQuantityChange.bind(this, "subtract", orderItem.Quantity, productInfo)}>-</span>
                                        <span className="quantity">{orderItem.Quantity}</span>
                                        <span className="action add" onClick={this.handleQuantityChange.bind(this, "add", orderItem.Quantity, productInfo)}>+</span>
                                    </div>
                                </Style.OrderItemQuantityBox>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Divider />
                <Style.OrderItemActions basic>   
                    <div className="removeAction" onClick={this.handleRemoveItemFromCart.bind(null, productInfo)}>
                        <Trash2 size="16" color="red" strokeWidth="1px"/><span>Remove</span>
                    </div>
                </Style.OrderItemActions>
            </Style.OrderItem>
        );
    }

    renderCartView(){
        const Style = this.props.componentStyle;
        const orderItems = this.orderStore.ItemsInCart;
        if(orderItems.length > 0){
            return(
                <Style.CartOrderView basic>
                    <Style.OrderList basic>
                        {
                            _map(orderItems, (orderItem) => {
                                return this.renderOrderItemView(orderItem)
                            })
                        }
                    </Style.OrderList>
                    <Dimmer active={this.orderStore.isLoading}>
                        <Loader>Loading ... </Loader>
                    </Dimmer>
                </Style.CartOrderView>
            );
        } else {
            return (
                <Style.EmptyCartPlaceholder placeholder>
                    <Header icon>
                        <Icon name='shopping cart' />
                        <Style.EmptyCartMsg>
                            Hey, Your shopping cart is empty !
                            <br/>
                            Please add some products.
                        </Style.EmptyCartMsg>
                    </Header>
                    <Dimmer active={this.orderStore.isLoading}>
                        <Loader>Loading ... </Loader>
                    </Dimmer>
                </Style.EmptyCartPlaceholder>
            );
        }
    }

    componentWillUnmount() {
        this.unBindEvents();
    }
    
    componentDidMount() {
        console.log("M. CartView");
	}
    
    render(){
        const Style = this.props.componentStyle;
        const cartDialogClassNames = classNames('cartPopup', this.props.className);

        const order = this.orderStore.currentItem;
        const orderItemCount = this.orderStore.ItemCountInCart;
        const cartIsEmpty = orderItemCount === 0;
        const countLabel = `( ${ orderItemCount > 1 ? `${orderItemCount} items` : `${orderItemCount} item`} )`;
        let totalPrice = cartIsEmpty ? 0 : this.Currency.format(order.DATA.OrderAmount);

        return (
            <RootStyledModal
                closeIcon
                key="cartDialog"
                size='tiny'
                className={cartDialogClassNames}
                open={this.orderStore.openCartView}
                onClose={this.handleCartCloseAction}
                closeOnDimmerClick={false}
                centered={true}
                $storeFactory={this.props.storeFactory} >
                    <Style.CartHeader content="Your Cart"/>
                    <Style.CartContent scrolling>
                        {this.renderCartView()}
                    </Style.CartContent>
                    <Style.CartActions>
                        <Style.CartSummaryInfo>
                            <Style.CartTotalItemCount>
                                Total {countLabel}
                            </Style.CartTotalItemCount>
                            <Style.CartTotalAmount>
                                <span>{totalPrice}</span>
                            </Style.CartTotalAmount>
                        </Style.CartSummaryInfo>
                        <Style.CartCheckout
                            primary={!cartIsEmpty}
                            disabled={cartIsEmpty}  
                            content="Checkout"
                            onClick={this.proceedToCheckout} />
                    </Style.CartActions>
            </RootStyledModal>
        );
    }
}