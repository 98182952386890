import _join from 'lodash/join';
import _map from 'lodash/map';
import _times from 'lodash/times';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Container, Divider, Form, Segment } from 'semantic-ui-react';
import { value } from '../../0-common/store/LanguageStore';
import { injectStyle } from '../utils/DecoratorUtil';


@inject('storeFactory')
@injectStyle("Login", "ForgotPasswordView")
@observer
export default class ShopForgotPasswordView extends Component{

  codeRefList = [];
  @observable editEmail = false;

  constructor(props){
    super(props);
    _times(4, () => {
        const ref = React.createRef();
        this.codeRefList.push(ref);
    });
    this.shopLoginStore = props.storeFactory.ShopLoginStore;
  }

  autoTab = (currentTabIndex, event) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    const TAB_KEY = 9;
    const SHIFT_KEY = 16;
    let elem = null;

    if((event.keyCode == TAB_KEY) || (event.shiftKey && event.keyCode == TAB_KEY) || (event.keyCode == SHIFT_KEY)) { 
        return;
    }

    if (event.keyCode == BACKSPACE_KEY) {
        elem = currentTabIndex > 0 && this.codeRefList[currentTabIndex - 1];
    } else if(event.keyCode !== DELETE_KEY){
        elem = (currentTabIndex < this.codeRefList.length - 1) && this.codeRefList[currentTabIndex + 1];
    }

    if (elem) {
        elem.current.focus();
    }
  }

  handleVerify = () => {
    const codeList = _map(this.codeRefList, (codeRef) => {
        return codeRef.current.value;
    })
    const code = _join(codeList, '');
    this.shopLoginStore.executeVerifyUserAccount(code, "RESET_PASSWORD", this.props.SellerID, this.props.ShopID);
  }

  handleCodeResend = () => {
    this.shopLoginStore.executeResendCode("RESET_PASSWORD", this.props.SellerID, this.props.ShopID);
  }

  handleChangeEmail = () => {
    this.shopLoginStore.userForgotPasswordActiveStep--;
  }

  handleContinueLogin = () => {
    this.shopLoginStore.gotoLoginView();
  }


  renderWelcomeView(){
    const Style = this.props.componentStyle;
    return(
        <Container fluid>
            <Style.FormSegment basic>
                <Style.WelcomeHeader as='h3'>
                    Your password has been set successfully.
                </Style.WelcomeHeader>
                <Style.ContinueLoginButton primary onClick={this.handleContinueLogin} loading={this.shopLoginStore.isLoading}>
                    Continue to login
                </Style.ContinueLoginButton>
            </Style.FormSegment>
        </Container>
    );
  }

  renderResetPasswordView(){
    const Style = this.props.componentStyle;
    return(
        <Container fluid>
            <Style.FormSegment basic>
                <Style.ResetPasswordHeader as='h3'>
                  {value('lb_rp_subHeader')}
                </Style.ResetPasswordHeader>
                <Form>
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='password'
                            size='big'
                            icon='lock'
                            iconPosition='left'
                            autoComplete="new-password"
                            ref={this.shopLoginStore.ref("nPassword")}
                            placeholder={value('ph_newpassword')}
                            onFocus={this.shopLoginStore.resetChangePSWDStatus} 
                            error={this.shopLoginStore.isChangePswdInValid} />
                        {
                            this.shopLoginStore.isChangePswdInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.changePswdErrorMsg}
                            </span>
                        }
                    </Style.FormField>  
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='password'
                            size='big'
                            icon='lock'
                            iconPosition='left'
                            autoComplete="new-password"
                            ref={this.shopLoginStore.ref("cPassword")}
                            placeholder={value('ph_confirmpassword')} 
                            onFocus={this.shopLoginStore.resetChangePSWDStatus} 
                            error={this.shopLoginStore.isChangePswdConfirmInValid} />
                        {
                            this.shopLoginStore.isChangePswdConfirmInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.changePswdConfirmErrorMsg}
                            </span>
                        }
                    </Style.FormField>
                    <Style.ResetPasswordButton primary onClick={this.shopLoginStore.executeChangePassword.bind(null, this.props.SellerID, this.props.ShopID)} loading={this.shopLoginStore.isLoading}>
                        {value('bt_resetpassword')} 
                    </Style.ResetPasswordButton>
                </Form>
            </Style.FormSegment>
        </Container>
    );
  }

  renderUserVerificationMsgView(){
    const Style = this.props.componentStyle;

    if(this.shopLoginStore.isVerificationCodeInValid){
        return(
            <Style.ErrorMsg>
                {this.shopLoginStore.verificationCodeErrorMsg}
            </Style.ErrorMsg>
        );
    }
    if(this.shopLoginStore.isResendCodeFailed){
        return(
            <Style.ErrorMsg>
                {this.shopLoginStore.resendCodeStatusMsg}
            </Style.ErrorMsg>
        );
    }
    if(this.shopLoginStore.isResendCodeSuccess){
        return(
            <Style.SuccessMsg>
                {this.shopLoginStore.resendCodeStatusMsg}
            </Style.SuccessMsg>
        );
    }
    if(this.shopLoginStore.isUpdateEmailSuccess){
        return(
            <Style.SuccessMsg>
                {this.shopLoginStore.updateEmailStatusMsg}
            </Style.SuccessMsg>
        );
    }
}

renderUserVerificationView(){
    const Style = this.props.componentStyle;
    return(
        <Container fluid>
            <Style.FormSegment basic>
                <Style.UserVerificationHeader as='h3'>
                    Enter the OTP sent to your email
                </Style.UserVerificationHeader>
                <Style.EmailHeader as='h3'>
                    {this.shopLoginStore.ForgotPasswordEmail}
                </Style.EmailHeader>
                <Segment basic textAlign="center" style={{margin: "0"}}>
                    <Form unstackable>
                        <Form.Group style={{justifyContent: "center"}}>
                            {
                                _times(4, (index) => {
                                    const disableAutoFocus = this.shopLoginStore.isUpdateEmailSuccess;
                                    return(
                                        <Style.FormField width={3} key={index} error={this.shopLoginStore.isVerificationCodeInValid}>
                                            <Style.CodeInputType
                                                autoFocus={ (index == 0 && !disableAutoFocus) ? true : false}
                                                type="text"
                                                autoComplete="new-text" 
                                                ref={this.codeRefList[index]}
                                                onFocus={this.shopLoginStore.resetVerifyAccountStatus}
                                                onKeyUp={this.autoTab.bind(this, index)} />
                                        </Style.FormField>
                                    )
                                })
                            }
                        </Form.Group>
                    </Form>
                    {
                        this.renderUserVerificationMsgView()
                    }
                </Segment>
                <Style.VerifyCode primary onClick={this.handleVerify} loading={this.shopLoginStore.isLoading}>
                    Verify
                </Style.VerifyCode>
                <Divider />
                <Style.OTPNotReceivedHeader as='h4'>
                    Didn't receive the OTP ?
                </Style.OTPNotReceivedHeader>
                <Style.SecondaryActions>
                    <Style.ChangeEmail secondary size="small" onClick={this.handleChangeEmail} loading={this.shopLoginStore.isLoading}>
                        Change Email
                    </Style.ChangeEmail>
                    <Style.ResendCode secondary size="small" onClick={this.handleCodeResend} loading={this.shopLoginStore.isLoading}>
                        Resend Code 
                    </Style.ResendCode>
                </Style.SecondaryActions>
            </Style.FormSegment>
        </Container>
    );
}

  renderForgotPasswordView(){
    const Style = this.props.componentStyle;
    return(
      <Container fluid>
        <Style.FormSegment basic>
            <Style.ForgotPasswordHeader as='h3' textAlign="center" style={{fontWeight: "500"}}>
                {value('lb_fp_subHeader')}
            </Style.ForgotPasswordHeader>
            <Form>
                <Style.FormField>
                    <Style.InputType
                        fluid
                        type='text'
                        size='big'
                        icon='envelope'
                        iconPosition='left'
                        autoComplete="new-text" 
                        ref={this.shopLoginStore.ref("fusername")}
                        placeholder={value('ph_email')} 
                        onFocus={this.shopLoginStore.resetForgotPSWDStatus}
                        error={this.shopLoginStore.isforgotPswdEmailEmpty} />
                    {
                    this.shopLoginStore.isforgotPswdEmailEmpty
                    &&
                    <span className="fieldErrorMsg">
                        {this.shopLoginStore.forgotPswdEmailErrorMsg}
                    </span>
                    }
                </Style.FormField>
            </Form>
            <Style.RequestPasswordResetButton primary onClick={this.shopLoginStore.executeForgotPassword.bind(null, this.props.SellerID, this.props.ShopID)} loading={this.shopLoginStore.isLoading} className="requestResetLink">
                {value('bt_request_reset_link')}
            </Style.RequestPasswordResetButton>
        </Style.FormSegment>
      </Container>
    );
  }

  render(){
      const currentStep = this.shopLoginStore.userForgotPasswordActiveStep;
      switch (currentStep) {
          case 1:
              return this.renderForgotPasswordView();
          case 2:
              return this.renderUserVerificationView();
          case 3:
              return this.renderResetPasswordView();
          case 4:
              return this.renderWelcomeView();
      }
  }
}