import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import ProductsInAllProductsView from "../view/Products-In-AllProductsView";
import ShopProductProfileView from "../view/Shop-Product-ProfileView";


@inject('storeFactory', 'storeFactory')
@observer
export default class ProductPage extends Component {
    
    constructor(props){
        super(props);
		this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
	}

	componentDidMount(){
		//Reset the Scroll to top.
		const shopBodyDOM = document.querySelector('.shopBody');
		shopBodyDOM.scrollTop = 0;
	}

    render() {
		console.log("R. ProductPage");
		const navigationStore = this.props.storeFactory.NavigationStore;
		const currentView = navigationStore.currentViewName;
		const searchFired = this.shopStore.searchStore.searchApplied;
		let productSubView = navigationStore.currentSubView;

		if(currentView === navigationStore.VIEW.SHOPLOGIN || productSubView === navigationStore.SUBVIEW.NONE){
			const shopParams = navigationStore.getContextualParam("SHOP"); 
			const productID = shopParams.itemID;
			if(productID){
				productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN;
			} else {
				productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.LIST;
			}
		}

		switch (productSubView) {
			case navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN:
				const shopParams = navigationStore.getContextualParam("SHOP");
				const productCategory = shopParams.itemCategory;
				const productID = shopParams.itemID;
				return <ShopProductProfileView productID={productID} productCategory={productCategory}/>
			
			case navigationStore.SUBVIEW.SHOP.PRODUCT.LIST:
				const shopID = this.shopStore.ShopID;
				const sellerID = this.shopStore.OwnerInfo.SourceID;
				return <ProductsInAllProductsView searchFired={searchFired} sellerID={sellerID} shopID={shopID} pageInfo={this.props.pageInfo}/>
		}
	}	
}