import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { CloudOff } from 'react-feather';
import { Header, Icon, Message, Segment } from 'semantic-ui-react';
import { TransitionModal } from 'semantic-ui-react-transition-modal';
import { infuseRootStyle, injectStyle } from '../utils/DecoratorUtil';

let RootStyledTransitionModal;

@inject('storeFactory')
@injectStyle("Common", "NetworkErrorView")
@observer
class ShopNetworkErrorView extends Component {

	constructor(props){
		super(props);
		RootStyledTransitionModal = infuseRootStyle(TransitionModal, "ShopNetworkErrorView");
	}

    @action
	handleDismiss = () => {
		this.props.storeFactory.ErrorStore.isNetworkError = false;
	}

    componentDidUpdate(){
		console.log("Update Network Error View >> "+this.props.storeFactory.ErrorStore.isNetworkError);
		if(this.props.storeFactory.ErrorStore.isNetworkError){
			this.handleDismissTimeout = setTimeout(this.handleDismiss, 3000);
		} else {
			clearTimeout(this.handleDismissTimeout);
		}
	}

	componentDidMount(){
		console.log("Mount Network Error View");
		setTimeout(this.handleDismiss, 3000);
	}

    render() {
		const Style = this.props.componentStyle;
        const isNetworkError = this.props.storeFactory.ErrorStore.isNetworkError;
        console.log("R. ShopNetworkErrorView > "+isNetworkError);
        return (
            <RootStyledTransitionModal animation="fade up" duration={500} open={isNetworkError} dimmer={false} size="mini" dimmer="blurring" closeIcon onClose={this.handleDismiss} $storeFactory={this.props.storeFactory}>
				<Style.ErrorView inverted>
					<Style.ErrorViewHeader icon>
						<Icon circular>
							<CloudOff size="32"/>
						</Icon>
						<Header.Content>No Internet</Header.Content>
					</Style.ErrorViewHeader>
					<Style.ErrorViewMessage icon size="small">
						<Icon name='warning circle' color="orange"/>
						<Message.Content>
							<Message.Header>
								Poor network connection detected.
							</Message.Header>
							Please check your connectivity!
							</Message.Content>
					</Style.ErrorViewMessage>
				</Style.ErrorView>
            </RootStyledTransitionModal>
        );
    }
}

export default ShopNetworkErrorView;