
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import 'react-whatsapp-widget/dist/index.css';


//path : src/shop/view
@inject("storeFactory", "shopTemplateFactory")
@observer
export default class ShopFooterView extends Component{

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    render(){
        const footerSection = this.shopStore.FooterSection;
        const footerThemeSectionID = (footerSection && footerSection.ThemeSectionID) || "";// eg: - "Default-FooterSection"
        const footer_template_ID =  this.shopStore.ShopTheme.getFooterTemplateID(footerThemeSectionID) // eg: - "FooterTemplate1";
        const FooterTemplate = this.props.shopTemplateFactory.getTemplate(footer_template_ID);

        return(
            <div className="footer" key="footer">
                <FooterTemplate {...this.props} />
            </div>
        );
    }
}