import parse from 'html-react-parser';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { injectStyle } from '../utils/DecoratorUtil';
import 'react-quill/dist/quill.snow.css';

const TitleDescriptionSection = function(props){
    const Style = props.componentStyle;
    return(
        <Style.TitleDescriptionContainer text key={props.section.DATA.uid}>
            <Style.TitleHeader as='h1'>
                {props.section.DATA.Name}
            </Style.TitleHeader>
            <Style.DescriptionBox>
                <div className="ql-editor">
                    {parse(props.section.DATA.Description_RT || props.section.DATA.Description ||  "")}
                </div>
            </Style.DescriptionBox>
        </Style.TitleDescriptionContainer>
    );
}

export default inject("storeFactory")(injectStyle("Common", "TitleDescriptionSection")(observer(TitleDescriptionSection)));