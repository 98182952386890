
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import _map from 'lodash/map';

@inject('pageSectionFactory', 'storeFactory')
@observer
class StandardPage extends Component {

    constructor(props){
        super(props);
        this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    componentDidUpdate(prevProps){
        //Reset the Scroll to top.
        if(this.props.pageInfo.uid !== prevProps.pageInfo.uid){
            const shopBodyDOM = document.querySelector('.shopBody');
            shopBodyDOM.scrollTop = 0;
            window.document.title = this.shopStore.ShopMetaData.og_title_data;
        }
    }

    componentDidMount(){
        //Reset the Scroll to top.
        const shopBodyDOM = document.querySelector('.shopBody');
        shopBodyDOM.scrollTop = 0;
        window.document.title = this.shopStore.ShopMetaData.og_title_data;
    }

    render() {
        const activePage = this.props.pageInfo;
        const sectionsInPage = activePage.SectionsInPage;
        return _map(sectionsInPage, (sectionId, index) => {
            const sectionRef = this.shopStore.getSectionByID(sectionId);
            const addSpacerSection = (index !== (sectionsInPage.length - 1))
            if(sectionRef){
                const sectionComponentRef = this.props.pageSectionFactory.fetchSectionInfo(sectionRef.DATA.CategorySourceID);
                const SectionComponent = sectionComponentRef.component;
                return (<SectionComponent page={activePage} section={sectionRef} key={sectionId} addSpacerSection={addSpacerSection}/>);
            }
        });
    }
}

export default StandardPage;