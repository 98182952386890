import React from 'react';
import ReactDOM from 'react-dom';
import "semantic-ui-css/semantic.min.css";
import storeFactory from '../0-common/utils/StoreFactory';
import ShopApp from './Shop';
import shopRouterStore from './store/Shop-RouterStore';


shopRouterStore.startRouter();

const mountElement = document.getElementById('app');
if( 
    (mountElement.childElementCount !== 0) 
    && 
    (
        (typeof window !== "undefined") 
        && 
        window.__INITIAL_STATE_SSR_DONE__ 
        && 
        (window.__INITIAL_STATE_SSR_DONE__ === true)
    ) 
)
{
    await storeFactory.initializeThemeFactory();
    ReactDOM.hydrate(<ShopApp />, mountElement);

} else {

    ReactDOM.render(<ShopApp />, mountElement);
}
