import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Button, Container, Divider, Form, Header, Input, Label, Segment } from 'semantic-ui-react';
import { value } from '../../0-common/store/LanguageStore';
import { injectStyle } from "../utils/DecoratorUtil";

//TODO 
// 1. Add Privacy Policy link
// 2. Add Terms & Condition link 
@inject('storeFactory')
@injectStyle('Login', 'RegisterView')
@observer
export default class ShopRegisterView extends Component{

    constructor(props){
        super(props);
        this.shopLoginStore = props.storeFactory.ShopLoginStore;
    }

    handleContinueLogin = () => {
        this.shopLoginStore.gotoLoginView();
    }

    renderWelcomeView(){
        const Style = this.props.componentStyle;
        return(
            <Container fluid>
                <Style.FormSegment basic>
                    <Style.WelcomeHeader as='h3'>
                        Your account has been successfully created.
                    </Style.WelcomeHeader>
                    <Style.ContinueLoginButton primary onClick={this.handleContinueLogin} loading={this.shopLoginStore.isLoading}>
                        Continue to login
                    </Style.ContinueLoginButton>
                </Style.FormSegment>
            </Container>
        );
    }

    renderUserRegisterationView() {
        const Style = this.props.componentStyle;
        return (
            <Style.FormSegment basic>
                <Form>
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='text'
                            size='big'
                            icon='user'
                            iconPosition='left'
                            autoComplete="new-text"
                            ref={this.shopLoginStore.ref("name")}
                            placeholder={value('ph_customername')} 
                            onFocus={this.shopLoginStore.resetRegisterStatus.bind(null, "name")}
                            error={this.shopLoginStore.isRegisterNameInValid} />
                        {
                            this.shopLoginStore.isRegisterNameInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.registerNameErrorMsg}
                            </span>
                        }
                    </Style.FormField>
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='text'
                            size='big'
                            icon='envelope'
                            iconPosition='left'
                            autoComplete="username"
                            ref={this.shopLoginStore.ref("rusername")}
                            placeholder={ value('ph_email')} 
                            onFocus={this.shopLoginStore.resetRegisterStatus.bind(null, "rusername")} 
                            error={this.shopLoginStore.isRegisterEmailInValid} />
                        {
                            this.shopLoginStore.isRegisterEmailInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.registerEmailErrorMsg}
                            </span>
                        }
                    </Style.FormField>  
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='password'
                            size='big'
                            icon='lock'
                            iconPosition='left'
                            autoComplete="new-password"
                            ref={this.shopLoginStore.ref("rpassword")}
                            placeholder={value('ph_password_register')} 
                            onFocus={this.shopLoginStore.resetRegisterStatus.bind(null, "rpassword")} 
                            error={this.shopLoginStore.isRegisterPasswordInValid} />
                        {
                            this.shopLoginStore.isRegisterPasswordInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.registerPasswordErrorMsg}
                            </span>
                        }
                    </Style.FormField>
                    <Style.FormField>
                        <Style.InputType
                            fluid
                            type='text'
                            size='big'
                            label={<Label content="+91" basic/>}
                            autoComplete="new-text"
                            ref={this.shopLoginStore.ref("mobilenumber")}
                            placeholder={value('ph_mobilenumber')} 
                            onFocus={this.shopLoginStore.resetRegisterStatus.bind(null, "mobilenumber")} 
                            error={this.shopLoginStore.isRegisterMobilenumberInValid} />
                        {
                            this.shopLoginStore.isRegisterMobilenumberInValid
                            &&
                            <span className="fieldErrorMsg">
                                {this.shopLoginStore.registerMobilenumberErrorMsg}
                            </span>
                        }
                    </Style.FormField>
                    <Style.SignUpButton primary onClick={this.shopLoginStore.executeRegister.bind(null, this.props.SellerID, this.props.ShopID)} loading={this.shopLoginStore.isLoading}>
                        Sign up
                    </Style.SignUpButton>
                </Form>
                <Divider />
                <Style.AlreadyHaveAccountText>
                    Already have an account
                </Style.AlreadyHaveAccountText>
                <Style.LoginButton secondary onClick={this.shopLoginStore.gotoLoginView}>
                  Login
                </Style.LoginButton>
            </Style.FormSegment>
        );
    }

    render(){
        const currentStep = this.shopLoginStore.userRegistrationActiveStep;
        switch (currentStep) {
            case 1:
                return this.renderUserRegisterationView();
            case 2:
                return this.renderWelcomeView();
        }
    }
}