import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _take from 'lodash/take';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import { action, computed } from "mobx";
import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import { Container, Header, Image, Segment } from "semantic-ui-react";
import { injectStyle } from "../utils/DecoratorUtil";
import { Media } from "../utils/Responsive";
import ProductsInCollectionView from "./Products-In-CollectionView";
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';

@inject("storeFactory")
@injectStyle("Collection", "ProfileView")
@observer
export default class ShopCollectionProfileViewViaSection extends Component{

    STORE_SUBJECT_TYPE = "WEBSECTIONS";

    sliderRef = null;
    carousalSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: null,
        prevArrow: null,
        afterChange: this.onSliderChangeHandler.bind(this)
    };

    constructor(props){
        super(props);
        this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");

        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;

		const shopParams = props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
		const collectionCategoryParam = shopParams.itemCategory;
		const sectionID = collectionCategoryParam.split("-SEP-")[1];

        const X_SUBJECT_TYPE = {
            "uid":sectionID,
            "Type":"COLLECTIONS",
            "Category":"",
            "PREDICATE_TYPE":"CollectionsInSection",
            "FIND_WITHIN":true,
            "PAGE_SIZE":-1,
            "FIND_WITH_FILTERS":true,
            "REQUESTED_PAGE":1,
            "APP_OWNER": sellerID,
            "ShopID": shopID
        };
		this.shopSectionStore = props.storeFactory.fetchStore(this.STORE_SUBJECT_TYPE, X_SUBJECT_TYPE);
    }

    @computed
    get ActiveSlide(){
        return this.shopSectionStore.activeSlideIndex;
    }

    set ActiveSlide(currentIndex){
        this.shopSectionStore.activeSlideIndex = currentIndex;
    }

    @action
    onSliderChangeHandler(current){
        const collectionsForSlider = this.shopSectionStore.ItemList;
        this.ActiveSlide = current;
        const activeCollection = collectionsForSlider[this.ActiveSlide];
        const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
        shopParams.itemID = activeCollection.DATA.uid;
    }

    nextCollection = () => {
        this.sliderRef.slickNext();
    }

    previousCollection = () => {
        this.sliderRef.slickPrev();
    }

    buildImageURLsForCollectionSliderView(item, requiredProductCount = 5){
        let requiredProducts = [];
		const productsInCollection = item.DATA.ProductsInCollection ? item.DATA.ProductsInCollection : [];
        if(requiredProductCount < productsInCollection.length){
            requiredProducts = _take(productsInCollection, requiredProductCount);
        } else {
            requiredProducts = productsInCollection;
        }
		const imageURLs = _compact(_map(requiredProducts, (productId) => {
			const imageId = item.REFERENCES[productId].ProfileImage;
			if(imageId && imageId !== ""){
                let imageCDNID = item.REFERENCE_IMAGES[imageId].SourceID;
                return "https://ucarecdn.com/" + imageCDNID + "/-/format/auto/-/quality/lightest/";
			}
		}));
		return imageURLs;
    }

    renderCollectionSliderView(){
        const Style = this.props.componentStyle;
        const collectionsForSlider = this.shopSectionStore.ItemList;
        return(
            <Style.Slider.SliderComponent ref={slider => (this.sliderRef = slider)} {...this.carousalSettings} className="collectionSlider">
            {
                _map(collectionsForSlider, (collection) => {
                    const imageURLs = this.buildImageURLsForCollectionSliderView(collection);
                    return(
                        <div key={collection.DATA.uid} >
                            <Style.CollectionTileSegment basic>
                                <Style.CollectionImages basic>
                                    {
                                        _map(imageURLs, (imageURL) => {
                                            return <Image size='tiny' rounded bordered src={imageURL} key={imageURL}/>
                                        })
                                    }
                                </Style.CollectionImages>
                                <Style.CollectionInfo basic>
                                    <Style.CollectionNameHeader as='h2'>
                                        {collection.DATA.Name}
                                        <Header.Subheader className="ql-editor">
                                            {parse(collection.DATA.Description_RT || collection.DATA.Description || "")}
                                        </Header.Subheader>
                                    </Style.CollectionNameHeader>
                                </Style.CollectionInfo>
                            </Style.CollectionTileSegment>
                        </div>
                    );
                })
            } 
            </Style.Slider.SliderComponent>
        );
    }

    renderTabletCollectionSliderView(){
        const Style = this.props.componentStyle;
        const collectionsForSlider = this.shopSectionStore.ItemList;
        return(
            <Style.Slider.SliderComponent  ref={slider => (this.sliderRef = slider)} {...this.carousalSettings} className="collectionSlider">
            {
                _map(collectionsForSlider, (collection) => {
                    const imageURLs = this.buildImageURLsForCollectionSliderView(collection);
                    return(
                        <div key={collection.DATA.uid} >
                            <Style.CollectionTileSegment basic>
                                <Style.CollectionImages basic>
                                        {
                                            _map(imageURLs, (imageURL) => {
                                                return <Image size='tiny' rounded bordered src={imageURL} key={imageURL}/>
                                            })
                                        }
                                </Style.CollectionImages>
                                <Style.CollectionInfo basic>
                                    <Style.CollectionNameHeader as='h2'>
                                        {collection.DATA.Name}
                                    </Style.CollectionNameHeader>
                                </Style.CollectionInfo>
                            </Style.CollectionTileSegment>
                        </div>
                    );
                })
            } 
            </Style.Slider.SliderComponent>
        );
    }

    renderMobileCollectionSliderView(){
        const Style = this.props.componentStyle;
        const collectionsForSlider = this.shopSectionStore.ItemList;
        return(
            <Style.Slider.SliderComponent  ref={slider => (this.sliderRef = slider)} {...this.carousalSettings} className="collectionSlider">
            {
                _map(collectionsForSlider, (collection) => {
                    const imageURLs = this.buildImageURLsForCollectionSliderView(collection, 2);
                    return(
                        <div key={collection.DATA.uid} >
                            <Style.CollectionTileSegment basic>
                                <Style.CollectionImages basic>
                                        {
                                            _map(imageURLs, (imageURL) => {
                                                return <Image size='tiny' rounded bordered src={imageURL} key={imageURL}/>
                                            })
                                        }
                                </Style.CollectionImages>
                                <Style.CollectionInfo basic>
                                    <Style.CollectionNameHeader as='h2'>
                                        {collection.DATA.Name}
                                    </Style.CollectionNameHeader>
                                </Style.CollectionInfo>
                            </Style.CollectionTileSegment>
                        </div>
                    );
                })
            } 
            </Style.Slider.SliderComponent>
        );
    }

    async componentDidMount(){
        if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
            this.sliderRef.slickGoTo(this.ActiveSlide);
            return;
        }

        console.log("M. ShopCollectionProfileView-Via-Section");
        await this.shopSectionStore.loadItems();
        const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
        const activeCollectionID = shopParams.itemID;
        const activeCollectionIndex = _findIndex(this.shopSectionStore.ItemList, {"DATA":{"uid":activeCollectionID}});
        if(activeCollectionIndex != -1){
            this.sliderRef.slickGoTo(activeCollectionIndex);
            this.ActiveSlide = activeCollectionIndex;
        }
        const activeCollection = _find(this.shopSectionStore.ItemList, {"DATA":{"uid":activeCollectionID}});
        if(activeCollection)
            window.document.title = `${this.shopStore.ShopName} | ${activeCollection.DATA.Name}`;
    }

    render() {
        if(this.shopSectionStore.isLoading){
            return null;
        }

        const Style = this.props.componentStyle;
        const collectionsForSlider = this.shopSectionStore.ItemList;
        const totalCollectionCount = collectionsForSlider.length;
        const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
        const activeCollectionID = shopParams.itemID;

        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;

        return  (
            <Container fluid className="collectionProfileView">
                <Segment basic className="collectionSliderContainer">
                    <Media at="mobile">
                        {this.renderMobileCollectionSliderView()}
                    </Media>
                    <Media at="tablet">
                        {this.renderTabletCollectionSliderView()}
                    </Media>
                    <Media greaterThanOrEqual="laptop">
                        {this.renderCollectionSliderView()}
                    </Media>
                    <Style.Slider.SliderActions basic>
                        <Style.Slider.PrevArrow circular icon='angle left' onClick={this.previousCollection} disabled={this.ActiveSlide === 0} />
                        <Style.Slider.NextArrow circular icon='angle right' onClick={this.nextCollection} disabled={this.ActiveSlide === (totalCollectionCount - 1)} />
                    </Style.Slider.SliderActions>
                </Segment>
                {
                    activeCollectionID
                    &&
                    <Style.CollectionProductView fluid >
                        <ProductsInCollectionView key={activeCollectionID} activeCollectionID={activeCollectionID} sellerID={sellerID} shopID={shopID} />
                    </Style.CollectionProductView>
                }
            </Container>
        );
	}
}