import _map from 'lodash/map';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Icon, List, Sticky } from 'semantic-ui-react';
import styled from 'styled-components';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import BannerSection from '../section/BannerSection';
import { injectStyle } from '../utils/DecoratorUtil';


const StyledSectionSpacer = styled.div`
    width: 100%;
    height: 2.5rem;
    background: transparent;
`;

@inject('pageSectionFactory', 'storeFactory')
@injectStyle("Home", "HomePage")
@observer
export default class HomePage extends Component {

    scrollContextRef = null;
    isHeaderTransparentFlag = false;

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    scrollThroughtBanner = () => {
        if(this.scrollContextRef){
            this.scrollContextRef.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
        }
    }

    renderBannerSection(sectionRef){
        const bannerImageID = sectionRef.DATA.ProfileImage;
        if(bannerImageID){
            const shop = this.shopStore.getCurrentItem();
            const facebookInfo = shop ? shop.DATA.facebookurl : '';
            const instagramInfo = shop ? shop.DATA.instagramurl : '';
            const twitterInfo = shop ? shop.DATA.twitterurl : '';
            const linkedInInfo = shop ? shop.DATA.linkedin : '';
            return(
                <div className="home-page-banner" key={sectionRef.DATA.uid}>
                    <BannerSection section={sectionRef} forHomePage={true}>
                        <a className="scrollDown" onClick={this.scrollThroughtBanner}>Scroll</a>
                        <List relaxed animated verticalAlign='middle' className="bannerSocialBar">
                            {
                                facebookInfo
                                &&
                                (facebookInfo.trim() !== "")
                                &&
                                <List.Item as='a' href={`https://www.facebook.com/${facebookInfo}`} target="_blank">
                                    <Icon name='facebook f' className="bannerSocialIcon"/>
                                </List.Item>
                            }
                            {
                                instagramInfo
                                &&
                                (instagramInfo.trim() !== "")
                                &&
                                <List.Item as='a' href={`https://www.instagram.com/${instagramInfo}`} target="_blank">
                                    <Icon name='instagram' className="bannerSocialIcon"/>
                                </List.Item>
                            }
                            {
                                twitterInfo
                                &&
                                (twitterInfo.trim() !== "")
                                &&
                                <List.Item as='a' href={`https://www.twitter.com/${twitterInfo}`} target="_blank">
                                    <Icon name='twitter' className="bannerSocialIcon"/>
                                </List.Item>
                            }
                            {
                                linkedInInfo
                                &&
                                (linkedInInfo.trim() != "")
                                &&
                                <List.Item as='a' href={`https://www.linkedin.com/${linkedInInfo}`} target="_blank">
                                    <Icon name='linkedin' className="bannerSocialIcon"/>
                                </List.Item>
                            }
                        </List>
                    </BannerSection>
                    <StyledSectionSpacer ref={ scrollContext => this.scrollContextRef = scrollContext}/>
                </div>
            );
        }
    }

    componentDidMount(){
        //Reset the Scroll to top.
        const shopBodyDOM = document.querySelector('.shopBody');
        shopBodyDOM.scrollTop = 0;
        window.document.title = this.shopStore.ShopMetaData.og_title_data;
    }

    render() {
        const Style = this.props.componentStyle;
        const activePage = this.props.pageInfo;
        const sectionsInPage = activePage.SectionsInPage;
        let previousSection = null;
        let flowMode = "FORWARD";

        return (
            <Style.HomeView fluid>
                {
                    _map(sectionsInPage, (sectionId , index) => {
                        const sectionRef = this.shopStore.getSectionByID(sectionId);
                        if(sectionRef){
                            if((index === 0) && sectionRef.DATA.CategorySourceID == 'BannerSection') {
                                return this.renderBannerSection(sectionRef);
                            } else {
                                const sectionComponentRef = this.props.pageSectionFactory.fetchSectionInfo(sectionRef.DATA.CategorySourceID);
                                if(previousSection === sectionComponentRef.section){
                                    flowMode = (flowMode === "BACKWARD") ? "FORWARD" : "BACKWARD";
                                }
                                previousSection = sectionComponentRef.section;
                                const SectionComponent = sectionComponentRef.component;
                                const addSpacerSection = (index !== (sectionsInPage.length - 1))
                                
                                return (<SectionComponent page={activePage} section={sectionRef} key={sectionId} flowMode={flowMode} addSpacerSection={addSpacerSection} />);
                            }
                        }
                    })
                }
            </Style.HomeView>
        );
    }
}