import { inject, observer } from 'mobx-react';
import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import ItemContainer from '../../0-common/container/ItemContainer';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { injectStyle } from '../utils/DecoratorUtil';
import { Media } from '../utils/Responsive';
import AddToCartMessage from './AddToCartMessage';
import ProductCardView from './ProductCardView';
import ShopFilterView from './Shop-FilterView';
import _remove from 'lodash/remove';

const STORE_SUBJECT_TYPE = "SHOPCOLLECTIONS";
@inject('storeFactory')
@injectStyle("Product", "AllProductView")
@observer
export default class ProductsInCollectionView extends ItemContainer {

	constructor(props){
		const X_SUBJECT_TYPE = {
            "uid":props.activeCollectionID,
            "Type":"PRODUCTS",
            "Category":"",
            "PREDICATE_TYPE":"ProductsInCollection",
            "FIND_WITHIN":true,
            "FIND_WITH_FILTERS":true,
			"REQUESTED_PAGE":1,
			"APP_OWNER": props.sellerID,
			"ShopID": props.shopID
        };
		super(props, STORE_SUBJECT_TYPE, X_SUBJECT_TYPE);
		this.openOnDBClick=true;
		this.STORE_TYPE = "PRODUCTS";
		this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
		this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
	}

	get CommonActions(){
		return ['FILTER', 'SORT'];
	}

	get SpecialActions(){
		return [];
	}

	get ItemActions(){
		return [];
	}

	get BulkActions(){
		return [];
    }
    
    get ItemsPerRow(){
		return 4;
	}

	get ShowItemStock(){
		return (this.shopStore.ShopCategory === "StandardWebShop");
	}

	get ExcludeSortOptions(){
		return ["LastUpdatedDate", "CreationDate"];
	}

	get CustomSortOptions(){
		return [
			{
				uid: `Newest`,
				Name: 'Newest',
				value: null,
				mode: "desc"
			}
		];
	}

	openFilterSideBar = (e) => {
		emitter.emit(EVENTS.FILTER.SIDEBAR.OPEN, this.itemStore.filterStore, this.predicateContext);
	}

	closeFilterSideBar = (e) => {
		emitter.emit(EVENTS.FILTER.SIDEBAR.CLOSE);
	}

	isContainerEmpty = () => {
		if(this.itemStore.isLoading){
			return false;
		} else {
			if(this.itemStore.ItemList){
				const itemsCount = this.itemStore.ItemList.length;
				if(itemsCount === 0){
					return true;
				} else {
					return false;
				}
			}
			return true;
		}
	}

	//@override
	getFilterView = () =>{
		const Style = this.props.componentStyle;
		const appliedFilters = this.itemStore.filterStore.appliedFilter;
		const isFilterApplied = (appliedFilters.size > 0);
		return(
			<>
				<Media lessThan="laptop" className="responsiveFilterBar">
					<div className="filterBar">
						<Segment basic textAlign="right">
							<Style.MobileFilterButton primary={isFilterApplied} onClick={this.openFilterSideBar}>
								<Icon name="filter" />
								Filter
							</Style.MobileFilterButton>
						</Segment>
					</div>
				</Media>
				<Media greaterThanOrEqual="laptop" className="responsiveFilterBar">
					<ShopFilterView
						viewType={this.FilterViewType}
						filterStore={this.itemStore.filterStore}
						searchStore={this.itemStore.searchStore}
						containerContextName={this.containerContextName}
						eventContext={this.predicateContext}  />
				</Media>
			</>
		);
	}

	//@override
	renderEmptyView(context){
		return null;
	}

	//@Override
	getItemContainerView(){
		const itemsList = this.itemStore.ItemList;
		const isLoading = this.itemStore.isLoading
		const pageCount = this.itemStore.totalPagesCount;
		return(<ProductCardView itemList={itemsList} itemsPerRow={this.ItemsPerRow} isLoading={isLoading} pageCount={pageCount} eventContext={this.predicateContext} />);
	}

	componentDidUpdate(){
		//Reset the Scroll to top for filter/search/pagination.
		const shopBodyDOM = document.querySelector('.shopBody');
		shopBodyDOM.scrollTop = 0;
	}

	componentWillUnmount(){
		this.unBindEvents();
		this.closeFilterSideBar();
		this.resetSortOption();
	}

	async componentDidMount(){
		if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
            return;
        }
		console.log("M. ProductsInCollectionView");
		await this.fetchListViewData();
		this.resetStatus();
	}

	render() {
		return (
			<>
				{this.renderView()}
				<AddToCartMessage />
			</>
		);
	}	
}