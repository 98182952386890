import _map from 'lodash/map';
import { action, observable } from "mobx";
import { inject, observer } from 'mobx-react';
import React, { Component } from "react";
import { Container, Dimmer, Divider, Form, Header, Icon, Input, Label, Loader, Modal, Segment, TextArea, Transition } from "semantic-ui-react";
import { emitter, EVENTS } from "../../0-common/utils/EventEmitter";
import { infuseRootStyle, injectStyle } from '../utils/DecoratorUtil';


//Required for Modals
let AddAddressModal;
let EditAddressModal;
@inject("storeFactory")
@injectStyle("Account", "AddressView")
@observer
export default class ShopBuyerAddressView extends Component{

    @observable showNewAddressFormView = false;
    @observable showEditAddressFormView = false;

    constructor(props){
        super(props);
        this.addressStore = props.storeFactory.ShopUserStore.AddressStore;
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        AddAddressModal = infuseRootStyle(Modal, "ShopBuyerAddressView - AddAddressModal");
        EditAddressModal = infuseRootStyle(Modal, "ShopBuyerAddressView - EditAddressModal");   
    }

    getPredicateValue(addressInfo, uid){
		const predicateValue = addressInfo.DATA[uid];
		return predicateValue ? predicateValue : "";
    }

    @action
    handleAddAddress = () => {
        this.showNewAddressFormView = true;
        this.addressStore.initializeNewAddress();
    }

    @action
    handleEditAddress = (addressID) => {
        this.showEditAddressFormView = true;
        this.addressStore.setAddressForEdit(addressID);
    }

    @action
    handleCloseAddressFormView = (mode) => {
        if(mode === "NEW"){
            this.showNewAddressFormView = false;
        } else if(mode === "EDIT"){
            this.showEditAddressFormView = false;
        }
        this.addressStore.cancelAddressChanges(mode);
    }

    @action
	handleAddressChange = (predicate, mode, e, data) => {
        if(mode === "NEW"){
            this.addressStore.updateNewAddressProperty(predicate, data ? data.value : e.target.value);
        } else if(mode === "EDIT"){
            this.addressStore.editAddressProperty(predicate, data ? data.value : e.target.value);
        }
	}

    @action
    handleAddNewAddress = async () => {
        this.addressStore.isLoading = true;
        const addressList = this.addressStore.ItemList;
        const isAddressListEmpty = (addressList.length === 0)
        const addressID = await this.addressStore.addNewAddress();
        if(addressID){
            this.showNewAddressFormView = false;
            if(this.props.onAddNewAddress){// Added for Checkout View
                await this.props.onAddNewAddress(addressID);
            }
            this.addressStore.isLoading = false;
        } else {
            this.addressStore.isLoading = false;
        }
        if(isAddressListEmpty){
            const shopOwnerInfo = this.shopStore.OwnerInfo;
            const shopID = this.shopStore.ShopID;
            this.props.storeFactory.ShopUserStore.fetchCurrentSessionBuyer(shopOwnerInfo.SourceID, shopID);
        }
    }

    @action
    handleSaveAddress = async () => {
        const addressID = await this.addressStore.updateAddress();
        if(addressID){
            this.showEditAddressFormView = false;
        }
    }

    @action
    handleRemoveAddress = async (addressID, isDeliveryAddress) => {
        await this.addressStore.removeAddress(addressID);
        if(isDeliveryAddress){
            if(this.props.onRemoveDeliveryAddress){// Added for Checkout View
                this.props.onRemoveDeliveryAddress();
            }
        }
    }

    markAddressAsDefault = (addressID) => {
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        this.props.storeFactory.ShopUserStore.markAddressAsDefault(addressID, sellerID, shopID);
    }

    markAddressForDelivery = (addressID) => {
        emitter.emit(EVENTS.CHECKOUT.SET_DELIVERY_ADDRESS, addressID);
    }

    renderAddressFormView(mode, addressInfo){
        const Style = this.props.componentStyle;
        if(addressInfo.DATA){
            return(
                <Container>
                    <Segment basic>
                        <Style.AddressForm>
                            <Form.Field key="Name" required>
                                <label>Name</label>
                                <Input
                                    fluid
                                    type='text'
                                    placeholder='Name'
                                    value={this.getPredicateValue(addressInfo, "Name")}
                                    onChange={this.handleAddressChange.bind(null, "Name", mode)}
                                    onFocus={this.addressStore.resetAddressValidStatus.bind(null, "name")}
                                    error={this.addressStore.isAddressNameInValid} />
                                {
                                    this.addressStore.isAddressNameInValid
                                    &&
                                    <div className="fieldErrorMsg">
                                        {this.addressStore.addressNameErrorMsg}
                                    </div>
                                }
                            </Form.Field>
                            <Form.Field key="mobile" required>
                                <label>Mobile Number</label>
                                <Input
                                    fluid
                                    label={<Label content="+91" basic/>}
                                    placeholder='10-digit mobile number'
                                    value={this.getPredicateValue(addressInfo, "addressmobilenumber")}
                                    onChange={this.handleAddressChange.bind(null, "addressmobilenumber", mode)}
                                    onFocus={this.addressStore.resetAddressValidStatus.bind(null, "mobileNumber")} 
                                    error={this.addressStore.isAddressMobileNumberInValid} />
                                {
                                    this.addressStore.isAddressMobileNumberInValid
                                    &&
                                    <div className="fieldErrorMsg">
                                        {this.addressStore.addressMobileNumberErrorMsg}
                                    </div>
                                }
                            </Form.Field>
                        </Style.AddressForm>
                    </Segment>
                    <Divider />
                    <Segment basic>
                        <Style.AddressForm>
                            <Form.Field key="streetaddress" required>
                                <label>Street Address</label>
                                <TextArea
                                    placeholder='Enter your street address' 
                                    value={this.getPredicateValue(addressInfo, "streetaddress")}
                                    onChange={this.handleAddressChange.bind(null, "streetaddress", mode)}
                                    onFocus={this.addressStore.resetAddressValidStatus.bind(null, "streetAddress")} />
                                {
                                    this.addressStore.isAddressStreetAddressInValid
                                    &&
                                    <div className="fieldErrorMsg">
                                        {this.addressStore.addressStreetAddressErrorMsg}
                                    </div>
                                }
                            </Form.Field>
                            <Form.Group widths='equal'>
                                <Form.Field key="locatedin">
                                    <label>Lankmark</label>
                                    <input 
                                        placeholder='Enter your landmark' 
                                        value={this.getPredicateValue(addressInfo, "locatedin")}
                                        onChange={this.handleAddressChange.bind(null, "locatedin", mode)}  />
                                </Form.Field>
                                <Form.Field key="pincode" required>
                                    <label>Pincode</label>
                                    <Input
                                        fluid
                                        placeholder='Enter your pincode'
                                        value={this.getPredicateValue(addressInfo, "pincode")}
                                        onChange={this.handleAddressChange.bind(null, "pincode", mode)}
                                        onFocus={this.addressStore.resetAddressValidStatus.bind(null, "pincode")}
                                        error={this.addressStore.isAddressPincodeInValid} />
                                    {
                                        this.addressStore.isAddressPincodeInValid
                                        &&
                                        <div className="fieldErrorMsg">
                                            {this.addressStore.addressPincodeErrorMsg}
                                        </div>
                                    }
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal' >
                                <Form.Field key="city" required>
                                    <label>City</label>
                                    <Input
                                        fluid
                                        type='text'
                                        placeholder='Enter your city'
                                        value={this.getPredicateValue(addressInfo, "city")} 
                                        onChange={this.handleAddressChange.bind(null, "city", mode)}
                                        onFocus={this.addressStore.resetAddressValidStatus.bind(null, "city")}
                                        error={this.addressStore.isAddressCityInValid} />
                                    {
                                        this.addressStore.isAddressCityInValid
                                        &&
                                        <div className="fieldErrorMsg">
                                            {this.addressStore.addressCityErrorMsg}
                                        </div>
                                    }
                                </Form.Field>
                                <Form.Field key="state" required>
                                    <label>State</label>
                                    <Input
                                        fluid
                                        type='text'
                                        placeholder='Enter your state' 
                                        value={this.getPredicateValue(addressInfo, "state")} 
                                        onChange={this.handleAddressChange.bind(null, "state", mode)}
                                        onFocus={this.addressStore.resetAddressValidStatus.bind(null, "state")}
                                        error={this.addressStore.isAddressStateInValid} />
                                    {
                                        this.addressStore.isAddressStateInValid
                                        &&
                                        <div className="fieldErrorMsg">
                                            {this.addressStore.addressStateErrorMsg}
                                        </div>
                                    }
                                </Form.Field>
                            </Form.Group>
                            <Form.Field key="country" required width="8">
                                <label>Country</label>
                                <Input
                                    fluid
                                    type='text'
                                    placeholder='Enter your country' 
                                    value={this.getPredicateValue(addressInfo, "country")} 
                                    onChange={this.handleAddressChange.bind(null, "country", mode)}
                                    onFocus={this.addressStore.resetAddressValidStatus.bind(null, "country")}
                                    error={this.addressStore.isAddressCountryInValid} />
                                {
                                    this.addressStore.isAddressCountryInValid
                                    &&
                                    <div className="fieldErrorMsg">
                                        {this.addressStore.addressCountryErrorMsg}
                                    </div>
                                }
                            </Form.Field>
                        </Style.AddressForm>
                    </Segment>
                    <Divider />
                    <Segment basic>
                        <Style.AddressForm>
                            <Form.Checkbox label='Make this as my default address' />
                        </Style.AddressForm>
                    </Segment>
                </Container>
            );
        }
    }

    componentDidMount(){
        this.addressStore.loadItems();
    }

    render(){
        const Style = this.props.componentStyle;
        const addressList = this.addressStore.ItemList;
        const userObject = this.props.storeFactory.ShopUserStore.User;
        
        let defaultAddressID = "";
        if(userObject.DATA && userObject.DATA.DefaultAddress && userObject.DATA.DefaultAddress.length > 0){
            defaultAddressID = userObject.DATA.DefaultAddress[0];
        }

        let selectedAddressID = "";
        if(this.props.selectedAddress){
            selectedAddressID = this.props.selectedAddress;
        }

        /*let segmentStyle = {};
        if(this.props.context === 'CHECKOUT'){
            if(addressList.length == 0){
                segmentStyle = {
                    margin: "auto"
                }
            } else {
                segmentStyle = {
                    height: "100%",
                    padding: "1rem 2rem",
                    width: "100%"
                }
            }
        } else {
            segmentStyle = {
                paddingLeft: "2rem"
            }
        }*/

        return(
            <Style.AddressView basic={(this.props.context === 'CHECKOUT')} $isEmpty={addressList.length == 0}>
                {
                    (addressList.length == 0)
                    &&
                    <Style.EmptyAddressList>
                        <Style.AddressPlaceholder icon>
                            <Icon name='map marker alternate' circular />
                            <Header.Content>
                                Delivery Address
                            </Header.Content>
                            <Header.Subheader>
                                Add your addresses and enjoy faster checkout
                            </Header.Subheader>
                        </Style.AddressPlaceholder>
                        <Style.AddAddress primary onClick={this.handleAddAddress}>
                            <Icon.Group>
                                <Icon name='map marker'/>
                                <Icon corner name='add'/>
                            </Icon.Group>
                            <span>Add Address</span>
                        </Style.AddAddress>
                        {
                            (this.props.context === 'CHECKOUT')
                            &&
                            <Transition visible={this.props.notifyAddAddress} animation='flash' duration={{ show : 2500, hide: 0}} unmountOnHide>
                                <Style.NotifyAddAddress>
                                    <Label size="large" pointing="above">Please add a delivery address</Label>
                                </Style.NotifyAddAddress>
                            </Transition>
                        }
                    </Style.EmptyAddressList>
                }
                {
                    (addressList.length > 0)
                    &&
                    <Style.AddressList>
                        <Style.AddressHeaderSegment basic>
                            <Style.AddressHeader>
                               {this.props.context === 'CHECKOUT' ? "Delivery Address" : "Add Delivery Address"}
                            </Style.AddressHeader>
                            <Style.AddAddress compact secondary onClick={this.handleAddAddress}>
                                <Icon.Group>
                                    <Icon name='map marker'/>
                                    <Icon corner name='add'/>
                                </Icon.Group>
                                <span>Add Address</span>
                            </Style.AddAddress>
                        </Style.AddressHeaderSegment>
                        <Divider />
                        <Style.AddressBody>
                        {
                            (this.props.context === 'CHECKOUT')
                            &&
                            <Transition visible={this.props.notifyAddAddress} animation='flash' duration={{ show : 2500, hide: 0}} unmountOnHide>
                                <Style.NotifyDeliveryAddress>
                                    <Label size="large" pointing="below">Please select a delivery address</Label>
                                </Style.NotifyDeliveryAddress>
                            </Transition>
                        }
                        {
                            _map(addressList, (addressInfo) => {
                                const name = (addressInfo.DATA && addressInfo.DATA.Name) ? addressInfo.DATA.Name : "";
                                const streetaddress = (addressInfo.DATA && addressInfo.DATA.streetaddress) ? `${addressInfo.DATA.streetaddress}` : "";
                                const locatedin = (addressInfo.DATA && addressInfo.DATA.locatedin) ? `, ${addressInfo.DATA.locatedin}` : "";
                                const city = (addressInfo.DATA && addressInfo.DATA.city) ? `, ${addressInfo.DATA.city}` : "";
                                const state = (addressInfo.DATA && addressInfo.DATA.state) ? addressInfo.DATA.state : "";
                                const country = (addressInfo.DATA && addressInfo.DATA.country) ? addressInfo.DATA.country : "";
                                const pincode = (addressInfo.DATA && addressInfo.DATA.pincode) ? addressInfo.DATA.pincode : "";
                                const addressMobileNo = addressInfo.DATA ? addressInfo.DATA.addressmobilenumber : "";

                                const isDefault = (addressInfo.DATA.uid === defaultAddressID);
                                const isSelected = (addressInfo.DATA.uid === selectedAddressID); //ToBe Used for 'CHECKOUT' View
                                return (
                                    <Style.Address 
                                    key={addressInfo.DATA.uid}
                                    raised={ (this.props.context === 'CHECKOUT') ? isSelected : isDefault} >
                                        
                                        <Style.AddressName> {name}
                                            {
                                                isDefault
                                                &&
                                                <Label basic content="Default" size="tiny"/>
                                            }
                                        </Style.AddressName>
                                        <Style.AddressInfo1>
                                            {streetaddress}{locatedin}
                                        </Style.AddressInfo1>
                                        <Style.AddressInfo1>
                                            {state}{city} - {pincode}
                                        </Style.AddressInfo1>
                                        <Style.AddressInfo2>
                                            {country}
                                        </Style.AddressInfo2>
                                        <Style.AddressInfo3>
                                            Mobile : <span>{addressMobileNo}</span>
                                        </Style.AddressInfo3>
                                        <Divider />
                                        <Style.AddressActions>
                                            {
                                                (this.props.context === 'ACCOUNT')
                                                &&
                                                !isDefault
                                                &&
                                                <Style.DefaultAddress primary size="tiny" compact content="Make This Default" onClick={this.markAddressAsDefault.bind(null, addressInfo.DATA.uid)} />
                                            }
                                            {
                                                (this.props.context === 'CHECKOUT')
                                                &&
                                                !isSelected
                                                &&
                                                <Style.DeliverAddress primary size="tiny" compact content="Deliver Here" loading={this.props.isLoading_SetDeliveryAddress} onClick={this.markAddressForDelivery.bind(null, addressInfo.DATA.uid)} />
                                            }
                                            <div style={{display: "inline-block"}}>
                                                <Style.EditAddress secondary size="tiny" compact content="Edit" onClick={this.handleEditAddress.bind(null, addressInfo.DATA.uid)}/>
                                                <Style.RemoveAddress secondary size="tiny" compact content="Remove" onClick={this.handleRemoveAddress.bind(null, addressInfo.DATA.uid, isSelected)}/>
                                            </div>
                                        </Style.AddressActions>
                                        {
                                            (this.props.context === 'ACCOUNT')
                                            &&
                                            <Style.AddressIcon 
                                                name={isDefault ? "map marker alternate" : "map marker"} 
                                                color={isDefault ? "red" : "black"} />
                                        }
                                        {
                                            (this.props.context === 'CHECKOUT')
                                            &&
                                            <Style.AddressIcon 
                                                name={isSelected ? "dot circle" : "circle outline"} 
                                                color={isSelected ? "red" : "black"} />
                                        }
                                    </Style.Address>
                                );
                            })
                        }
                        </Style.AddressBody>
                    </Style.AddressList>

                }
                <AddAddressModal
                    key="AddressDialog"
                    size='tiny'
                    open={this.showNewAddressFormView}
                    closeOnDimmerClick={false}
                    centered={true}
                    $storeFactory={this.props.storeFactory} >

                        <Style.ModalHeader content='Add New Address' />
                        <Modal.Content scrolling>
                            {this.renderAddressFormView("NEW", this.addressStore.newAddress)}
                        </Modal.Content>
                        <Modal.Actions>
                            <Style.SaveAddress primary onClick={this.handleAddNewAddress}>
                                <Icon name='checkmark' /> Save
                            </Style.SaveAddress>
                            <Style.CancelAddress secondary onClick={this.handleCloseAddressFormView.bind(null, "NEW")}>
                                <Icon name='close' /> Cancel
                            </Style.CancelAddress>
                        </Modal.Actions>
                </AddAddressModal>
                
                <EditAddressModal
                    key="EditAddressDialog"
                    size='tiny'
                    open={this.showEditAddressFormView}
                    closeOnDimmerClick={false}
                    centered={true}
                    $storeFactory={this.props.storeFactory} >

                        <Style.ModalHeader content='Edit Address' />
                        <Modal.Content scrolling>
                            {this.renderAddressFormView("EDIT", this.addressStore.AddressForEdit)}
                        </Modal.Content>
                        <Modal.Actions>
                            <Style.SaveAddress primary onClick={this.handleSaveAddress}>
                                <Icon name='checkmark' /> Save
                            </Style.SaveAddress>
                            <Style.CancelAddress secondary onClick={this.handleCloseAddressFormView.bind(null, "EDIT")}>
                                <Icon name='close' /> Cancel
                            </Style.CancelAddress>
                        </Modal.Actions>
                </EditAddressModal>
                <Dimmer active={this.addressStore.isLoading} inverted>
					<Loader inverted>Loading ... </Loader>
				</Dimmer>
            </Style.AddressView>
        );
    }
}