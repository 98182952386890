import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Container, Label } from "semantic-ui-react";
import PageNotFoundScreen from '../../0-common/screen/PageNotFoundScreen';
import ServerUnderMaintenanceScreen from '../../0-common/screen/ServerUnderMaintenanceScreen';
import PaymentStatusScreen from '../screen/PaymentStatusScreen';
import ShopScreen from '../screen/ShopScreen';

@inject("storeFactory")
@observer
export default class ShopAppContainer extends Component {

	constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
		this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
		this.orderStore.isLoading = true;
    }

	renderSiteLabel(){
		const siteMode = process.env.siteMode;
		if(siteMode === "DEMO"){
			return(
				<Label color="blue" className="siteLabel">
					Demo System
				</Label>
			);
		} else {
			return null;
		}
	}

	renderShopScreen(){
		return (<Container fluid className="AppShop">
					{this.renderSiteLabel()}
					<ShopScreen />
				</Container>);
	}

	renderPaymentScreen(){
		return (<Container fluid className="AppPayment">
					{this.renderSiteLabel()}
					<PaymentStatusScreen />
				</Container>);
	}

	renderPageNotFoundScreen(){
		return (<Container fluid className="NoApp">
					{this.renderSiteLabel()}
					<PageNotFoundScreen />
				</Container>);
	}

	renderServerUnderMaintenanceScreen(){
		return (<Container fluid className="NoApp">
					{this.renderSiteLabel()}
					<ServerUnderMaintenanceScreen />
				</Container>);
	}

	configureAnalyticsStore(){
		const shop = this.shopStore.getCurrentItem();
		if(shop){
			const analyticsID = shop.DATA.GoogleAnalyticsID;
			if(analyticsID){
				const ownerSubscriptionPlan = this.shopStore.OwnerSubscriptionPlan;
				if(this.shopStore.SubscriptionStore.IsFeatureAuthorizedFor(ownerSubscriptionPlan, "storeAnalytics")){
					this.props.storeFactory.initializeAnalyticsStore(ownerSubscriptionPlan);
					console.log("M. Shop App Container >> Google Analytics Store Initialized.");
				}else{
					console.log("M. Shop App Container >> Google Analytics, Not Authorized for plan : "+ownerSubscriptionPlan);
				}
			}else{
				console.log("M. Shop App Container >> Google Analytics, Not Configured.");
			}
		}else{
			console.warn("M. Shop App Container >> Google Analytics Store Initialization Failed ! Shop Not Available.");
		}
	}

    async componentDidMount() {
		const navigationStore = this.props.storeFactory.NavigationStore;
        const shopParams = navigationStore.getContextualParam("SHOP"); 
        const shopID = shopParams ? shopParams.shopID : null; //for preview mode
        const shopLink = navigationStore.ShopLink; //for live mode
        console.log("M. Shop App Container > shopLink : "+shopLink);
        console.log("M. Shop App Container > shopID : "+shopID);

        if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
            console.log(" $$$$$$$$$$$$$$$$$$$$$$$$$ __INITIAL_STATE_SSR_DONE__ FOUND ");
			const shopOwnerInfo = this.shopStore.OwnerInfo;
            if(shopOwnerInfo){
				const sellerID = shopOwnerInfo.SourceID;
				await this.props.storeFactory.ShopUserStore.loadCurrentBuyer(sellerID, shopID);
				console.log("M. Shop App Container >> loadCurrentBuyer");
			}
            delete window.__INITIAL_STATE_SSR_DONE__;
            return;
        }

		try{
			if(shopID || shopLink){
				await this.shopStore.executeFetchShopWithPublicAccess(shopID, shopLink);
				this.configureAnalyticsStore();
				const shopOwnerInfo = this.shopStore.OwnerInfo;
				if(shopOwnerInfo){
					
					switch (navigationStore.currentScreen.screenName) {
	
						case navigationStore.SCREEN.SHOP:
							const sellerID = shopOwnerInfo.SourceID;
							const loadBuyerResponse = await this.props.storeFactory.ShopUserStore.loadCurrentBuyer(sellerID, shopID);
							if(loadBuyerResponse){
								const pageID = shopParams ? shopParams.pageID : null;
								if(pageID === navigationStore.SUBVIEW.BUYER.ORDER){
									this.orderStore.loadOrdersForBuyer(sellerID, shopID);
									navigationStore.changeView(navigationStore.VIEW.SHOP, navigationStore.SUBVIEW.BUYER.ORDER);
								}
							}
						break;
					
						case navigationStore.SCREEN.PAYMENT:
							const orderInfo = navigationStore.getContextualParam("Order");
							if(orderInfo){
								const paymentOrderId = orderInfo.paymentOrderId;
								const paymentId = orderInfo.paymentId;
								const shopOwnerInfo = this.shopStore.OwnerInfo;
								const shopID = this.shopStore.ShopID;
								this.orderStore.executeCheckPaymentStatus(paymentOrderId, paymentId, shopOwnerInfo, shopID);
							} else {
								this.orderStore.isLoading = false;
							}
						break;
					}
				} else {
					navigationStore.changeView(navigationStore.VIEW.SHOP);
					shopParams.pageID = this.shopStore.DefaultShopPageID;
				}
			} else {
				console.error("Shop App Container >> NOT FOUND >> shopLink | shopID");
				console.log("Shop App Container > shopLink : "+shopLink);
				console.log("Shop App Container > shopID : "+shopID);
			}
		}catch(error){
            const isPageNotFound = ( (navigationStore.currentScreenName === navigationStore.SCREEN.PAGE_NOT_FOUND) || this.props.storeFactory.ErrorStore.notFound);
			const isNetworkError = this.props.storeFactory.ErrorStore.isNetworkError;
			const isAccessDenied = this.props.storeFactory.ErrorStore.accessDenied;
			const isInvalidSession = this.props.storeFactory.ErrorStore.isInvalidSession
            if(!isPageNotFound && !isNetworkError && !isAccessDenied && !isInvalidSession){
                console.error("RUNTIME ERROR FOUND, REDIRECTING TO SERVER UNDER MAINTENANCE SCREEN");
                this.props.storeFactory.ErrorStore.serverUnderMaintenance = true;//TODO - Need to show Oop's Error Page
            }
		}
	}

	render() {
		const navigationStore = this.props.storeFactory.NavigationStore;
		const screen = navigationStore.currentScreen.screenName;
		console.log("R. Shop AppContainer rendering.... : "+ screen);
		switch(screen){
			case navigationStore.SCREEN.SHOP : return this.renderShopScreen();
			case navigationStore.SCREEN.PAYMENT : return this.renderPaymentScreen();
			case navigationStore.SCREEN.PAGE_NOT_FOUND : return this.renderPageNotFoundScreen();
			case navigationStore.SCREEN.SERVER_UNDER_MAINTENANCE : return this.renderServerUnderMaintenanceScreen();
			default : return this.renderShopScreen();
		}
	}
}