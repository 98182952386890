import classNames from 'classnames';
import parse from 'html-react-parser';
import _chunk from 'lodash/chunk';
import _dropWhile from 'lodash/dropWhile';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _join from 'lodash/join';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _times from 'lodash/times';
import _compact from 'lodash/compact';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from "react";
import 'react-quill/dist/quill.snow.css';
import Slider from "react-slick";
import {
	Divider, Grid, Header,
	Image, Label, Placeholder, Segment, Transition
} from 'semantic-ui-react';
import { emitter, EVENTS } from "../../0-common/utils/EventEmitter";
import { injectStyle } from "../utils/DecoratorUtil";
import { Media } from "../utils/Responsive";
import AddToCartMessage from "./AddToCartMessage";


@inject("storeFactory")
@injectStyle("Product", "ProfileView")
@observer
export default class ShopProductProfileView extends Component{

	@observable selectedSize = {};
	@observable notifySizeSelection = false;
	@observable showMoreDetails = false;
	NO_SIZE_PREDICATE = false;
	imageCarousalSettingsForMobileView = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: null,
        prevArrow: null,
        adaptiveHeight: true,
    };
	
	constructor(props){
		super(props);
		this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
		this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
		this.productStore 	= props.storeFactory.fetchStore("PRODUCTS");
	}

	get Currency(){
        return this.props.storeFactory.CurrencyStore.Currency;
    }

	getCurrentProductTypeInfo = () => {
		const itemTypeInfo = this.productStore.getCurrentItemTypeInfo();
		if(itemTypeInfo)
			return itemTypeInfo.REGIONS;
		else 
			return [];
	}

	getCurrentProduct = () => {
		return this.productStore.getCurrentItem();
	}

	getImagesFromGallery(currentItem){
		if(currentItem && currentItem.DATA){
			const imagesForCollageView = currentItem.DATA.ImageGallery.map( imageRef => {
				const productImageInfo = toJS(currentItem.REFERENCE_IMAGES[imageRef]);
				if(currentItem.DATA.ProfileImage === imageRef)
					productImageInfo.isDefault = true;
				else 
					productImageInfo.isDefault = false;
				return productImageInfo;				
			});

			return _orderBy(imagesForCollageView, ['isDefault'], ['desc']);
		} else 
			return null;	
	}

	getDesignInfoFor(predicateId,currentDesign){
		return _find(currentDesign.Highlight, ["uid", predicateId]);
	}

	@action
	handleBuy = function(productId, productName, productRetailPrice, productDiscountedPrice, productBrand, productCategoryList, e){
		try{
			let productOption = this.selectedSize.source;
			let productOptionText = this.selectedSize.text;

			if(this.NO_SIZE_PREDICATE){
				productOption = 'ONE_SIZE';
				productOptionText = "";
			}
			if(_isNil(productOption) || productOption === ""){
				this.notifySizeSelection = true;
			} else {
				this.notifySizeSelection = false;
				const productInfo = {
					productId,
					productOption,
					productOptionText,
					productName,
					productRetailPrice,
					productDiscountedPrice,
					productBrand,
					productCategoryList,
					context: "Product Page",
				}
				emitter.emit(EVENTS.CART.ADD, productInfo);
				e.stopPropagation();
			}
		}
		catch (error){
			emitter.emit(EVENTS.SHOP.ERROR, `On Click 'Add To Cart' : ${error.message}`, "Shop Product Profile View");
		}
	}

	handleGoToShoppingCart = () => {
		emitter.emit(EVENTS.CART.OPEN);
    }

	@action
	handleSizeSelection = (productSize) => {
		if(this.selectedSize.source !== productSize.source){
			this.selectedSize = productSize;
			this.notifySizeSelection = false;
		}
	}

	@action
	handleShowMoreToggle = () => {
		this.showMoreDetails = !this.showMoreDetails;
	}

	generatePriceView(currentItem){
		const Style = this.props.componentStyle;
		const discountedPrice = currentItem.DATA.DiscountedPrice;
		const retailPrice = currentItem.DATA.RetailPrice;

		if(discountedPrice){
			const discountPercentage = Math.round(((retailPrice - discountedPrice) / retailPrice) * 100);
			return(
				<Style.PriceInfo>
					{
						discountedPrice
						&&
						<span className="discountedPrice">{this.Currency.format(discountedPrice)}</span>
					}
					{
						retailPrice
						&&
						<span className="retailPrice">{this.Currency.format(retailPrice)}</span>
					}
					{
						(discountPercentage > 0)
						&&
						<span className="discountPercentage"> ( {discountPercentage}% OFF )</span>
					}
				</Style.PriceInfo>
			);
		} else {
			return null;
		}
		
	}

	generateSizeView(product){
		const Style = this.props.componentStyle;
		const webShopCategory = this.shopStore.ShopCategory;
		const sizePredicate = product.DATA.CATEGORY_MASTERS_Size;
		const predicateValues = _map(product.DATA[sizePredicate], (predicateValue) => {
			const predicateValueEntry = _find(product.OPTIONS[sizePredicate], ["key", predicateValue]);
			if(predicateValueEntry)
				return predicateValueEntry;
			else 
				return null;
		});
		const discountedPrice = product.DATA.DiscountedPrice;

		if(predicateValues && predicateValues.length > 0 && discountedPrice){
			this.NO_SIZE_PREDICATE = false;
			const inStock = product.DATA.InStock;
			return(
				<Style.SizeInfo className="sizeBlock">
					<Style.SizeHeader className="sizeHeader">
						<Style.SizeHeaderText>Size</Style.SizeHeaderText>
						<Transition visible={this.notifySizeSelection} animation='shake' duration={{ show : 2500, hide: 0}} unmountOnHide>
							<Style.SelectSizeWarning size="large" pointing="left" className="selectSizeWarning">Please select a size</Style.SelectSizeWarning>
						</Transition>
					</Style.SizeHeader>
					<Label.Group>
						{
							_map(predicateValues, (size) => {
								const isSelected = (this.selectedSize.source === size.source);
								const sizeClass = classNames({
									sizeLabel : true,
									selected : isSelected,
									disabled : !inStock
								});
								let fontSizeVal = "1rem"; //example S, M, L
								if(size.text.length > 2){ // example XXL
									fontSizeVal = "0.8rem";
								} else if(size.text.length === 2){ //example XL
									fontSizeVal = "0.9rem";
								}
								return (
									<Style.SizeLabel
									key={size.key}
									basic
									size="massive"
									circular  
									$fontSize={fontSizeVal}
									className={sizeClass} 
									onClick={ (webShopCategory === "StandardWebShop" && inStock) ? this.handleSizeSelection.bind(null, size) : null}>
										<span>{size.text}</span>
									</Style.SizeLabel>
								);
							})
						} 
					</Label.Group>
				</Style.SizeInfo>
			);
		} else {
			this.NO_SIZE_PREDICATE = true;
			return null;
		}
	}

	generateBuyButtonView(product, categoryNameList){
		const Style = this.props.componentStyle;
		const productOptionAddedInCart = this.orderStore.getProductOptionAddedInCartFor(product.DATA.uid);
		const isOptionSelected = productOptionAddedInCart[this.selectedSize.source];
		const webShopCategory = this.shopStore.ShopCategory;
		const discountedPrice = product.DATA.DiscountedPrice;
		const retailPrice = product.DATA.RetailPrice || product.DATA.DiscountedPrice;

		if(webShopCategory === 'StandardWebShop' && discountedPrice){
			const inStock = product.DATA.InStock;
			return(
				<Style.BuyButtonSegment basic>
					{
						isOptionSelected
						&&
						inStock
						&&
						<Style.GoToCartButton
							className="goToCart"
							primary
							content="Go to cart"
							onClick={this.handleGoToShoppingCart} />
					}
					{
						!isOptionSelected
						&&
						inStock
						&&
						<Style.AddToCartButton
							className="addToCart"
							loading={this.orderStore.isAddInProgress}
							primary
							content="Add to cart"
							onClick={this.handleBuy.bind(this, product.DATA.uid, product.DATA.Name, retailPrice, discountedPrice, product.DATA.Brand, categoryNameList)} />
					}
					{
						!inStock
						&&
						<Style.OutOfStockButton
							disabled
							className="outOfStock"
							secondary
							loading={this.orderStore.isAddInProgress}
							content="Out Of Stock" />
					}
				</Style.BuyButtonSegment>
			);
		} else { // webShopCategory === "StandardWebSite"
			return null;
		}
	}

	generateHeaderView(currentItem, categoryName, categoryNameList){
		const Style = this.props.componentStyle;
		const priceView 	= this.generatePriceView(currentItem);
		const sizeView 		= this.generateSizeView(currentItem);
		const buyButton 	= this.generateBuyButtonView(currentItem, categoryNameList);
		const productName 	= currentItem.DATA.Name;
		return (
			<Fragment>
				<Style.ProductNameHeader>
					{productName}
					<Header.Subheader>
						{categoryName}
					</Header.Subheader>
				</Style.ProductNameHeader>
				{priceView}
				{sizeView}
				{buyButton}
				<Divider/>
			</Fragment>
		);
	}

	generateDescriptionView(currentItem, currentDesign){
		const Style = this.props.componentStyle;
		const descriptionSpecification = _find(currentDesign.Specification, ['uid', 'Details']);
		const descriptionSpecificationPredicates = _filter(descriptionSpecification.Predicates, (predicate) => {
			if( _isNil(currentItem.DATA[predicate.uid]) || _isEmpty(currentItem.DATA[predicate.uid])){
				return false;
			} else 
				return true;
		})
		if(!_isEmpty(descriptionSpecificationPredicates)){
			let descriptionSpecificationPredicateList = _map(descriptionSpecificationPredicates, (predicate, index) => {
				return this.generateDescriptionPredicateInfo(currentItem, predicate, index, descriptionSpecificationPredicates.length);
			});
			if(descriptionSpecificationPredicateList.length > 2){
				descriptionSpecificationPredicateList.push(<Style.ShowMoreButton content={ this.showMoreDetails ? 'Show Less' : 'Show More' } onClick={this.handleShowMoreToggle} />);
			}
			return(
				<div className="description-view">
					<Style.DescriptionHeaderText>Details</Style.DescriptionHeaderText>
					<div className="description-details">
						{descriptionSpecificationPredicateList}
					</div>
					<Divider />
				</div>
			);
		}else 
			return null;	
	}

	generateDescriptionPredicateInfo(currentItem, predicate, index, totalLength){
		const Style = this.props.componentStyle;
		const richTextPredicate = `${predicate.uid}_RT`; 
		const richTextPredicateValue = parse(currentItem.DATA[richTextPredicate] || currentItem.DATA[predicate.uid] || "");
		const isExtra = index > 1;
		//If showMoreDetails is not set then last visible predicate will be the one just above "Show More" button i.e 2nd predicate from the list with index == 1;
		//Else last visible predicate will be the last one from the list.
		//Last Visible predicate can be used to add special css like in bullseyeBotanical, all the detail predicate have divider but the last one don't.
		const isLastVisible = this.showMoreDetails ? (index == totalLength - 1) : (index == 1);
		return (
			<Style.DescriptionProductInfoSegment basic key={predicate.uid} $isExtra={isExtra} $isLastVisible={isLastVisible} $isShowMore={this.showMoreDetails}>
				<Style.PredicateName>{predicate.Name}</Style.PredicateName>
				<Style.PredicateValue className="ql-editor">{richTextPredicateValue}</Style.PredicateValue>
			</Style.DescriptionProductInfoSegment>
		);
	}

	/*generateColorView(product){
		const predicateValues = _map(product.DATA.color, (predicateValue) => {
			const predicateValueEntry = _find(product.OPTIONS.color, ["key", predicateValue]);
			if(predicateValueEntry)
				return predicateValueEntry.text;
			else 
				return null;
		});
		if(predicateValues && predicateValues.length > 0)
			return(
				<Fragment>
					<Header as='h5'>Color</Header>
					<Label.Group>
						{
							_map(predicateValues, (color) => {

								return (
									<Label basic key={color} color={color.toLowerCase()}>
						        		{color}
						        	</Label>
						        );
							})
						} 
					</Label.Group>
				</Fragment>
			);
		else 
			return null;	
	}*/

	generatePredicateInfo(currentItem, predicate, withDivider = true){
		const Style = this.props.componentStyle;
		let predicateValue = "";
		if(predicate.ViewType === "DropdownPredicate"){
			const predicateValues = _map(currentItem.DATA[predicate.uid], (predicateValue) => {
				const predicateValueEntry = _find(currentItem.OPTIONS[predicate.uid], ["key", predicateValue]);
				if(predicateValueEntry)
					return predicateValueEntry.text;
				else 
					return null;
			});
			predicateValue = _join(predicateValues, ", ");
		}else if(predicate.ViewSubType === "Flag"){
			predicateValue = currentItem.DATA[predicate.uid] === true ? 'Yes' : 'No';
		}else {
			predicateValue = currentItem.DATA[predicate.uid];
		}
		
		return (
			<Style.ProductInfoSegment basic key={predicate.uid}>
				<Style.PredicateName>{predicate.Name}</Style.PredicateName>
				<Style.PredicateValue>{predicateValue}</Style.PredicateValue>
				{
					withDivider
					&&
					<Style.PredicateDivider />
				}
			</Style.ProductInfoSegment>
		);	
	}

	generateSpecificationView(currentItem, currentDesign){
		const Style = this.props.componentStyle;
		const sizePredicate = currentItem.DATA.CATEGORY_MASTERS_Size;
		const nonDescriptionSpecifications = _dropWhile(currentDesign.Specification, ['uid', 'Details']);
		const predicates = _filter( _flatten(_map(nonDescriptionSpecifications, "Predicates")), (predicate) => {
			if(predicate.uid === "RetailPrice" || predicate.uid === "DiscountedPrice" || predicate.uid === sizePredicate || predicate.uid === "InStock")
				return false;
			else if( _isNil(currentItem.DATA[predicate.uid]) || currentItem.DATA[predicate.uid] === ""){
				return false;
			} else 
				return true;
		});	
		const predicateRowList = _chunk( predicates, 2);
		if(predicateRowList && predicateRowList.length > 0)
			return (
				<Fragment>
					<Style.SpecificationHeaderText>Specifications</Style.SpecificationHeaderText>
					<Style.SpecificationGrid stackable columns='2'>
						{
							_map(predicateRowList, (predicateRow, index) => {
								return(
									<Grid.Row style={{paddingBottom: "0rem"}} key={index}>
										{
											_map(predicateRow, (predicate) => {
												return (
													<Grid.Column key={predicate.uid}>
														{this.generatePredicateInfo(currentItem, predicate)}
													</Grid.Column>
												);
											})
										}
									</Grid.Row>
								);
							})
						}			
					</Style.SpecificationGrid>
				</Fragment>
			);
		else
			return null;
	}

	generateImageGalleyView(imagesForCollageView){
		const Style = this.props.componentStyle;
		return(
			<Style.ImageGallery basic className="ImageGallery">
				{
					_map( _chunk(imagesForCollageView, 2), (imageChunk, index) => {
						return (
							<Style.ImageRow key={index}>
								{
									_map(imageChunk, (imageObj) => {
										const cdnId = imageObj.SourceID ? imageObj.SourceID : "";
										const imageSrc = "https://ucarecdn.com/"+cdnId+"/-/format/auto/-/quality/smart_retina/";
										return (
											<Style.ImageView basic compact key={cdnId}>
												<Image src={imageSrc} />
											</Style.ImageView>
										);
									})
								}
							</Style.ImageRow>
						);
					})
				}
			</Style.ImageGallery>
		);
	}

	generateMobileImageGalleyView(imagesForCollageView){
		return(
			<Segment basic className="imageGallery">
				<Slider {...this.imageCarousalSettingsForMobileView}>
					{
						_map(imagesForCollageView, (imageObj) => {
							const cdnId = imageObj.SourceID ? imageObj.SourceID : "";
							const imageSrc = "https://ucarecdn.com/"+cdnId+"/-/format/auto/-/quality/smart/";
							return (
								<Image src={imageSrc} key={cdnId}/>
							);
						})
					}
				</Slider>
			</Segment>
		);
	}

	buildProfileView(currentItem, currentDesign){
		const Style = this.props.componentStyle;
		const imagesForCollageView = this.getImagesFromGallery(currentItem);	
		const itemCategory = currentItem.DATA.Category;
		const categoryDic = this.props.storeFactory.ModuleStore.getModuleCategories("PRODUCTS");
		const category = _find(categoryDic, ['key', itemCategory]);
		const categoryName = category ? category.text : "$$$$";
		const shop=this.shopStore.getCurrentItem();
		const categoryNameList = _compact(_map(currentItem.DATA.Categories, (itemCategoryID) => {
			const category = _find(categoryDic, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));

		return (
			<Style.ProfileView key="body">
				<Style.OpenViewGrid stackable className="openViewGrid" columns="2">
					<Grid.Row>
						<Grid.Column className="imageColumn" computer={10} tablet={16} mobile={16}>
							<Media lessThan="laptop">
								{this.generateMobileImageGalleyView(imagesForCollageView)}	
							</Media>
							<Media greaterThanOrEqual="laptop">
								{this.generateImageGalleyView(imagesForCollageView)}	
							</Media>
						</Grid.Column>
						<Grid.Column className="productInfoColumn" computer={6} tablet={16} mobile={16}>
							<Style.ProductInfo basic>
								{this.generateHeaderView(currentItem, categoryName, categoryNameList)}
								{this.generateDescriptionView(currentItem, currentDesign)}
								{this.generateSpecificationView(currentItem, currentDesign)}
							</Style.ProductInfo>
						</Grid.Column>
					</Grid.Row>
				</Style.OpenViewGrid>
				<AddToCartMessage />
				{
					shop 
					&& 
					shop.DATA.WidgetPackID
					&&
					<>
						<div className='review-header'>Reviews</div>
						<div id="wpac-review" className='review'></div>
					</>
				}
			</Style.ProfileView>
		);
	}

	buildLoadingMobileImageGalleyView(){
		const Style = this.props.componentStyle;
		return(
			<Segment basic className="imageGallery">
				<Placeholder>
					<Placeholder.Image rectangular />
				</Placeholder>
			</Segment>
		);
	}

	buildLoadingImageGalleyView(){
		const Style = this.props.componentStyle;
		return(
			<Style.ImageGallery basic className="ImageGallery">
				{
					_times( 2, (counter) => {
						return (
							<Style.ImageRow key={counter}>
								{
									_times(2, (counter) => {
										return (
											<Placeholder key={counter}>
												<Placeholder.Image square />
											</Placeholder>
										);
									})
								}
							</Style.ImageRow>
						);
					})
				}
			</Style.ImageGallery>
		);
	}

	buildLoadingView(){
		const Style = this.props.componentStyle;
		const webShopCategory = this.shopStore.ShopCategory;
		return (
			<Style.ProfileView key="body" $loading={true}>
				<Style.OpenViewGrid stackable className="openViewGrid" columns="2">
					<Grid.Row>
						<Grid.Column className="imageColumn" computer={10} tablet={16} mobile={16}>
							<Media lessThan="laptop">
								{this.buildLoadingMobileImageGalleyView()}	
							</Media>
							<Media greaterThanOrEqual="laptop">
								{this.buildLoadingImageGalleyView()}	
							</Media>
						</Grid.Column>
						<Grid.Column className="productInfoColumn" computer={6} tablet={16} mobile={16}>
							<Style.ProductInfo basic>
								<Placeholder>
									<Placeholder.Header>
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder.Header>
									<Placeholder.Header>
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder.Header>
									<Placeholder.Paragraph>
										<Placeholder.Line length='full' />
										<Placeholder.Line length='medium' />
									</Placeholder.Paragraph>
								</Placeholder>
								{
									(webShopCategory === 'StandardWebShop')
									&&
									<Style.AddToCartButton primary content="Add to cart" disabled style={{margin: "2rem auto 1rem 0"}} />
								}
								<Divider/>
								<Style.DescriptionHeaderText>Details</Style.DescriptionHeaderText>
								<Placeholder>
									<Placeholder.Paragraph>
										<Placeholder.Line />
										<Placeholder.Line />
										<Placeholder.Line />
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder.Paragraph>
									<Placeholder.Paragraph>
										<Placeholder.Line />
										<Placeholder.Line />
										<Placeholder.Line />
									</Placeholder.Paragraph>
								</Placeholder>
								<Divider/>
								<Style.SpecificationHeaderText>Specifications</Style.SpecificationHeaderText>
								<Style.SpecificationGrid stackable columns='2'>
									{
										_times(5, (counter) => {
											return(
												<Grid.Row style={{paddingBottom: "0rem"}} key={counter}>
													{
														_times(2, (counter) => {
															return (
																<Grid.Column key={counter}>
																	<Style.ProductInfoSegment basic>
																		<Placeholder>
																			<Placeholder.Line length='very short' />
																			<Placeholder.Line length='short' />
																		</Placeholder>
																		<Style.PredicateDivider />
																	</Style.ProductInfoSegment>
																</Grid.Column>
															);
														})
													}
												</Grid.Row>
											);
										})
									}			
								</Style.SpecificationGrid>
							</Style.ProductInfo>
						</Grid.Column>
					</Grid.Row>
				</Style.OpenViewGrid>
			</Style.ProfileView>
		);
	}

	emitProductViewEvent() {
		const product = this.getCurrentProduct();
		const categoryDictionary = this.props.storeFactory.ModuleStore.getModuleCategories("PRODUCTS");

		const categoryNameList = _compact(_map(product.DATA.Categories, (itemCategoryID) => {
			const category = _find(categoryDictionary, ['key', itemCategoryID]);
			return (category ? category.text : null);
		}));
		const productInfo = {
			productId: product.DATA.uid,
			productName: product.DATA.Name,
			productRetailPrice: (product.DATA.RetailPrice || product.DATA.DiscountedPrice),
			productDiscountedPrice: product.DATA.DiscountedPrice,
			productBrand: product.DATA.Brand,
			productCategoryList: categoryNameList,
		};
		emitter.emit(EVENTS.SHOP.PRODUCT.VIEW, productInfo);
	}

	async componentDidUpdate(prevProps){
		console.log("componentDidUpdate. ShopProductProfileView >>");
		console.log("prevProps (productID) >> "+prevProps.productID);
		console.log("currentProps (productID) >> "+this.props.productID);
		if(prevProps.productID !== this.props.productID){
			console.log("Fetching Search Result for >> ");
			const sellerID = this.shopStore.OwnerInfo.SourceID;
			const shopID = this.shopStore.ShopID;
			await this.productStore.executeFetchProductForShop(this.props.productID, this.props.productCategory, sellerID, shopID);
			const productPage = _find(this.shopStore.Pages, ['CategorySourceID', 'ProductPage']);
			const productMetaData = this.productStore.getProductMetaData(this.shopStore.ShopName, this.shopStore.ShopURL, this.shopStore.ShopURLType, productPage.uid);
			window.document.title = productMetaData.og_title_data;

			const shopBodyDOM = document.querySelector('.shopBody');
			shopBodyDOM.scrollTop = 0;
			this.emitProductViewEvent();
		}
	}


	async componentDidMount() {

		console.log("M. ShopProductProfileView");
		if(window.WPac){
			const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP");
			const productID = shopParams.itemID;
			const shop=this.shopStore.getCurrentItem();
			if(shop && shop.DATA.WidgetPackID){
				console.log("product id is" + productID)
				console.log("widget pack id is" + shop.DATA.WidgetPackID)
				WPac.init([
					{widget: 'Review', id: shop.DATA.WidgetPackID, chan: productID},
					{widget: 'ReviewCount', id: shop.DATA.WidgetPackID, chan: productID},
					{widget: 'ReviewRecent', id: shop.DATA.WidgetPackID, chan: productID}
				]);
			}

		}

		if(window.__INITIAL_STATE_SSR_DONE__ && window.__INITIAL_STATE_SSR_DONE__ === true){
			this.emitProductViewEvent();
            return;
        }

		console.log("M. ShopProductProfileView");
		const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP");
		const productCategory = shopParams.itemCategory;
		const productID = shopParams.itemID;
		if(productID){
			const sellerID = this.shopStore.OwnerInfo.SourceID;
			const shopID = this.shopStore.ShopID;
			await this.productStore.executeFetchProductForShop(productID, productCategory, sellerID, shopID);
			const productPage = _find(this.shopStore.Pages, ['CategorySourceID', 'ProductPage']);
			const productMetaData = this.productStore.getProductMetaData(this.shopStore.ShopName, this.shopStore.ShopURL, this.shopStore.ShopURLType, productPage.uid);
			window.document.title = productMetaData.og_title_data;
			this.emitProductViewEvent();
		}
		
		const shopBodyDOM = document.querySelector('.shopBody');
		shopBodyDOM.scrollTop = 0;
	}

	render() { 
		console.log("R. ShopProductProfileView");
		const product = this.getCurrentProduct()
		const productDesign = this.getCurrentProductTypeInfo();
		const isLoading = this.productStore.isLoading;

		if(product && productDesign){
			return this.buildProfileView(product, productDesign);	
		} else if(isLoading){
			return this.buildLoadingView();
		} else {
			return null;
		}
	}
}