
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import CollectionsInAllCollectionsView from "../view/Collections-In-AllCollectionsView";
import ShopCollectionProfileView from "../view/Shop-Collection-ProfileView";
import ShopCollectionProfileViewViaSection from "../view/Shop-Collection-ProfileView_Via_Section";

@inject('storeFactory')
@observer
export default class CollectionPage extends Component {

    //STORE_SUBJECT_TYPE = "WEBSECTIONS";

	constructor(props){
		super(props);
		this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        /*const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;

		const shopParams = props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
		const collectionCategoryParam = shopParams.itemCategory;
		const sectionID = collectionCategoryParam.split("-SEP-")[1];

        const X_SUBJECT_TYPE = {
            "uid":sectionID,
            "Type":"COLLECTIONS",
            "Category":"",
            "PREDICATE_TYPE":"CollectionsInSection",
            "FIND_WITHIN":true,
            "PAGE_SIZE":-1,
            "FIND_WITH_FILTERS":true,
            "REQUESTED_PAGE":1,
            "APP_OWNER": sellerID,
            "ShopID": shopID
        };
		this.itemStore = props.storeFactory.fetchStore(this.STORE_SUBJECT_TYPE, X_SUBJECT_TYPE);*/
    }
    
    componentDidMount(){
        //this.itemStore.loadItems();

        //Reset the Scroll to top.
        const shopBodyDOM = document.querySelector('.shopBody');
        shopBodyDOM.scrollTop = 0;
    }

    render() {
        console.log("R. CollectionPage");
        const navigationStore = this.props.storeFactory.NavigationStore;
		const currentView = navigationStore.currentViewName;
		let collectionSubView = navigationStore.currentSubView;

        if(currentView === navigationStore.VIEW.SHOPLOGIN || collectionSubView === navigationStore.SUBVIEW.NONE){
			const shopParams = navigationStore.getContextualParam("SHOP"); 
			const collectionID = shopParams.itemID;
			if(collectionID){
                const collectionCategoryParam = shopParams.itemCategory;
                if(collectionCategoryParam.includes("-SEP-")){//The url was generated from HomePage -> HomeSection -> View More Button 
                    collectionSubView = navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN_VIA_SECTION;
                } else {
                    collectionSubView = navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN;
                }
			} else {
				collectionSubView = navigationStore.SUBVIEW.SHOP.COLLECTION.LIST;
			}
		}

        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        const isLoading = this.shopStore.isLoading;

        switch (collectionSubView) {
			case navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN:
				return <ShopCollectionProfileView />

            case navigationStore.SUBVIEW.SHOP.COLLECTION.OPEN_VIA_SECTION:
                return <ShopCollectionProfileViewViaSection />
			
			case navigationStore.SUBVIEW.SHOP.COLLECTION.LIST:
				const searchFired = this.shopStore.searchStore.searchApplied;
				return <CollectionsInAllCollectionsView searchFired={searchFired} sellerID={sellerID} shopID={shopID} />

            default :
                return (
                    <Dimmer active={isLoading}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                );
		}
	}
}