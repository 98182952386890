import React, { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Edit3 } from "react-feather";
import { Button, Divider, Form, Header, Input, Popup, Segment } from "semantic-ui-react";
import { value } from "../../0-common/store/LanguageStore";
import { injectStyle } from "../utils/DecoratorUtil";

@inject("storeFactory")
@injectStyle("Account", "ProfileView")
@observer
export default class ShopBuyerProfileView extends Component{

    @observable enableEditForPersonalInfo = false;
    @observable enableEditForEmailInfo = false;
    @observable enableEditForMobileInfo = false;

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    getPredicateValue(userInfo, uid){
		const predicateValue = userInfo.DATA[uid];
		return predicateValue ? predicateValue : "";
	}

    @action
    handleEditForPersonalInfo = () => {
        this.enableEditForPersonalInfo = true;
    }

    @action
    handleEditForEmailInfo = () => {
        this.enableEditForEmailInfo = true;
    }

    @action
    handleEditForMobileInfo = () => {
        this.enableEditForMobileInfo = true;
    }

    @action
    handleCancelForPersonalInfo = () => {
        this.enableEditForPersonalInfo = false;
        this.props.storeFactory.ShopUserStore.cancelUserProfileChanges();
    }

    @action
    handleCancelForEmailInfo = () => {
        this.enableEditForEmailInfo = false;
        this.props.storeFactory.ShopUserStore.cancelUserProfileChanges();
    }

    @action
    handleCancelForMobileInfo = () => {
        this.enableEditForMobileInfo = false;
        this.props.storeFactory.ShopUserStore.cancelUserProfileChanges();
    }

    @action
	handleProfileChange = (predicate, e, data) => {
		this.props.storeFactory.ShopUserStore.updateProperty(predicate, data ? data.value : e.target.value);
	}

	@action
	handlePersonalInfoSave = async () => {
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        await this.props.storeFactory.ShopUserStore.saveUserProfileChanges(sellerID, shopID);
        this.enableEditForPersonalInfo = false;
    }

    @action
	handleEmailInfoSave = async () => {
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        await this.props.storeFactory.ShopUserStore.saveUserProfileChanges(sellerID, shopID);
        this.enableEditForEmailInfo = false;
    }

    @action
	handleMobileInfoSave = async () => {
        const sellerID = this.shopStore.OwnerInfo.SourceID;
        const shopID = this.shopStore.ShopID;
        await this.props.storeFactory.ShopUserStore.saveUserProfileChanges(sellerID, shopID);
        this.enableEditForMobileInfo = false;
    }

    render(){
        const Style = this.props.componentStyle;
        const userInfo = this.props.storeFactory.ShopUserStore.UserForEdit;
        if(!userInfo.DATA){
            return null;
        }

        return(
            <Style.ProfileView>
                <Style.ProfileInfoSection>
                    <Header>
                        <span>Personal Information</span>
                        {
                            !this.enableEditForPersonalInfo
                            &&
                            <Popup
                            key="edit"
                            content="Edit"
                            position='bottom center'
                            size='tiny'
                            trigger={
                                <Edit3 size="16" onClick={this.handleEditForPersonalInfo}/>
                            } />
                        }
                    </Header>
                    <Divider />
                    <Style.ProfileInfoForm>
                        <Form.Group>
                            <Form.Field width="8">
                                <label>Name</label>
                                <Input
                                    spellCheck={false}
                                    disabled={!this.enableEditForPersonalInfo}
                                    value={this.getPredicateValue(userInfo, "Name")} 
                                    onChange={this.handleProfileChange.bind(null, "Name")}/>
                            </Form.Field>
                            {
                                this.enableEditForPersonalInfo
                                &&
                                <Form.Field className="actions">
                            
                                    <Style.SaveButton secondary content="Save" onClick={this.handlePersonalInfoSave} />
                                    <Style.CancelButton secondary content="Cancel" onClick={this.handleCancelForPersonalInfo} />
                                
                                </Form.Field>
                            }
                        </Form.Group>
                        <Form.Field>
                            <label>Gender</label>
                            <Form.Group>
                                <Form.Radio
                                    label='Male'
                                    value='male'
                                    checked={this.getPredicateValue(userInfo, "gender") === 'male'}
                                    onChange={this.handleProfileChange.bind(null, "gender")}
                                    disabled={!this.enableEditForPersonalInfo}
                                />
                                <Form.Radio
                                    label='Female'
                                    value='female'
                                    checked={this.getPredicateValue(userInfo, "gender") === 'female'}
                                    onChange={this.handleProfileChange.bind(null, "gender")}
                                    disabled={!this.enableEditForPersonalInfo}
                                />
                            </Form.Group>
                        </Form.Field>
                    </Style.ProfileInfoForm>
                </Style.ProfileInfoSection>
                <Style.ProfileInfoSection>
                    <Header>
                        <span>Email Address</span>
                    </Header>
                    <Divider />
                    <Style.ProfileInfoForm>
                        <Form.Group>
                            <Form.Field width="8">
                                <label>Email Address</label>
                                <Input
                                    spellCheck={false}
                                    disabled={true}
                                    placeholder={value('ph_userprofile_modal_generalinfo_email')} 
                                    value={this.getPredicateValue(userInfo, "Email")} 
                                    onChange={this.handleProfileChange.bind(null, "Email")} />
                            </Form.Field>
                        </Form.Group>
                    </Style.ProfileInfoForm>
                </Style.ProfileInfoSection>  
                <Style.ProfileInfoSection>
                    <Header>
                        <span>Mobile Number</span>
                        {
                            !this.enableEditForMobileInfo
                            &&
                            <Popup
                            key="edit"
                            content="Edit"
                            position='bottom center'
                            size='tiny'
                            trigger={
                                <Edit3 size="16" onClick={this.handleEditForMobileInfo}/>
                            } />
                        }
                    </Header>
                    <Divider />
                    <Style.ProfileInfoForm>
                        <Form.Group>
                            <Form.Field width="8">
                                <label>Mobile Number</label>
                                <Input 
                                    spellCheck={false}
                                    disabled={!this.enableEditForMobileInfo}
                                    placeholder={value('ph_userprofile_modal_generalinfo_mobile')}
                                    value={this.getPredicateValue(userInfo, "MobileNumber")} 
                                    onChange={this.handleProfileChange.bind(null, "MobileNumber")}/>
                            </Form.Field>
                            {
                                this.enableEditForMobileInfo
                                &&
                                <Form.Field className="actions">
                            
                                    <Style.SaveButton secondary content="Save" onClick={this.handleMobileInfoSave} />
                                    <Style.CancelButton secondary content="Cancel" onClick={this.handleCancelForMobileInfo}/>
                                
                                </Form.Field>
                            }
                        </Form.Group>
                    </Style.ProfileInfoForm>
                </Style.ProfileInfoSection>  
            </Style.ProfileView>
        );
    }
}