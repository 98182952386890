
import classNames from "classnames";
import _find from 'lodash/find';
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Container, Ref } from "semantic-ui-react";
import AccountPage from "../page/AccountPage";
import CollectionPage from '../page/CollectionPage';
import HomePage from '../page/HomePage';
import ProductPage from '../page/ProductPage';
import StandardPage from '../page/StandardPage';
import { Media } from "../utils/Responsive";
import ShopFilterMobileView from "./Shop-FilterMobileView";
import ShopFooterView from "./Shop-FooterView";
import SideBarMenuView from "./SideBar-Menu-View";

@inject('storeFactory')
@observer
export default class ShopActivePageView extends Component{

    constructor(props){
        super(props);
        this.itemStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.shopBodyContextRef = React.createRef();
    }

    /*@action
    onSideBarMenuClick(pageID){
        this.itemStore.ActivePage = pageID;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP);
        emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    @action
    showBuyersProfileView = () => {
        this.itemStore.ActivePage = this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.PROFILE;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.PROFILE);
		//this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.BUYERACCOUNT, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.PROFILE);
        emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    @action
    showBuyersOrderView = () => {
        this.itemStore.ActivePage = this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.ORDER;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.ORDER);
		//this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.BUYERACCOUNT, this.props.storeFactory.NavigationStore.SUBVIEW.BUYER.ORDER);
        emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    @action
    showLoginView = () => {
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOPLOGIN, this.props.storeFactory.NavigationStore.SUBVIEW.SHOP.LOGIN);
        emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    @action
    showSignOutWarningPopup = () => {
        emitter.emit(EVENTS.SHOP.SIGNOUT);
    }*/

    renderActivePage(activePage){
        if(activePage){
            const pageClassList = classNames('activePage', activePage.CategorySourceID);

            switch(activePage.CategorySourceID){
                case "HomePage" : 
                    return (
                        <div className={pageClassList}>
                            <HomePage pageInfo={activePage} shopBodyContextRef={this.shopBodyContextRef} />
                        </div>
                    );
                
                case "ProductPage" : 
                    return (
                        <div className={pageClassList}>
                            <ProductPage pageInfo={activePage}/>
                        </div>
                    );

                case "CollectionPage" :
                    return (
                        <div className={pageClassList}>
                            <CollectionPage pageInfo={activePage}/>
                        </div>
                    );

                case "StandardPage" : 
                    return (
                        <div className={pageClassList}>
                            <StandardPage pageInfo={activePage} />
                        </div>
                    );

                case "AccountPage" : 
                    return (
                        <div className={pageClassList}>
                            <AccountPage pageInfo={activePage} />
                        </div>
                    );
            }
        }
    }

    renderMobileShopBodyView(activePage){
        const isHomePage = (activePage && (activePage.CategorySourceID === 'HomePage') );
        return(
            <SideBarMenuView filterView={ShopFilterMobileView} isMobile={true} isHomePage={isHomePage}>
                <Ref innerRef={this.shopBodyContextRef}>
                    <Container fluid className="shopBody">
                        {this.renderActivePage(activePage)}
                        <ShopFooterView />
                    </Container>
                </Ref>
            </SideBarMenuView>
        );
    }

    renderShopBodyView(activePage){
        const isHomePage = (activePage && (activePage.CategorySourceID === 'HomePage') );
        return (
            <SideBarMenuView isMobile={false} isHomePage={isHomePage}>
                <Ref innerRef={this.shopBodyContextRef}>
                    <Container fluid className="shopBody">
                        {this.renderActivePage(activePage)}
                        <ShopFooterView />
                    </Container>
                </Ref>
            </SideBarMenuView>
        );
    }

    render(){
        const activePageID = this.itemStore.ActivePage; 
        const pageList = this.itemStore.Pages;
        const activePage = _find(pageList, ['uid', activePageID]);
        const activePageCategory = activePage ? activePage.CategorySourceID : "";
        const bodyClassList = classNames('responsiveBody', `${activePageCategory}ResponsiveBody`);
        return(
            <>
                <Media lessThan="laptop" className={bodyClassList}>
                    {this.renderMobileShopBodyView(activePage)}
                </Media>
                <Media greaterThanOrEqual="laptop" className={bodyClassList}>
                    {this.renderShopBodyView(activePage)}
                </Media>
            </>
        );
    }
}