import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { AlertCircle, CheckCircle } from "react-feather";
import { Dimmer, Header, Icon, Loader, Segment } from "semantic-ui-react";
import { injectStyle } from '../utils/DecoratorUtil';

@inject("storeFactory")
@injectStyle("Common", "PaymentStatusView")
@observer
export default class ShopPaymentStatusView extends Component {

    constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
    }
    
    render() {
        const Style = this.props.componentStyle;
        const homeUrl = JSON.parse(localStorage.getItem('orderHomeURL'));
        const orderInfo = this.props.storeFactory.NavigationStore.getContextualParam("Order");
        let orderId = "";
        if(orderInfo){
            orderId = orderInfo.orderId;
        }
        let orderStatus = ""
        let orderStatusColor = "";
        const paymentStatus = this.orderStore.paymentStatusInfo;
        if(paymentStatus === "captured" || paymentStatus === "COMPLETED"){
            orderStatus = "Order Confirmed";
            orderStatusColor = "#008000cc";
        } else {
            orderStatus = "Processing Payment";
            orderStatusColor = "#ffa500";
        }

        return (
            <Style.PaymentView>
                <Style.PaymentSegment raised compact>
                    <Style.PaymentHeader>
                        <Icon name='payment' />
                        <Header.Content>Payment</Header.Content>
                    </Style.PaymentHeader>
                    <Style.PaymentInfo basic>
                        <Style.PaymentInfoHeader>
                            Thank you for shopping with us.
                        </Style.PaymentInfoHeader>
                        <Style.PaymentInfoStatus icon>
                            {
                                orderStatus === "Order Confirmed"
                                &&
                                <CheckCircle size="64" color={orderStatusColor}/>
                            }
                            {
                                orderStatus === "Processing Payment"
                                &&
                                <AlertCircle size="64" color={orderStatusColor} />
                            }
                            <Header.Content style={{color: orderStatusColor}}>
                                {orderStatus}
                            </Header.Content>
                        </Style.PaymentInfoStatus>
                        <Style.PaymentInfoContent dividing>
                            <Header.Content>
                                Order No : {orderId}
                                <Header.Subheader>
                                    <span>Your order is currently being processed.</span>
                                    <span>An order confirmation message will be sent to your registered email with the expected delivery date for your items.</span>
                                </Header.Subheader>
                            </Header.Content>
                        </Style.PaymentInfoContent>
                        <Segment basic textAlign="center" style={{margin: "0"}}>
                            <Style.ContinueShoppingButton primary content="Continue Shopping" onClick={ () => window.location.href = homeUrl}/>
                        </Segment>
                        <Dimmer active={this.orderStore.isLoading}>
                            <Loader />
                        </Dimmer>
                    </Style.PaymentInfo>
                </Style.PaymentSegment>
            </Style.PaymentView>
        );
    }
}