
import classNames from "classnames";
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import { action, observable } from 'mobx';
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { emitter, EVENTS } from "../../0-common/utils/EventEmitter";

@inject('storeFactory', 'shopTemplateFactory')
@observer
export default class ShopHeaderView extends Component{

    @observable isHeaderTransparent = false;
    
    emitterBindList = {};

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        this.bindEvents(props);
    }

    bindEvents = (props) => {
        this.emitterBindList[EVENTS.SHOP.HEADER.TRANSPARENT] = emitter.on(EVENTS.SHOP.HEADER.TRANSPARENT, this.makeHeaderTransparent);
        this.emitterBindList[EVENTS.SHOP.HEADER.OPAQUE] = emitter.on(EVENTS.SHOP.HEADER.OPAQUE, this.makeHeaderOpaque);
	}

	unBindEvents = () => {
		_forEach(this.emitterBindList, (unbindRef, key) => {
			unbindRef();
		})
		this.emitterBindList = {};
	}

    @action
    makeHeaderOpaque = () => {
        this.isHeaderTransparent = false;
    }

    @action
    makeHeaderTransparent = () => {
        this.isHeaderTransparent = true;
    }

    /*showSideBarMenu = () => {
		emitter.emit(EVENTS.SHOP.SIDEBAR.OPEN);
    }

    closeSideBarMenu = () => {
		emitter.emit(EVENTS.SHOP.SIDEBAR.CLOSE);
    }

    goBackAction_Checkout = () => {
		const homeUrl = JSON.parse(localStorage.getItem('orderHomeURL'));
        window.location.href = homeUrl
    }

    @action
    goBackAction_Product = () => {
		//const productListUrl = JSON.parse(localStorage.getItem('productListURL_Back'));
        //window.location.href = productListUrl;
        //window.history.back();
        const productPageID = JSON.parse(localStorage.getItem('productPageID'));
        this.shopStore.ActivePage = productPageID;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP);
    }

    goBackAction_Collection = () => {
        const homePageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "HomePage"});
        this.shopStore.ActivePage = homePageRef.uid;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP);
        //window.history.back();
    }

    showSearchBox = () => {
        this.shopStore.searchStore.expandSearchBox = true;
    }

    hideSearchBox = () => {
        this.shopStore.searchStore.expandSearchBox = false;
    }

    renderMenuIcon(){
        let menuIconType = "MENU";
        const navigationStore = this.props.storeFactory.NavigationStore;
        const currentView = navigationStore.currentViewName;

        switch (currentView) {

            case navigationStore.VIEW.CHECKOUT:
                menuIconType = "BACK_CHECKOUT";
                break;
        
            default:
                const activePageID = this.shopStore.ActivePage; 
                const pageList = this.shopStore.Pages;
                const activePage = _find(pageList, ['uid', activePageID]);

                if(activePage){

                    switch (activePage.CategorySourceID) {
                        case "ProductPage" : 
                            let productSubView = navigationStore.currentSubView;
                            if(productSubView === navigationStore.SUBVIEW.NONE){
                                const shopParams = this.props.storeFactory.NavigationStore.getContextualParam("SHOP"); 
                                const productID = shopParams.itemID;
                                if(productID){
                                    productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN;
                                } else {
                                    productSubView = navigationStore.SUBVIEW.SHOP.PRODUCT.LIST;
                                }
                            }
                            switch (productSubView) {
                                case navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN:
                                    menuIconType = "BACK_PRODUCT";
                                    break;
                            
                                case navigationStore.SUBVIEW.SHOP.PRODUCT.LIST:
                                    const showSideBar = this.shopStore.showSideBar;
                                    if(showSideBar){
                                        menuIconType = "CLOSE";
                                    } else {
                                        menuIconType = "MENU";
                                    }
                                    break;
                            }
                            break;
                            
                        case "CollectionPage" : 
                            menuIconType = "BACK_COLLECTION";
                            break;
                        
                        default:
                            const showSideBar = this.shopStore.showSideBar;
                            if(showSideBar){
                                menuIconType = "CLOSE";
                            } else {
                                menuIconType = "MENU";
                            }
                            break;
                    }
                }
                break;
        }

        switch (menuIconType) {

            case "MENU":
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <MenuIcon size="32px" strokeWidth="1px" onClick={this.showSideBarMenu} />
                        <span style={{fontFamily:"Lato", fontSize: "0.8rem", textAlign: "center"}}>Menu</span>
                    </div>
                );
            
            case "CLOSE" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <X size="32px" strokeWidth="1px" onClick={this.closeSideBarMenu} />
                        <span style={{fontFamily:"Lato", fontSize: "0.8rem", textAlign: "center"}}>Close</span>
                    </div>
                );
            
            case "BACK_CHECKOUT" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="30px" strokeWidth="1px" onClick={this.goBackAction_Checkout} />
                        <span style={{fontFamily:"Lato", fontSize: "0.8rem", textAlign: "center"}}>Back</span>
                    </div>
                );

            case "BACK_PRODUCT" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="30px" strokeWidth="1px" onClick={this.goBackAction_Product} />
                        <span style={{fontFamily:"Lato", fontSize: "0.8rem", textAlign: "center"}}>Back</span>
                    </div>
                );

            case "BACK_COLLECTION" :
                return(
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <ArrowLeft size="30px" strokeWidth="1px" onClick={this.goBackAction_Collection} />
                        <span style={{fontFamily:"Lato", fontSize: "0.8rem", textAlign: "center"}}>Back</span>
                    </div>
                );
        }
    }

    renderShopHeaderView(){
        const activePageID = this.shopStore.ActivePage; 
        const pageList = this.shopStore.Pages;
        const activePage = _find(pageList, ['uid', activePageID]);

        const headerClass = classNames({
            transparent : (activePage && activePage.CategorySourceID === 'HomePage' && this.isHeaderTransparent),
            shopHeader: true,
            homeHeader: (activePage && activePage.CategorySourceID === 'HomePage')
        })

        // TODO >> use the templateID/layoutID present in headerSection
        const temporary_Header_Template_ID = "HeaderTemplate2";
        const HeaderTemplate = this.props.shopTemplateFactory.getTemplate(temporary_Header_Template_ID);
        const shopTheme =  this.shopStore.ShopTheme;
        
        return (<HeaderTemplate currentTheme={shopTheme} headerClassList={headerClass} />);
    }

    renderMobileShopHeaderView(){
        
        let businessName = "";
        const businessLogo = this.shopStore.ShopLogo;
        const webShopCategory = this.shopStore.ShopCategory;
        const cartCount = this.orderStore.ItemCountInCart;
        const buyer = this.props.shopUserStore.User;
        const showSideBar = this.shopStore.showSideBar;
        const navigationStore = this.props.storeFactory.NavigationStore;
        const currentView = navigationStore.currentViewName;
        return(
            <div className="shopHeader" key="header">
                {
                    this.shopStore.searchStore.expandSearchBox
                    &&
                    <Segment basic className="expandedSearchBox">
                        <Icon name="arrow left" size="large" onClick={this.hideSearchBox} />
                        <SearchView
                            fluid
                            searchStore={this.shopStore.searchStore}
                            filterStore={this.shopStore.filterStore}
                            eventContext="" />
                    </Segment>
                }
                {
                    !this.shopStore.searchStore.expandSearchBox
                    &&
                    <Segment basic style={{display: "flex", height: "100%"}}>
                        <div style={{display: "flex", alignItems: "center", height: "100%", marginRight: "1rem"}}>
                            {this.renderMenuIcon()}
                        </div>
                        {
                            businessLogo
                            &&
                            <Image src={businessLogo}/>
                        }
                        <Menu secondary size="huge" style={{flex: "1", marginTop: "0", marginBottom: "0"}}>
                            <Menu.Menu position="right">
                                <Media at="mobile" className="responsiveSearch">
                                    <Menu.Item>
                                        <Icon name="search" onClick={this.showSearchBox} />
                                    </Menu.Item>
                                </Media>
                                <Media at="tablet" className="responsiveSearch">
                                    <Menu.Item className="searchMenuItem">
                                        <SearchView
                                            size="mini"
                                            searchStore={this.shopStore.searchStore}
                                            filterStore={this.shopStore.filterStore}
                                            eventContext="" />
                                    </Menu.Item>
                                </Media>
                                {
                                    (webShopCategory === 'StandardWebShop')
                                    &&
                                    <>
                                        <Menu.Item active={false} style={{padding: "0"}}>
                                            <ShoppingCart onClick={this.showShoppingCart} style={{cursor: "pointer"}}></ShoppingCart>
                                            {
                                                cartCount > 0
                                                &&
                                                <Label className="cartItemCountBubble" color='orange' floating circular>
                                                    {cartCount}
                                                </Label>
                                            }
                                        </Menu.Item>
                                    </>
                                }
                            </Menu.Menu>
                        </Menu>
                    </Segment>
                }
            </div>
        );
    }*/

    render(){
        const activePageID = this.shopStore.ActivePage; 
        const pageList = this.shopStore.Pages;
        const activePage = _find(pageList, ['uid', activePageID]);

        const headerClass = classNames({
            transparent : (activePage && activePage.CategorySourceID === 'HomePage' && this.isHeaderTransparent),
            shopHeader: true,
            homeHeader: (activePage && activePage.CategorySourceID === 'HomePage')
        })

        const headerSection = this.shopStore.HeaderSection;
        const headerThemeSectionID = (headerSection && headerSection.ThemeSectionID) || "";// eg: - "Default-HeaderSection"
        const header_template_ID =  this.shopStore.ShopTheme.getHeaderTemplateID(headerThemeSectionID) // eg: - "HeaderTemplate1";
        const HeaderTemplate = this.props.shopTemplateFactory.getTemplate(header_template_ID);

        return (<HeaderTemplate headerClassList={headerClass} {...this.props} />);
    }
}

