import _find from 'lodash/find';
import BannerSection from '../section/BannerSection';
import ContactUsSection from '../section/ContactUsSection';
import ContentSection from '../section/ContentSection';
import FeaturedCollectionSection from "../section/FeaturedCollectionSection";
import TitleDescriptionSection from "../section/TitleDescriptionSection";

export class PageSectionFactory {

	_sectionDictionary = [
        {
            section: "BannerSection",
            component: BannerSection
        },
        {
            section: "CollectionsSection",
            component: FeaturedCollectionSection
        },
        {
            section: "ContentSection",
            component: ContentSection
        },
        {
            section: "TitleDescription",
            component: TitleDescriptionSection
        },
        {
            section: "ContactUsSection",
            component: ContactUsSection
        }
    ];

	//param2 - section type
	fetchSectionInfo(sectionType){
		return _find(this._sectionDictionary, {'section':sectionType});
	}

}

export default new PageSectionFactory();