import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Message, Transition } from 'semantic-ui-react';
import { injectStyle } from '../utils/DecoratorUtil';

@inject("storeFactory")
@injectStyle("Common", "AddToCartMessage")
@observer
class AddToCartMessage extends Component {

    constructor(props){
        super(props);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
    }

    @action
	handleSuccessDismiss = () => {
		this.orderStore.notifyAddedToCart = false;
	}

    componentDidUpdate(){
		//Auto hide for added to cart success msg
		if(this.orderStore.notifyAddedToCart){
			setTimeout(() => this.orderStore.notifyAddedToCart = false, 4000);
		}
	}

    componentDidMount() {
		//Force hide for 'added to cart' success msg incases when user adds the product from listing screen and then immediately opens the product 
		if(this.orderStore.notifyAddedToCart){
			this.orderStore.notifyAddedToCart = false;
		}
	}

    render() {
        const Style = this.props.componentStyle;
        return (
            <Transition 
                mountOnShow={false}
                as="div" 
                animation='fade left' 
                duration={500, 1000}
                visible={this.orderStore.notifyAddedToCart} >
                    <Style.MessageBlock onDismiss={this.handleSuccessDismiss}>								
                            <Style.MessageContent>
                                <Style.MessageHeader>Added to cart successfully</Style.MessageHeader>
                            </Style.MessageContent>
                    </Style.MessageBlock>
            </Transition>
        );
    }
}

export default AddToCartMessage;