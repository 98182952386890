import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Container, Divider, Form, Segment } from 'semantic-ui-react';
import { value } from '../../0-common/store/LanguageStore';
import { injectStyle } from "../utils/DecoratorUtil";

@inject('storeFactory')
@injectStyle('Login', 'SignInView')
@observer
export default class ShopSigninView extends Component{

  constructor(props){
    super(props);
    this.shopLoginStore = props.storeFactory.ShopLoginStore;
  }

  render(){
    const Style = this.props.componentStyle;
    return(
        <Style.FormSegment basic>
            <Form>
              <Style.FormField>
                <Style.InputType
                  fluid
                  type='text'
                  size='big'
                  icon='envelope'
                  iconPosition='left'
                  autoComplete='username'
                  ref={this.shopLoginStore.ref("username")}
                  placeholder={value('ph_email')}
                  onFocus={this.shopLoginStore.resetLoginStatus.bind(null, "username")}
                  error={this.shopLoginStore.isUsernameInValid} />
                {
                  this.shopLoginStore.isUsernameInValid
                  &&
                  <span className="fieldErrorMsg">
                    {this.shopLoginStore.usernameErrorMsg}
                  </span>
                }
              </Style.FormField>  
              <Style.FormField>
                <Style.InputType
                  fluid
                  type='password'
                  size='big'
                  icon='lock'
                  iconPosition='left'
                  autoComplete='current-password'
                  ref={this.shopLoginStore.ref("password")}
                  placeholder={value('ph_password')}
                  onFocus={this.shopLoginStore.resetLoginStatus.bind(null, "password")}
                  error={this.shopLoginStore.isPasswordEmpty} />
                {
                  this.shopLoginStore.isPasswordEmpty
                  &&
                  <span className="fieldErrorMsg">
                    {this.shopLoginStore.passwordErrorMsg}
                  </span>
                }
              </Style.FormField>
              <Style.LoginButton primary onClick={this.shopLoginStore.executeLogin.bind(null, this.props.SellerID, this.props.ShopID)} loading={this.shopLoginStore.isLoading}>
                Login
              </Style.LoginButton>
            </Form>
            <Style.ForgotPassword onClick={this.shopLoginStore.gotoForgotPasswordView} >{value('bt_forgotpassword')}</Style.ForgotPassword>
            <Divider />
            <Style.SignUpButton secondary onClick={this.shopLoginStore.gotoRegisterView}>
              Sign up
            </Style.SignUpButton>
        </Style.FormSegment>
    );
  }
}