import { inject, observer } from 'mobx-react';
import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import ItemContainer from '../../0-common/container/ItemContainer';
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { injectStyle } from '../utils/DecoratorUtil';
import { Media } from '../utils/Responsive';
import AddToCartMessage from './AddToCartMessage';
import CollectionCardView from './CollectionCardView';
import ShopFilterView from './Shop-FilterView';

const STORE_SUBJECT_TYPE = "WEBSECTIONS";

@inject('storeFactory')
@injectStyle("Collection", "AllCollectionView")
@observer
export default class CollectionsInAllCollectionsView extends ItemContainer {

	constructor(props){
		const X_SUBJECT_TYPE = {
            "uid":props.shopID,
            "Type":"COLLECTIONS",
            "Category":"",
            "PREDICATE_TYPE":"CollectionsInShop",
            "FIND_WITHIN":true,
            "FIND_WITH_FILTERS":true,
			"REQUESTED_PAGE":1,
			"APP_OWNER": props.sellerID,
			"ShopID": props.shopID
        };
		super(props, STORE_SUBJECT_TYPE, X_SUBJECT_TYPE);
		this.STORE_TYPE = "COLLECTIONS";
		this.shopStore = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
		this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});

		//	Remove PARENT EVENTS.ITEM.OPEN event handler
		const unbindRefOpenEvent = this.emitterBindList[EVENTS.ITEM.OPEN+this.predicateContext];
		unbindRefOpenEvent();
		delete this.emitterBindList[EVENTS.ITEM.OPEN+this.predicateContext];
		//	Bind EVENTS.ITEM.OPEN to new event handler

		//	Remove PARENT EVENTS.SEARCH.APPLY event handler
		const unbindRefSearchEvent = this.emitterBindList[EVENTS.SEARCH.APPLY+this.predicateContext];
		unbindRefSearchEvent();
		delete this.emitterBindList[EVENTS.SEARCH.APPLY+this.predicateContext];
		//	Bind EVENTS.SEARCH.APPLY to new event handler

		//	Moved the item open event & apply search event listners to ShopScreen to support 'search & open'
		//	this.emitterBindList[EVENTS.ITEM.OPEN+this.predicateContext] = emitter.on(EVENTS.ITEM.OPEN+this.predicateContext, this.handleOpenProductView);
	}

	get CommonActions(){
		return ['FILTER'];
	}

	get SpecialActions(){
		return [];
	}

	get ItemActions(){
		return [];
	}

	get BulkActions(){
		return [];
    }
    
    get ItemsPerRow(){
		return 4;
	}

	get ShowItemStock(){
		return (this.shopStore.ShopCategory === "StandardWebShop");
	}

	openFilterSideBar = (e) => {
		emitter.emit(EVENTS.FILTER.SIDEBAR.OPEN, this.itemStore.filterStore, this.predicateContext);
	}

	isContainerEmpty = () => {
		if(this.itemStore.isLoading){
			return false;
		} else {
			if(this.itemStore.ItemList){
				const itemsCount = this.itemStore.ItemList.length;
				if(itemsCount === 0){
					return true;
				} else {
					return false;
				}
			}
			return true;
		}
	}

	//@override
	getFilterView = () =>{
		const Style = this.props.componentStyle;
		const appliedFilters = this.itemStore.filterStore.appliedFilter;
		const isFilterApplied = (appliedFilters.size > 0);
		return(
			<>
				<Media lessThan="laptop" className="responsiveFilterBar">
					<div className="filterBar">
						<Segment basic textAlign="right">
							<Style.MobileFilterButton primary={isFilterApplied} onClick={this.openFilterSideBar}>
								<Icon name="filter" />
								Filter
							</Style.MobileFilterButton>
						</Segment>
					</div>
				</Media>
				<Media greaterThanOrEqual="laptop" className="responsiveFilterBar">
					<ShopFilterView
						viewType={this.FilterViewType}
						filterStore={this.itemStore.filterStore}
						searchStore={this.itemStore.searchStore}
						containerContextName={this.containerContextName}
						eventContext={this.predicateContext}  />
				</Media>
			</>
		);
	}

	//@override
	renderEmptyView(context){
		return null;
	}

	//@Override
	getItemContainerView(){
		const itemsList = this.itemStore.ItemList;
		const isLoading = this.itemStore.isLoading
		const pageCount = this.itemStore.totalPagesCount;
		return(<CollectionCardView itemList={itemsList} itemsPerRow={this.ItemsPerRow} isLoading={isLoading} pageCount={pageCount} eventContext={this.predicateContext} />);
	}

	componentDidUpdate(prevProps){
		console.log("componentDidUpdate. CollectionsInAllCollectionsView >>");
		console.log("prevProps (searchApplied) >> "+prevProps.searchFired);
		console.log("currentProps (searchApplied) >> "+this.props.searchFired);
		if(prevProps.searchFired === false && this.props.searchFired === true){
			console.log("Fetching Search Result for >> ");
			console.log("Search Query : "+this.shopStore.searchStore.finalSearchQuery);
			this.fetchListViewData();
			this.shopStore.searchStore.searchApplied = false;
		}

		//Reset the Scroll to top for filter/search/pagination.
		const shopBodyDOM = document.querySelector('.shopBody');
		shopBodyDOM.scrollTop = 0;
	}

	componentWillUnmount(){
		this.unBindEvents();
	}

	componentDidMount(){
		console.log("M. CollectionsInAllCollectionsView");
		this.fetchListViewData();
		this.resetStatus();
	}

	render() {
		const Style = this.props.componentStyle;
		console.log("R. CollectionsInAllCollectionsView");
		return (
			<Style.CollectionView fluid>
				{this.renderView()}
				<AddToCartMessage />
			</Style.CollectionView>
		);
	}	
}