import React, { Component } from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Button, Divider, Form, Header, Icon, Input, Message, Segment } from "semantic-ui-react";
import { value } from "../../0-common/store/LanguageStore";
import { injectStyle } from "../utils/DecoratorUtil";

@inject("storeFactory")
@injectStyle("Account", "ChangePasswordView")
@observer
export default class ShopBuyerChangePasswordView extends Component{

    @observable confirmPasswordValue = "";

    constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore("PUBLICWEBSHOPS");
    }

    getPredicateValue(userInfo, uid){
		const predicateValue = userInfo.DATA[uid];
		return predicateValue ? predicateValue : "";
	}

    @action
	setConfirmPassword = (e) => {
		this.props.storeFactory.ShopUserStore.setConfirmPassword(this.confirmPasswordValue);
		this.props.storeFactory.ShopUserStore.checkConfirmPassword();
	}
	
	@action
	onConfirmPassword = () => {
		this.props.storeFactory.ShopUserStore.checkConfirmPassword();
	}

	@action
	handlePasswordChange = (predicate, e, data) => {
		this.props.storeFactory.ShopUserStore.updateProperty(predicate, data ? data.value : e.target.value);
    }
    
    @action
    handleConfirmPasswordChange = (e, data) => {
        this.confirmPasswordValue = data ? data.value : e.target.value;
    }

	@action
	handleProfileSave = async () => {
		const checkConfirmed = this.props.storeFactory.ShopUserStore.checkConfirmPassword();
		if(checkConfirmed) {
            const sellerID = this.shopStore.OwnerInfo.SourceID;
            const shopID = this.shopStore.ShopID;
            await this.props.storeFactory.ShopUserStore.saveUserProfileChanges(sellerID, shopID);
            this.confirmPasswordValue = "";		
        }
    }
    
    @action
    handleCancelChanges = () =>{
        this.props.storeFactory.ShopUserStore.cancelUserProfileChanges();
        this.confirmPasswordValue = "";	
    }

    render(){
        const Style = this.props.componentStyle;
        const userInfo = this.props.storeFactory.ShopUserStore.UserForEdit;
        return(
            <Style.ChangePasswordView>
                <Style.ChangePasswordHeader icon='user secret' content="Change Password" />
                <Divider />
                <Style.ChangePasswordForm error={this.props.storeFactory.ShopUserStore.confirmPasswordError}>
                    <Form.Group>
                        <Form.Field width="8" style={{marginBottom: "1rem"}}>
                            <label>New Password</label>
                            <Input
                                autoFocus={true}   
                                type="password" 
                                value={this.getPredicateValue(userInfo, "SECRET")} 
                                autoComplete="new-password" 
                                onChange={this.handlePasswordChange.bind(null, "SECRET")}
                                onBlur={this.onConfirmPassword} />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width="8">
                            <label>{value('lb_userprofile_modal_changepwd_confirmpwd')}</label>
                            <Input 
                                type="password"
                                autoComplete="new-password"
                                value={this.confirmPasswordValue}
                                onChange={this.handleConfirmPasswordChange}
                                onBlur={this.setConfirmPassword} />
                            <Message
                                error
                                header={value('msg_userprofile_modal_changepwd_error_header')}
                                content={value('msg_userprofile_modal_changepwd_error')} />
                        </Form.Field>
                    </Form.Group>
                </Style.ChangePasswordForm>
                <Segment basic>
                    <Style.SaveButton secondary onClick={this.handleProfileSave} disabled={this.props.storeFactory.ShopUserStore.confirmPasswordError}>
                        <Icon name='save' /> {value('bt_userprofile_modal_save')}
                    </Style.SaveButton>
                    <Style.CancelButton secondary onClick={this.handleCancelChanges} >
                        <Icon name='close' /> Cancel
                    </Style.CancelButton>
                </Segment>
            </Style.ChangePasswordView>
        );
    }
    
}