import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { emitter, EVENTS } from '../../0-common/utils/EventEmitter';
import { infuseRootStyle } from '../utils/DecoratorUtil';
import CartView from '../view/CartView';
import CheckoutView from '../view/CheckoutView';
import ShopActivePageView from '../view/Shop-ActivePageView';
import ShopHeaderView from '../view/Shop-HeaderView';
import ShopLoginView from '../view/Shop-LoginView';
import ShopNetworkErrorView from '../view/Shop-NetworkErrorView';
import SignOutView from '../view/SignOutView';

const STORE_SUBJECT_TYPE = "PUBLICWEBSHOPS";
let RootStyledShopContainer;
@inject("storeFactory")
@observer
export default class ShopScreen extends Component {

    emitterBindList = {};
    
	constructor(props){
        super(props);
        this.shopStore  = props.storeFactory.fetchStore(STORE_SUBJECT_TYPE);
        this.orderStore = props.storeFactory.fetchStore("PUBLICORDERSTORE", {"Type" : "ORDERS"});
        RootStyledShopContainer = infuseRootStyle(Container, "ShopScreen");
        this.bindEvents(props);
    }

    bindEvents = (props) => {
        
        this.emitterBindList[EVENTS.CART.ADD] = emitter.on(EVENTS.CART.ADD, this.handleAddProductToCart);
        this.emitterBindList[EVENTS.CART.REMOVE] = emitter.on(EVENTS.CART.REMOVE, this.handleRemoveProductFromCart);
        this.emitterBindList[EVENTS.CART.UPDATE] = emitter.on(EVENTS.CART.UPDATE, this.handleUpdateProductInCart);

        this.emitterBindList[EVENTS.ITEM.OPEN] = emitter.on(EVENTS.ITEM.OPEN, this.handleOpenProductView);
        this.emitterBindList[EVENTS.SEARCH.APPLY] = emitter.on(EVENTS.SEARCH.APPLY, this.handleSearchApply);
        
        this.emitterBindList[EVENTS.SHOP.SIDEBAR.OPEN] = emitter.on(EVENTS.SHOP.SIDEBAR.OPEN, this.handleOpenSideBar);
        this.emitterBindList[EVENTS.SHOP.SIDEBAR.CLOSE] = emitter.on(EVENTS.SHOP.SIDEBAR.CLOSE, this.handleCloseSideBar);

        this.emitterBindList[EVENTS.FILTER.SIDEBAR.OPEN] = emitter.on(EVENTS.FILTER.SIDEBAR.OPEN, this.handleOpenFilterSideBar);
        this.emitterBindList[EVENTS.FILTER.SIDEBAR.CLOSE] = emitter.on(EVENTS.FILTER.SIDEBAR.CLOSE, this.handleCloseFilterSideBar);
	}

	unBindEvents = () => {
		_forEach(this.emitterBindList, (unbindRef, key) => {
			unbindRef();
		})
		this.emitterBindList = {};
	}
    
    handleAddProductToCart = (productInfo) => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
		this.orderStore.executeAddProductToCart(shopOwnerInfo.SourceID, shopID, productInfo);
    }

    handleRemoveProductFromCart = (orderItemToBeRemoved) => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        this.orderStore.executeRemoveProductFromCart(shopOwnerInfo.SourceID, shopID, orderItemToBeRemoved);
    }

    handleUpdateProductInCart = (productInfo, productQuantity) => {
        const shopOwnerInfo = this.shopStore.OwnerInfo;
        const shopID = this.shopStore.ShopID;
        if(shopOwnerInfo)
            this.orderStore.executeUpdateProductInCart(shopOwnerInfo.SourceID, shopID, productInfo, productQuantity);
    }

    @action
    handleOpenProductView = (eventName, productID, itemType, productCategory) => {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "ProductPage"});
        this.shopStore.ActivePage = pageRef.uid;
        navigationStore.changeView(navigationStore.VIEW.SHOP, navigationStore.SUBVIEW.SHOP.PRODUCT.OPEN);

        const contextualParam = navigationStore.getContextualParam("SHOP");
        contextualParam.itemCategory = productCategory;
        contextualParam.itemID = productID;
        navigationStore.setContextualParam("SHOP", contextualParam);

        //localStorage.setItem('productListURL_Back', JSON.stringify(window.location.href));
        localStorage.setItem('productPageID', JSON.stringify(this.shopStore.ActivePage));
    }

    handleOpenProductView_NewTab = (eventName, productID, itemtType, productCategory) => {
        let productInfo = "";
        let productURL = "";
        const pageRef = _find(this.shopStore.Pages, {"CategorySourceID" : "ProductPage"});
        const contextualParam = this.props.storeFactory.NavigationStore.getContextualParam("SHOP");
        const shopViewMode = contextualParam.shopMode;

	    if(shopViewMode === "preview"){
            const shopCategory = this.shopStore.getCurrentItem().DATA.Category;
            const shopID = this.shopStore.getCurrentItem().DATA.uid;
            productInfo = `${shopCategory}/${shopID}/${pageRef.uid}/${productCategory}/${productID}`;
        } else { // shopViewMode === "live"
            productInfo = `${pageRef.uid}/${productCategory}/${productID}`;
        }

        if(shopViewMode === "preview"){
            productURL = `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}/p/WEBSHOPS/shop/${productInfo}`;
        } else { // shopViewMode === "live"
            const contextualParam = this.props.storeFactory.NavigationStore.getContextualParam("SHOP")
            if(contextualParam && contextualParam.shopLink){
                const shopLink = contextualParam.shopLink;
                productURL = `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}/s/${shopLink}/shop/${productInfo}`;
            } else {
                productURL = `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port : '')}/shop/${productInfo}`;
            }
        }
		window.open(productURL);
    }

    @action
    handleSearchApply = () => {
        this.shopStore.searchStore.isOpen = false;
        const productPageRef = _find(this.shopStore.Pages, ['CategorySourceID', 'ProductPage']);
        this.shopStore.ActivePage = productPageRef.uid;
        this.props.storeFactory.NavigationStore.changeView(this.props.storeFactory.NavigationStore.VIEW.SHOP);
		this.props.storeFactory.NavigationStore.setActviePage(1);
		//this.fetchListViewData();
    }

    @action
    handleOpenSideBar = () => {
        this.shopStore.showFilterSideBar = false;
        this.shopStore.showSideBar = true;
    }

    @action
    handleCloseSideBar = () => {
        this.shopStore.showSideBar = false;
    }

    @action
    handleOpenFilterSideBar = (filterStore, filterEventContext) => {
        this.shopStore.showFilterSideBar = true;
        this.shopStore.filterStoreForSideBar = filterStore;
        this.shopStore.filterEventContext = filterEventContext;
    }

    @action
    handleCloseFilterSideBar = () => {
        this.shopStore.showFilterSideBar = false;
    }

    renderShopView(showLogin = false) {
		return (
            <RootStyledShopContainer fluid className="shopPreview" $injectGlobalStyle $injectFontStyle $storeFactory={this.props.storeFactory}> 
                <ShopHeaderView />
                <ShopActivePageView />
                <CartView/>
                {
                    showLogin
                    &&
                    this.shopStore.OwnerInfo
                    &&
                    <ShopLoginView SellerID={this.shopStore.OwnerInfo.SourceID} ShopID={this.shopStore.ShopID} />
                }
                <SignOutView />
                <ShopNetworkErrorView />
                <Dimmer active={this.shopStore.isLoading}>
                    <Loader>Loading</Loader>
                </Dimmer>
            </RootStyledShopContainer>
        );
    }

    renderCheckoutView(){
        return(
            <RootStyledShopContainer fluid className="shopPreview" $injectGlobalStyle $injectFontStyle $storeFactory={this.props.storeFactory}> 
                <ShopHeaderView />
                <CheckoutView />
                <SignOutView />
                <ShopNetworkErrorView />
            </RootStyledShopContainer>
        );
    }

    componentWillUnmount(){
		this.unBindEvents();
    }
    
    render() {
        const navigationStore = this.props.storeFactory.NavigationStore;
        const view = navigationStore.currentViewName;
        console.log("R. ShopScreen : "+ view);
        switch(view){
            case navigationStore.VIEW.SHOP : return this.renderShopView();
            case navigationStore.VIEW.SHOPLOGIN  : return this.renderShopView(true);
            case navigationStore.VIEW.CHECKOUT : return this.renderCheckoutView();
            default : return this.renderShopView();
        }
    }
}